
  // EmailVerificationPage.jsx
  import React, { useEffect, useState } from 'react';
  import { useNavigate, useParams } from 'react-router-dom';
  import ConfirmationDialog from '../../components/reusableComponents/ConfirmationDialog/ConfirmationDialog';
  import { Mail, CheckCircle, XCircle } from 'lucide-react';
  import { verifyEmailApi } from '../../api/EmailVerificationApi';
  import styles from './EmailVerification.module.css';
  
  const EmailVerificationPage = () => {
    const { code, token } = useParams();
    const navigate = useNavigate();
    const [verificationStatus, setVerificationStatus] = useState('verifying');
    const [errorMessage, setErrorMessage] = useState('');
    const [showDialog, setShowDialog] = useState(true);
  
    useEffect(() => {
      const verifyEmail = async () => {
        if (!token || !code) {
          setVerificationStatus('error');
          setErrorMessage('Verification token is missing');
          return;
        }
     
        try {
          const response = await verifyEmailApi(code, token);
          const data = response.json()
     
          if (response.status === 200) {
            setVerificationStatus('success');
          } else {
            setVerificationStatus('error');
            const errorMessage = data?.errors?.length ? 
              data.errors.map(err => err.detail).join('. ') : 
              'Verification failed';
            setErrorMessage(errorMessage);
          }
        } catch (error) {
          console.error('Error verifying email:', error);
          setVerificationStatus('error');
          const errorMessage = error?.errors?.length ? 
            error.errors.map(err => err.detail).join('. ') : 
            'Failed to verify email. Please try again.';
          setErrorMessage(errorMessage);
        }
      };
     
      verifyEmail();
     }, [code, token]);
  
    const getDialogProps = () => {
      const configs = {
        verifying: {
          variant: 'info',
          title: 'Verifying Your Email',
          message: 'Please wait while we verify your email address...',
          showCloseButton: false,
          closeOnOverlayClick: false,
          closeOnEscape: false,
          isConfirmLoading: true,
          confirmText: 'Verifying...',
          cancelText: 'Cancel',
        },
        success: {
          variant: 'success',
          title: 'Email Verified!',
          message: 'Your email has been successfully verified. You can now proceed to login.',
          confirmText: 'Go to Login',
          cancelText: 'Close',
          customIcon: CheckCircle,
        },
        error: {
          variant: 'danger',
          title: 'Verification Failed',
          message: errorMessage,
          confirmText: 'Try Again',
          cancelText: 'Go Back',
          customIcon: XCircle,
        }
      };
  
      return configs[verificationStatus];
    };
  
    const handleConfirm = () => {
      if (verificationStatus === 'success') {
        navigate('/login');
      } else if (verificationStatus === 'error') {
        window.location.reload();
      }
    };
  
    const handleClose = () => {
      setShowDialog(false);
      if (verificationStatus !== 'success') {
        navigate('/');
      }
    };
  
    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.iconContainer}>
            <div className={`${styles.iconWrapper} ${styles[verificationStatus]}`}>
              <Mail className={styles.icon} />
            </div>
            <h1 className={styles.title}>
              Email Verification 
            </h1>
          </div>
  
          <ConfirmationDialog
            isOpen={showDialog}
            onClose={handleClose}
            onCancel={handleClose}
            onConfirm={handleConfirm}
            {...getDialogProps()}
            size="small"
            position="center"
            blurBackground={true}
            className={styles.dialog}
          />
        </div>
      </div>
    );
  };
  
  export default EmailVerificationPage;