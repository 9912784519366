import React, { useEffect, useState, useRef } from 'react';
import { TextField, Button, Grid, Box, Typography, Snackbar, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import { createCustomerApi, createPartnerApi, fetchPartnersApi, fetchPartnersFullListApi, UpdateCustomerApi } from '../../api/usermanagmentApi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { handleInfiniteScroll } from './utils/userUltils';
const CustomerForm = ({ customerDataEdit, currentCustomerId, setAlert, setIsModalOpen, getAllCustomers }) => {
  const initialFormData = {
    name: '',
    business_name: '',
    email_id: '',
    number_of_employees: null,
    partner_id: '',
    billing_address: {
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      phone_number: ''
    },

  }
  const menuRef = useRef(null);
  let accessToken = useSelector((state) => state.auth.accessToken);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customerResponse, setCustomerResponse] = useState('');
  const [open, setOpen] = useState(false);
  const [partnerNames, setPartnerNames] = useState([]);
  const [customerData, setCustomerData] = useState(initialFormData);

  console.log("customer data", customerData)
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getPartner();
    }

    return () => {
      isMounted = false; // Cleanup function
    };
  }, []);
  useEffect(() => {
    if (customerDataEdit) {
      const mappedData = Object.keys(initialFormData).reduce((acc, key) => {
        let value = customerDataEdit.hasOwnProperty(key) ? customerDataEdit[key] : initialFormData[key];
        // Convert null or undefined values to an empty string
        acc[key] = value ?? "";
        return acc;
      }, {});
      setCustomerData(mappedData);
    } else {
      setCustomerData(initialFormData);
    }
  }, [customerDataEdit]);

  useEffect(() => {
    if (customerResponse) {
      setOpen(true)
      const timer = setTimeout(() => {
        setCustomerResponse('')
        // Now navigate after the message is cleared
        setOpen(false)
      }, 5000);

      // Clean up the timer if the component unmounts before the timeout completes
      return () => clearTimeout(timer);
    }
  }, [customerResponse, navigate]);

  const handleClose = () => {
    setCustomerResponse('')
    setOpen(false);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name?.includes('billing_address')) {
      const [_, field] = name.split('.');
      setCustomerData((prev) => ({
        ...prev,
        billing_address: { ...prev.billing_address, [field]: value }
      }));
    } else if (name === 'number_of_employees') {
      // Convert string to number if it is `number_of_employees`
      setCustomerData((prev) => ({
        ...prev,
        [name]: Number(value)
      }));
    } else {
      setCustomerData((prev) => ({
        ...prev,
        [name]: value
      }));
    }

  };
  const getPartner = async () => {

    try {
      const data = await fetchPartnersFullListApi(accessToken); // Call the API to fetch templates
      if (data) {
        setPartnerNames(data);
      } 
    } catch (error) {
      setError(true); // Set error state if the API call fails setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!currentCustomerId) {
      try {
        const response = await createCustomerApi(accessToken, customerData);
        let errorMessages = [];
        if (response.message !== "Customer created successfully.") {
          if (response && response.type === "validation_error") {
            const messages = Array.isArray(response.errors)
              ? response.errors.map((err) => `${err.attr}: ${err.detail}`)
              : ["Unknown validation error"];
            errorMessages.push(...messages);
          } else {
            errorMessages.push("Failed to update user data.");
          }
        }
        if (errorMessages.length > 0) {
          setAlert((prev) => ({ ...prev, open: true, message: errorMessages, type: "failure" }));
          setCustomerResponse(errorMessages)
          return;
        }
        setError(false);
        setAlert((prev) => ({ ...prev, open: true, message: response.message, type: "success" }));
        setTimeout(() => {
          setIsModalOpen(false)
          getAllCustomers()
        }, 2000);
        setCustomerResponse(response.message || "Customer created successfully.!")
      } catch (error) {
        console.error('Error submitting partner data:', error);
        setError('Failed to submit data. Please try again.');
      } finally {
        setLoading(false); // Hide loading indicator
      }
    } else {
      try {

        const response = await UpdateCustomerApi(accessToken, customerData, currentCustomerId);
        setCustomerResponse(response.message || "Customer created successfully.!")
        setAlert((prev) => ({ ...prev, open: true, message: response.message, type: "success" }));
        setTimeout(() => {
          setIsModalOpen(false)
          getAllCustomers()
        }, 3000);
      } catch (error) {
        let errorMessages = [];
        if (error && error.type === "validation_error") {
          const messages = Array.isArray(error.errors)
            ? error.errors.map((err) => `${err.attr}: ${err.detail}`)
            : ["Unknown validation error"];
          errorMessages.push(...messages);
        } else {
          errorMessages.push("Failed to update user data.");
        }

        if (errorMessages.length > 0) {
          setAlert((prev) => ({ ...prev, open: true, message: errorMessages, type: "failure" }));
          setCustomerResponse(errorMessages)
          return;
        }

      } finally {
        setLoading(false); // Hide loading indicator
      }
    }

  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* General Info */}
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="name" sx={{ mb: 1 }}>Name:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="name"
              value={customerData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="bussniness" sx={{ mb: 1 }}>Business Name:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="business_name"
              value={customerData.business_name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="email" sx={{ mb: 1 }}>Email ID:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="email_id"
              value={customerData.email_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="number" sx={{ mb: 1 }}>Number of Employees:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              type="number"
              name="number_of_employees"
              value={customerData.number_of_employees}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel id="Partner-label" htmlFor="number" sx={{ mb: 1 }} >
              Partner:
            </InputLabel>
            <Select
              labelId="Partner-label"
              id="Partner-dropdown"
              name="partner_id"
              value={customerData.partner_id} // Bind state to value prop
              onChange={handleChange}
              input={<OutlinedInput label="Partner" />}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250, // Same height restriction here
                    overflowY: "auto",
                  },
                }
              }}
              renderValue={(selected) => {
                const selectedType = partnerNames.find((type) => type.id === selected);
                return selectedType ? selectedType.name : <span style={{ color: "#9e9e9e" }}>Select Partner</span>;
              }}
              displayEmpty
            >
              {Array.isArray(partnerNames) && partnerNames.length > 0 ? (
                partnerNames.map((item) => (
                  // <MenuItem key={item.id} value={{ key: item.id, name: item.name }}>
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No partners available</MenuItem>
              )}
            </Select>
          </Grid>


          {/* Billing Address */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Billing Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="address" sx={{ mb: 1 }}>Address Line 1:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="billing_address.address_line1"
              value={customerData.billing_address.address_line1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="line2" sx={{ mb: 1 }}>Address Line 2:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="billing_address.address_line2"
              value={customerData.billing_address.address_line2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="City" sx={{ mb: 1 }}>City:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="billing_address.city"
              value={customerData.billing_address.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="state" sx={{ mb: 1 }}>State:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="billing_address.state"
              value={customerData.billing_address.state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="name" sx={{ mb: 1 }}>Country:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="billing_address.country"
              value={customerData.billing_address.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="zip" sx={{ mb: 1 }}>ZIP Code:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="billing_address.zip"
              value={customerData.billing_address.zip}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="number" sx={{ mb: 1 }}>Phone number:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="billing_address.phone_number"
              value={customerData.billing_address.phone_number}
              onChange={handleChange}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Button
              variant="contained"
              sx={{
                alignItems: 'center',
                width: '160px',
                height: '32px',
                borderRadius: '24px',
                backgroundColor: '#4F63BE',
                boxShadow: 'none',
                textTransform: 'none',
                '& .MuiButton-startIcon': {
                  borderRadius: '50%',
                  padding: '0px',
                },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>

          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CustomerForm;
