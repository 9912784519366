import React from "react";
import { Mail, MailOpen, Trash2, Check, Clock } from "lucide-react";
import styles from "./NotificationItem.module.css";

function NotificationItem({
    notification,
    selectedNotification,
    isSelected,
    type,
    onNotificationClick,
    onCheckboxChange,
    onToggleReadStatus,
    onDelete,
}) {
    const handleCheckboxClick = (e) => {
        e.stopPropagation();
        onCheckboxChange?.(notification.id);
    };

    const handleActionClick = (e, action) => {
        e.stopPropagation();
        action();
    };

    console.log("notification---->", notification)

    // Decide icon and text for read/unread state
    const { icon: ReadIcon, text: readText } = notification.isRead
        ? { icon: MailOpen, text: 'Mark unread' }
        : { icon: Mail, text: 'Mark read' };

    return (
        <div
            className={`
            ${styles.notificationItem} 
            ${notification.isRead ? styles.read : styles.unread} 
            ${isSelected ? styles.selected : ''} 
            ${selectedNotification?.id == notification?.id ? styles.activeMessage : ''}
        `}
            onClick={() => onNotificationClick(notification)}
        >
            {type === "inbox" && (
                <div className={styles.leftSection}>
                    <label className={styles.checkboxWrapper} onClick={handleCheckboxClick}>
                        <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => { }}
                            className={styles.checkbox}
                        />
                        <span className={styles.checkmark}>
                            <Check size={12} />
                        </span>
                    </label>
                    {!notification.isRead && <div className={styles.unreadIndicator} />}
                </div>
            )}

            <div className={styles.notificationContent}>
                <div className={styles.header}>
                    <h4 className={styles.title}>
                        {notification.title}
                    </h4>
                    <div className={styles.timeStamp}>
                        <span>{notification.createdDate.split(',')[0]}</span>
                    </div>
                </div>

                <p className={styles.description}>{notification.description}</p>

                <div className={styles.footer}>
                    {/* <div className={styles.timeStamp}>
                        <Clock size={14} />
                        <span>{notification.timestamp}</span>
                    </div> */}

                    <div className={styles.actions}>
                        <button
                            className={`${styles.actionButton} ${styles.readButton}`}
                            onClick={(e) => handleActionClick(e, onToggleReadStatus)}
                            title={readText}
                        >
                            <ReadIcon size={16} />

                        </button>

                        <button
                            className={`${styles.actionButton} ${styles.deleteButton}`}
                            onClick={(e) => handleActionClick(e, onDelete)}
                            title="Delete"
                        >
                            <Trash2 size={16} />

                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotificationItem;