import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Grid } from '@mui/material';
import Header from './EventproHeader/EventHeader';
import Sidebar from './Sidebar/Sidebar';
import Calendar from './calendar/Calendar';
import Schedule from './schedule/Scheduler';
import { Diversity1 } from '@mui/icons-material';

const EventPro = () => {
  const [selectedFunction, setSelectedFunction] = useState('');
  const [showCalendarNew, setShowCalendarNew] = useState(false);
  const [showSchedulerNew, setShowSchedulerNew] = useState(false);
  const [type, setType] = useState();

  // Dynamic list of sidebar items
  const sidebarItems = [
    { value: '1', label: 'Calendar' },
    { value: '2', label: 'Schedule' },
  ];
  const accessToken = useSelector((state) => state.auth.accessToken);
  console.log("Event Pro Index  accessToken... ", accessToken)
  // useEffect(()=>{
  //   console.log("selectedFunction ",selectedFunction)
  // },[selectedFunction])
  const renderContent = () => {
    switch (selectedFunction) {
      case '1':
        return <Calendar
          showCalendarNew={showCalendarNew}
          setShowCalendarNew={setShowCalendarNew}
          setType={setType}
        />

      case '2':
        return <Schedule
          showSchedulerNew={showSchedulerNew}
          setShowSchedulerNew={setShowSchedulerNew}
          setType={setType}
        />

      default:
        return (<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "calc(100vh - (188px))" }}><Typography>Please select a function from the sidebar.</Typography></Box>)
    }
  };
  return (
    <Box sx={{ mt: "4.5rem" }}>
      <Header
        showCalendarNew={showCalendarNew}
        setShowCalendarNew={setShowCalendarNew}
        showSchedulerNew={showSchedulerNew}
        setShowSchedulerNew={setShowSchedulerNew}
        type={type}
        setType={setType}
      />
      {/* Main layout with Sidebar and Content */}
      <Grid container sx={{ pr: 1 }} style={{ justifyContent: "space-around", marginLeft: "-16px", flexWrap: "nowrap" }}>
        {/* Sidebar column - 2/12 (sm: 12, md: 3) */}
        <div >
          <Sidebar items={sidebarItems} onSelect={setSelectedFunction} setShowSchedulerNew={setShowSchedulerNew} setShowCalendarNew={setShowCalendarNew} />
        </div>

        {/* Content column - 10/12 (sm: 12, md: 9) */}
        <Grid item xs={12} md={9} sx={{ marginTop: "16px", height: "100%", marginLeft: "16px" }}>
          {renderContent()}
        </Grid>
      </Grid>

    </Box>
  );
};

export default EventPro;