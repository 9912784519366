import { jwtDecode } from "jwt-decode";
const API_BASE_URL = process.env.REACT_APP_API_URL + "/api/"
export const isTokenValid = (token) => {
  if (!token) {
    return false;
  }

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // current time in seconds
    return decoded.exp > currentTime;
  } catch (error) {
    console.error('Invalid token:', error);
    return false;
  }
};
export const refreshAccessToken = async () => {
  try {
    const refresh = localStorage.getItem('refreshToken');
    const response = await fetch(`${API_BASE_URL}token/refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh }),
    });
    if (!response.ok) {
      if (response.status === 401) {
        // Handle expired or invalid refresh token
        // Clear tokens from local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        // Optional: Trigger any additional logout actions, e.g., redirect
        window.location.href = '/login';
      } 
      return null;
      
    }

    const data = await response.json();
    const newAccessToken = data.access;
    localStorage.setItem('accessToken', newAccessToken);
    localStorage.setItem('refreshToken', data.refresh);
    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};
export const handleTokenExpiry = async (refreshToken) => {
    try {
      const response = await fetch(`${API_BASE_URL}refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refreshToken }),
      });
 
      if (response.ok) {
        const data = await response.json();
        return data; // Return the new tokens (access and refresh)
      } else {
        throw new Error('Unable to refresh token');
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
    }
  };
 
  export const isTokenExpired = (token) => {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };

