// reducers/sidebarReducer.js

import { TOGGLE_SIDEBAR, HIDE_SIDEBAR, SHOW_SIDEBAR } from '../actions/actions'

const initialState = {
    isVisible: true
};

const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                isVisible: !state.isVisible
            };
        case HIDE_SIDEBAR:
            return {
                ...state,
                isVisible: false
            };
        case SHOW_SIDEBAR:
            return {
                ...state,
                isVisible: true
            };
        default:
            return state;
    }
};

export default sidebarReducer;