import React, { useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  IconButton,
} from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowBackIos";
import ArrowRightIcon from "@mui/icons-material/ArrowForwardIos";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

const CustomPagination = ({ totalPages, curPage, onPageChange }) => {
  // const [curPage, setcurPage] = useState(curPage);
  console.log("current page--------------in pagination",curPage)
  console.log("current page-------useeff-------in pagination",curPage)
  const handlePageChange = (page) => {
    // setcurPage(page);
    onPageChange(page);
  };

  const handleStart = () => {
    handlePageChange(1); // Go to the first page
  };

  const handleEnd = () => {
    handlePageChange(totalPages); // Go to the last page
  };

  const handlePrev = () => {
    if (curPage > 1) {
      handlePageChange(curPage - 1);
    }
  };

  const handleNext = () => {
    if (curPage < totalPages) {
      handlePageChange(curPage + 1);
    }
  };

  const handleDropdownChange = (event) => {
    const page = Number(event.target.value);
    handlePageChange(page);
    console.log('...handleDropdownChange :', page)
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderRadius: "25px",
        border: "1px solid #ECEEF6",
        padding: "10px",
        gap: 2,
      }}
    >
      {/* Start Icon */}
      <IconButton
        onClick={handleStart}
        disabled={curPage === 1}
        sx={{
          color: curPage === 1 ? "#ccc" : "#3f51b5",
          "&:hover": { backgroundColor: "#f0f0f0" },
        }}
      >
        <FirstPageIcon />
      </IconButton>

      {/* Left Arrow */}
      <IconButton
        onClick={handlePrev}
        disabled={curPage === 1}
        sx={{
          color: curPage === 1 ? "#ccc" : "#3f51b5",
          "&:hover": { backgroundColor: "#f0f0f0" },
        }}
      >
        <ArrowLeftIcon />
      </IconButton>

      {/* Dropdown for page selection */}
      <FormControl>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
            Page
          </Typography>
          <Select
            value={curPage}
            onChange={handleDropdownChange}
            sx={{
              width: 120,
              height: 40,
              borderRadius: "25px",
              border: "1px solid #ECEEF6",
              color: '#000000',
              paddingLeft: 1,
            }}
          >
            {Array.from({ length: totalPages }, (_, i) => (
              <MenuItem key={i + 1} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormControl>

      {/* Right Arrow */}
      <IconButton
        onClick={handleNext}
        disabled={curPage === totalPages}
        sx={{
          color: curPage === totalPages ? "#ccc" : "#3f51b5",
          "&:hover": { backgroundColor: "#f0f0f0" },
        }}
      >
        <ArrowRightIcon />
      </IconButton>

      {/* End Icon */}
      <IconButton
        onClick={handleEnd}
        disabled={curPage === totalPages}
        sx={{
          color: curPage === totalPages ? "#ccc" : "#3f51b5",
          "&:hover": { backgroundColor: "#f0f0f0" },
        }}
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

export default CustomPagination;
