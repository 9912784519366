// useConfigurationList.js
import { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from '../sharedHelpers/useHistory';
import { validateItems } from '../../../../../../../utils/validationUtils';

export const useConfigurationList = ({
  initialItems = [],
  getTypeParams,
  requiredField,
  onSave,
  typeConfigMap
}) => {
  const [errors, setErrors] = useState({});
  
  const {
    current: items,
    updateHistory,
    undo,
    redo,
    canUndo,
    canRedo
  } = useHistory(initialItems.map(item => ({ 
    ...item, 
    id: uuidv4(),
    params: item.params || {} 
  })));

  const validateForm = useCallback(() => {
    const { errors, isValid } = validateItems(items, getTypeParams, {
      showValidation: true,
      requiredField,
      itemLabel: 'Function'
    });

    setErrors(errors);
    return isValid;
  }, [items, getTypeParams, requiredField]);

  const createNewItem = useCallback(() => ({
    id: uuidv4(),
    [requiredField]: '',
    params: {}
  }), [requiredField]);

  const handleAdd = useCallback(() => {
    if (validateForm()) {
      updateHistory([...items, createNewItem()]);
    }
  }, [items, updateHistory, validateForm, createNewItem]);

  const handleChange = useCallback((id, field, value) => {
    const newItems = items.map(item => {
      if (item.id !== id) return item;

      if (field === requiredField) {
        const typeConfig = typeConfigMap[value];
        const validParamNames = new Set(
          typeConfig?.params?.map(param => param.name) || []
        );

        const filteredParams = Object.entries(item.params || {})
          .filter(([key]) => validParamNames.has(key))
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});

        return { 
          ...item, 
          [requiredField]: value,
          params: filteredParams
        };
      }

      if (field === 'params') {
        return { 
          ...item, 
          params: { ...item.params, ...value }
        };
      }

      return { ...item, [field]: value };
    });

    updateHistory(newItems);
    setErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[id];
      return newErrors;
    });
  }, [items, updateHistory, requiredField, typeConfigMap]);

  const handleDelete = useCallback((id) => {
    updateHistory(items.filter(item => item.id !== id));
    setErrors(prev => {
      const newErrors = { ...prev };
      delete newErrors[id];
      return newErrors;
    });
  }, [items, updateHistory]);

  const handleSave = useCallback((e) => {
    e?.preventDefault();
    if (validateForm()) {
      const cleanedItems = items.map(({ id, ...rest }) => ({
        [requiredField]: rest[requiredField],
        params: rest.params || {}
      }));
      onSave?.(cleanedItems);
    }
  }, [items, onSave, validateForm, requiredField]);

  const getDisabledItems = useCallback((currentItemId) => {
    return items
      .filter(item => item.id !== currentItemId && item[requiredField])
      .map(item => item[requiredField]);
  }, [items, requiredField]);

  return {
    items,
    errors,
    canUndo,
    canRedo,
    handleAdd,
    handleChange,
    handleDelete,
    handleSave,
    undo,
    redo,
    getDisabledItems
  };
};