import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styles from './SchedulerNew.module.css'
import OnceScheduler from './OnceScheduler';
import DailyScheduler from './DailyScheduler';
import WeeklyScheduler from './WeeklyScheduler';
import MonthlyScheduler from './MonthlyScheduler';
import YearlyScheduler from './YearlyScheduler';
import { getTimeZones, getOccurence } from '../services/scheduleService';

const SchedulerNew = () => {
    const [occurrenceType, setOccurrenceType] = useState()
    const calendar = ["Global", "UAE Calendar", "USA Calendar"]
    const [timeZones, setTimeZones] = useState([])
    const [occurrence, setOccurence] = useState([])
    let accessToken = useSelector((state) => state.auth.accessToken);

    useEffect(() => {
        getTimeZones(accessToken, setTimeZones)
        getOccurence(accessToken, setOccurence)
    }, [])

    const renderComponent = () => {
        switch (occurrenceType) {
            case 1:
                return (
                    <OnceScheduler />
                );
            case 2:
                return (
                    <DailyScheduler />
                );
            case 3:
                return (
                    <WeeklyScheduler />
                );
            case 4:
                return (
                    <MonthlyScheduler />
                );
            case 5:
                return (
                    <YearlyScheduler />
                );
            default:
                return null;
        }
    };

    return (
        <Grid container spacing={2} sx={{ pt: 4 }}>
            <Grid item xs={6}>
                <Grid container direction="column" spacing={2}>
                    <Grid item className={styles.gridItem}>
                        <Typography sx={{ width: "100px" }}>Name:
                            {/* <span style={{ color: "red", marginLeft: "2px" }}>*</span> */}
                        </Typography>
                        <TextField
                            sx={{
                                width: "75%",
                                height: "48px",
                                "& .MuiInputBase-root": {
                                    height: "48px",
                                }
                            }}
                            name="name"
                            variant="outlined"
                            required
                        />
                    </Grid>

                    <Grid item className={styles.gridItem}>
                        <Typography sx={{ width: "100px" }}>Occurrence:</Typography>
                        <Select
                            sx={{
                                width: "75%",
                                height: "48px"
                            }}
                            onChange={(e) => setOccurrenceType(e.target.value)}
                        >
                            {occurrence.map((item, index) => (
                                <MenuItem key={index} value={item.value}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container direction="column" spacing={2}>
                    <Grid item className={styles.gridItem}>
                        <Typography >Calendar:</Typography>
                        <Select
                            sx={{
                                width: "75%",
                                height: "48px"
                            }}
                        >
                            {calendar.map((item, index) => (
                                <MenuItem key={index} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>

                    </Grid>

                    <Grid item className={styles.gridItem}>
                        <Typography sx={{ width: "100px" }}>Time zone:</Typography>
                        <Select
                            sx={{
                                width: "75%",
                                height: "48px"
                            }}
                        >
                            {timeZones.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className={styles.gridItem} sx={{ pb: 4, borderBottom: "1px solid #ECEEF6" }}>
                <Typography sx={{ width: "100px" }}>
                    Description:
                </Typography>
                <TextField
                    multiline
                    rows={3}
                    maxRows={8}
                    sx={{
                        width: "88%",
                        height: "100%",
                        "& .MuiInputBase-root": {
                            height: "100%",
                        },
                        "& .MuiInputBase-input": {
                            height: "100%",
                            resize: "vertical",
                            fontWeight: "400"
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#C4C4C4 !important"
                        },
                    }}
                    name="description"
                    variant="outlined"

                />
            </Grid>



            {renderComponent() && (<Grid item xs={12} md={12} lg={12} xl={12} sx={{ mt: 3, display: "flex", justifyContent: "flex-start", mb: 3, alignItems: "center" }}>
                {renderComponent()}
            </Grid>)}

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mb: 3, alignItems: "center" }} >
                <Button
                    variant="contained"
                    sx={{
                        width: "156px",
                        height: "48",
                        fontSize: "16px",
                        borderRadius: '32px',
                        backgroundColor: '#4F63BE',
                        boxShadow: 'none',
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#4F63BE',
                        },
                    }}
                >
                    Save
                </Button>

            </Grid>
        </Grid>
    )

}

export default SchedulerNew;
