const createPermissionConfig = (permissions)=>{
    const config = {
        sample: { read: false },
        partner: { create: false, read: false, update: false },
        user: { create: false, read: false, update: false },
        userrole: { create: false, read: false },
        customer: { create: false, read: false, update: false },
        permission: { create: false, read: false, update: false },
        group: { create: false, read: false, update: false },
        role: { create: false, read: false, update: false },
        basic: { read: false, update: false },
        customerWorkflow: { create: false, read: false, update: false },
        dashboard: { read: false },
        processflow: { read: false }
      };
      permissions.forEach((perm) => {
        const [category, action] = perm.split('-');
        if (config[category] && config[category][action] !== undefined) {
          config[category][action] = true; // Set permission to true if it exists in config
        }
      });
      return config
}
export default createPermissionConfig