import React, { useRef} from 'react'
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import OutlinedInput from "@mui/material/OutlinedInput";
import { shouldShowCustomerDropdown, shouldShowPartnerdropdown, shouldShowUserTypeDropdown } from './utils/userfilterUtils';
import { handleInfiniteScroll } from './utils/userUltils';

const UserFilter = ({ partnerData, userTypesData, setFilterQuery, filterQuery, customers, onApply,setCustomers,getPartner,nextPageUrl,getCustomer,nextPageCustomers }) => {
  const menuRef = useRef(null);
  const userType = useSelector((state) => state.auth.userDetails?.user_type);
  let accessToken = useSelector((state) => state.auth.accessToken);
  const handleChange = (field, value) => {
    if(field==="partner"){
      setCustomers([])
      
    }
    setFilterQuery((prevState) => ({
      ...prevState,
      [field]: value,
      ...(field === "partner" && { customer: "" }) // Dynamically update based on the input's 'name' attribute
    }));
  };

  return (
    <Box sx={{ flexGrow: 1,p: 2 }}>
      <Grid container spacing={2}>
        <Grid size={4} sx={{ minWidth: 200 }}>
          <InputLabel htmlFor="name" sx={{ mb: 1 }}>Name</InputLabel>
          <TextField
            fullWidth
            sx={{maxWidth:"200px"}}
            inputProps={{ 'aria-label': 'Without label' }}
            size="small"
            name="first_name"
            value={filterQuery.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </Grid>
        {shouldShowUserTypeDropdown(userType) && (
          <Grid size={4} sx={{ minWidth: 200 }}>
            <InputLabel htmlFor="usertype" sx={{ mb: 1 }}>User type</InputLabel>
            <Select
              labelId="types-label"
              id="roles-dropdown"
              fullWidth
              inputProps={{ "aria-label": "Without label" }}
              value={filterQuery.usertype}
              onChange={(e) => handleChange("usertype", e.target.value)}
              input={<OutlinedInput label="Types" />}
              renderValue={(selected) => {
                const selectedType = userTypesData.find((type) => type.value === selected);
                return selectedType ? selectedType.name : <span style={{ color: "#9e9e9e" }}>Select Type</span>;
              }}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select Type</em>
              </MenuItem>

              {Array.isArray(userTypesData) && userTypesData.length > 0 ? (
                userTypesData.map((type) => (
                  <MenuItem key={type.id} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled >No types available</MenuItem>
              )}
            </Select>
          </Grid>
        )}

        {shouldShowPartnerdropdown(userType) && (
          <Grid size={4} sx={{ minWidth: 200 }}>
            <InputLabel htmlFor="partner" sx={{ mb: 1 }}>Partner</InputLabel>
            <Select
              labelId="types-label"
              id="roles-dropdown"
              fullWidth
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                PaperProps: {
                  onScroll: (event) => handleInfiniteScroll(event, nextPageUrl, getPartner),
                  ref: menuRef,
                  style: {
                    maxHeight: 250, // Same height restriction here
                    overflowY: "auto",
                  },
                }               
              }}
              value={filterQuery.partner || ''}
              onChange={(e) => handleChange("partner", e.target.value)}
              input={<OutlinedInput label="Types" />}
              renderValue={(selected) => {
                const selectedType = partnerData.find((type) => type.id === selected);
                return selectedType ? selectedType.name : <span style={{ color: "#9e9e9e" }}>Select Partner</span>;
              }}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select Partner</em>
              </MenuItem>

              {Array.isArray(partnerData) && partnerData.length > 0 ? (
                partnerData.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No types available</MenuItem>
              )}
            </Select>
          </Grid>
        )}
        {shouldShowCustomerDropdown(userType) && (
          <Grid size={4} sx={{ minWidth: 200 }}>
            <InputLabel htmlFor="customer" sx={{ mb: 1 }}>Customer</InputLabel>
            <Select
              labelId="types-label"
              id="roles-dropdown"
              fullWidth
              MenuProps={{
                PaperProps: {
                  onScroll: (event) => handleInfiniteScroll(event, nextPageCustomers, getCustomer),
                  ref: menuRef,
                  style: {
                    maxHeight: 250, // Same height restriction here
                    overflowY: "auto",
                  },
                }               
              }}
              inputProps={{ "aria-label": "Without label" }}
              value={filterQuery.customer || ''}
              onChange={(e) => handleChange("customer", e.target.value)}
              input={<OutlinedInput label="Types" />}
              renderValue={(selected) => {
                const selectedType = customers?.find((type) => type.id === selected);
                console.log("selected type", selectedType)
                return selectedType ? selectedType.customer_id : <span style={{ color: "#9e9e9e" }}>Select customer</span>;
              }}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select Type</em>
              </MenuItem>
              {Array.isArray(customers) && customers.length > 0 ? (
                customers.map((item) => {
                  const stringValue = item.name || item.customer_id;
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {stringValue}
                    </MenuItem>
                  )
                })
              ) : (
                <MenuItem disabled >No customers available</MenuItem>
              )}
            </Select>
          </Grid>

        )}
        <Grid size={4} sx={{ minWidth: 200, margin: "auto 0px 0px" }}>
          <Button
            variant="contained"
            sx={{
              alignItems: 'center',
              width: '100px',
              height: '32px',
              borderRadius: '24px',
              backgroundColor: '#4F63BE',
              boxShadow: 'none',
              textTransform: 'none',
              '& .MuiButton-startIcon': {
                borderRadius: '50%',
                padding: '0px',
              },
            }}
            onClick={onApply}
          >
            Apply
          </Button>
        </Grid>

      </Grid>
    </Box>
  )
}

export default UserFilter