import { fetchApi } from "../utils/apiUtils";



export const fetchCalendarListApi = async (accessToken, currentPage) => {

    const response = await fetchApi(`list-calender/?page=${currentPage}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`, // Explicitly add token
            },
            requiresAuth: true
        }
    );
    return await response;


}
export const fetchCalendarMasterDataApi = async (accessToken) => {

    const response = await fetchApi(`list-country_weekend/`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`, // Explicitly add token
            },
            requiresAuth: true
        }
    );
    return await response;


}

export const fetchHolidayApi = async (accessToken, country) => {

    const response = await fetchApi(`list-holidays/?country_code=${country}&year=2025`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`, // Explicitly add token
            },
            requiresAuth: true
        }
    );
    return await response;


}

export const createCalendarApi = async (accessToken, calendarData) => {
    try {
        const response = await fetchApi('create-calender/',
            {
                method: 'POST',
                body: calendarData,
                requiresAuth: true
            }
        );
        return response;
    } catch (error) {
        return error;
    }

}
export const getCalendarApi = async (accessToken, calendarId) => {
    try {
        const response = await fetchApi(`detail-calender/${calendarId}`,
            {
                method: 'POST',
                requiresAuth: true
            }
        );
        return response;
    } catch (error) {
        return error;
    }

}

export const updateCalendarApi = async (accessToken, calendarId, calendarData) => {
    try {
        const response = await fetchApi(`update-calender/${calendarId}/`,
            {
                method: 'POST',
                body: calendarData,
                requiresAuth: true
            }
        );
        return response;
    } catch (error) {
        return error;
    }

}


export const deleteCalendarApi = async (accessToken, calendarId) => {
    try {
        const response = await fetchApi(`delete-calendar/${calendarId}`,
            {
                method: 'DELETE',
                requiresAuth: true
            }
        );
        return response;
    } catch (error) {
        return error;
    }

}