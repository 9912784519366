import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Box } from "@mui/material";

const TimeFrequency = () => {
    const [frequency, setFrequency] = useState(1)
    const TimeArray = [
        { label: "Hours", value: "3" },
        { label: "Minutes", value: "2" },
        { label: "Seconds", value: "1" }
    ];
    return (

        <div style={{ display: "flex", flexDirection: "column", gap: "20px", width: "100%" }}>
            {/* Frequency Input */}
            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ width: "12%" }}>Every</Typography>
                <TextField
                    value={frequency}
                    onChange={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (value > 60) value = 60; // Restrict max value
                        if (value < 1 || isNaN(value)) value = 1; // Restrict min value
                        setFrequency(value);
                    }}
                    inputProps={{
                        min: 1,
                        max: 60
                    }}
                    type="number"
                    sx={{
                        width: "35%",
                        height: "48px",
                        marginRight: '30px',
                        "& .MuiInputBase-root": {
                            height: "48px",
                        }
                    }}
                />
                <Select
                    sx={{
                        width: "35%",
                        height: "48px"
                    }}
                >
                    {TimeArray.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>

            {/* Time Picker Section */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ display: "flex", alignItems: "center", flexWrap: "nowrap", marginBottom: "16px" }}>
                    <Typography style={{ width: "12%" }}>Start Time:</Typography>
                    <div style={{ width: "35%", marginRight: '30px' }}>
                        <TimePicker
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            sx={{
                                height: "48px",
                                width: "100%",


                                "& .MuiInputBase-root": {
                                    height: "100%",
                                    minHeight: "45px",
                                    display: "flex",
                                    alignItems: "center", padding: "16px",
                                },
                                "& .MuiInputBase-input": {
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "400",
                                }
                            }}
                        />
                    </div>

                    <Typography style={{ marginRight: "16px" }}>End Time:</Typography>
                    <div style={{ width: "35%" }}>
                        <TimePicker
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            sx={{
                                height: "48px",
                                width: "100%",


                                "& .MuiInputBase-root": {
                                    height: "100%",
                                    minHeight: "45px",
                                    display: "flex",
                                    alignItems: "center", padding: "16px",
                                },
                                "& .MuiInputBase-input": {
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "400",
                                }
                            }}
                        />
                    </div>
                </div>
            </LocalizationProvider>
        </div>

    )
}

export default TimeFrequency