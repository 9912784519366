import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Box, Typography, InputLabel } from '@mui/material';
import { createPartnerApi, editPartnerApi } from '../../api/usermanagmentApi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const PartnerForm = ({ partner, partnerId, setAlert, setIsModalOpen, getPartnersData }) => {
  let accessToken = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [partnerResponse, setPartnerResponse] = useState('');
  const partnerConfig = useSelector((state) => state.auth.userDetails?.permissionConfig?.partner);

  const [partnerData, setPartnerData] = useState({
    name: '',
    business_name: '',
    url: '',
    email_id: '',
    number_of_employees: '',
    billing_address: {
      address_line1: '',
      address_line2: '',
      city: '',
      state: '',
      country: '',
      zip: '',
      phone_number: ''
    }
  });

  useEffect(() => {
    setPartnerData(partner);
  }, [partner])

  useEffect(() => {
    if (partnerResponse) {
  
      const timer = setTimeout(() => {
        setPartnerResponse('')

      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [partnerResponse, navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes('billing_address')) {
      const [_, field] = name.split('.');
      setPartnerData((prev) => ({
        ...prev,
        billing_address: { ...prev.billing_address, [field]: value }
      }));
    } else if (name === 'number_of_employees') {
      // Convert string to number if it is `number_of_employees`
      setPartnerData((prev) => ({
        ...prev,
        [name]: Number(value)
      }));
    } else {
      setPartnerData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // try {
    if (partnerId !== null) {

      const response = await editPartnerApi(accessToken, partnerData, partnerId);
      let errorMessages = [];
      if (response.message !== "Partner Updated successfully.") {
        if (response && response.type === "validation_error") {
          const messages = Array.isArray(response.errors)
            ? response.errors.map((err) => `${err.attr}: ${err.detail}`)
            : ["validation error"];
          errorMessages.push(...messages);
        } else {
          errorMessages.push("Failed to update user data.");
        }
      }
      if (errorMessages.length > 0) {
        setAlert((prev) => ({ ...prev, open: true, message: errorMessages, type: "failure" }));
        setPartnerResponse(errorMessages)
        return;
      }
  
      setAlert((prev) => ({ ...prev, open: true, message: response.message, type: "success" }));
      setTimeout(() => {
        setIsModalOpen(false)
        getPartnersData()
      }, 2000);
      setPartnerResponse(response.message || "Partner Updated successfully.")
    } else {

      const response = await createPartnerApi(accessToken, partnerData);
      let errorMessages = [];
      if (response.message !== "Partner created successfully.") {
        if (response && response.type === "validation_error") {
          const messages = Array.isArray(response.errors)
            ? response.errors.map((err) => `${err.attr}: ${err.detail}`)
            : ["validation error"];
          errorMessages.push(...messages);
        } else {
          errorMessages.push("Failed to update user data.");
        }
      }
      if (errorMessages.length > 0) { 
        setAlert((prev) => ({ ...prev, open: true, message: errorMessages, type: "failure" }));
        setPartnerResponse(errorMessages)
        return;
      }
      setAlert((prev) => ({ ...prev, open: true, message: response.message, type: "success" }));
      setTimeout(() => {
        setIsModalOpen(false)
        getPartnersData()
      }, 3000);

      setPartnerResponse(response.message || "Partner Updated successfully.")
    }

  };

  return (
    <Box >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* General Info */}
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="name" sx={{ mb: 1 }}>First Name:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="name"
              value={partnerData.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="name" sx={{ mb: 1 }}>Business Name:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="business_name"
              value={partnerData.business_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="name" sx={{ mb: 1 }}>Website URL:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'

              name="url"
              value={partnerData.url}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="name" sx={{ mb: 1 }}>Email ID:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              name="email_id"
              value={partnerData.email_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="name" sx={{ mb: 1 }}>Number of Employees:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size='small'
              type="number"
              name="number_of_employees"
              value={partnerData.number_of_employees}
              onChange={handleChange}
            />
          </Grid>

          {/* Billing Address */}
          <Grid item xs={12}>

            <Typography variant="h6" gutterBottom>
              Billing Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="Address" sx={{ mb: 1 }}>Address Line 1:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              name="billing_address.address_line1"
              value={partnerData.billing_address.address_line1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor="Address" sx={{ mb: 1 }}>Address Line 2:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              name="billing_address.address_line2"
              value={partnerData.billing_address.address_line2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="city" sx={{ mb: 1 }}>City:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              name="billing_address.city"
              value={partnerData.billing_address.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="State" sx={{ mb: 1 }}>State:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              name="billing_address.state"
              value={partnerData.billing_address.state}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="Country" sx={{ mb: 1 }}>Country:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              name="billing_address.country"
              value={partnerData.billing_address.country}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="ZIPCode" sx={{ mb: 1 }}>ZIP Code:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              name="billing_address.zip"
              value={partnerData.billing_address.zip}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="Phonenumber" sx={{ mb: 1 }}>Phone number:</InputLabel>
            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              name="billing_address.phone_number"
              value={partnerData.billing_address.phone_number}
              onChange={handleChange}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            {(partnerConfig?.create || partnerConfig?.update) && (
              <Button
                variant="contained"
                sx={{
                  alignItems: 'center', width: '160px', height: '32px', borderRadius: '24px', backgroundColor: '#4F63BE', boxShadow: 'none', textTransform: 'none', '& .MuiButton-startIcon': { borderRadius: '50%', padding: '0px', },
                }}
                onClick={handleSubmit}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default PartnerForm;
