import React, { useState, useEffect } from "react";
import { CssBaseline, Box } from "@mui/material";
import WorkflowHeader from "../../components/WorkflowHeader/WorkflowHeader";
import FilterSidebar from "../../components/FilterSidebar/FilterSidebar";
import WorkflowGrid from "../../components/WorkflowGrid/WorkflowGrid";
import styles from "./WorkflowLibraryPage.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
 fetchWorkflowTilesApi,
 fetchTagsApi,
} from "../../../../api/workflowApi";

const WorkflowLibraryPage = () => {
 const [filters, setFilters] = useState({ system: [], dataWrangler: [] });
 const [selectedTags, setSelectedTags] = useState([]);
 const [selectedFunctions, setSelectedFunctions] = useState([]);
 const [tilesData, setTilesData] = useState({ results: [], next: null });
 const [loading, setLoading] = useState(true);
 const [loadingMore, setLoadingMore] = useState(false);
 const [error, setError] = useState(null);

 const dispatch = useDispatch();
 const accessToken = useSelector((state) => state.auth.accessToken);
 const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

 const functionsList = ["Finance", "Retail", "HR", "IT"];

 useEffect(() => {
   const fetchInitialData = async () => {
     try {
      //  if (isAuthenticated && accessToken) {
         const tagsResponse = await fetchTagsApi();

         const categorizedTags = tagsResponse.reduce(
           (acc, tag) => {
             if (tag.tag_type === "System") {
               acc.system = tag.tag_names.map(tagName => ({
                 id: tagName.id,
                 name: tagName.name,
               }));
             } else if (tag.tag_type === "Data Wrangler") {
               acc.dataWrangler = tag.tag_names.map(tagName => ({
                 id: tagName.id,
                 name: tagName.name,
               }));
             }
             return acc;
           },
           { system: [], dataWrangler: [] }
         );

         setFilters(categorizedTags);

         const initialTilesData = await fetchWorkflowTilesApi({
           name: "",
           tags: [],
           functions: [],
         });

         setTilesData(initialTilesData);
      //  } else {
      //    throw new Error("User is not authenticated");
      //  }
     } catch (error) {
       const errorMessage = error?.errors?.length ? 
         error.errors.map(err => err.detail).join('. ') : 
         'Failed to load workflow tiles or systems';
       setError(errorMessage);
       console.error('Error fetching initial data:', error);
     } finally {
       setLoading(false);
     }
   };

   fetchInitialData();
 }, [accessToken, isAuthenticated, dispatch]);

 const handleTagSelection = async (selectedTagIds, selectedFuncs) => {
   setLoading(true);
   setError(null);
   setSelectedTags(selectedTagIds);
   setSelectedFunctions(selectedFuncs);

   try {
     const newTilesData = await fetchWorkflowTilesApi({
       name: "",
       tags: selectedTagIds,
       functions: selectedFuncs,
     });
     setTilesData(newTilesData);
   } catch (error) {
     const errorMessage = error?.errors?.length ? 
       error.errors.map(err => err.detail).join('. ') : 
       'Failed to fetch workflows with selected filters';
     setError(errorMessage);
     console.error('Error fetching filtered workflows:', error);
   } finally {
     setLoading(false);
   }
 };

 const handleLoadMore = async () => {
   if (!tilesData.next || loadingMore) return;

   setLoadingMore(true);
   setError(null);

   try {
     const newData = await fetchWorkflowTilesApi(
       {
         name: "",
         tags: selectedTags,
         functions: selectedFunctions,
       },
       tilesData.next
     );

     setTilesData(prev => ({
       ...newData,
       results: [...prev.results, ...newData.results]
     }));
   } catch (error) {
     const errorMessage = error?.errors?.length ? 
       error.errors.map(err => err.detail).join('. ') : 
       'Failed to load more workflows';
     setError(errorMessage);
     console.error('Error loading more workflows:', error);
   } finally {
     setLoadingMore(false);
   }
 };

 return (
   <Box
     sx={{
       width: "100%",
       margin: "0 auto",
       overflowX: "hidden",
     }}
   >
     <CssBaseline />
     <div className={styles.workflowLibraryPage}>
       <div className={styles.headerAndContent}>
         <WorkflowHeader />
         <div className={styles.mainContent}>
           <FilterSidebar
             systems={filters.system}
             functions={functionsList}
             onTagSelectionChange={handleTagSelection}
           />
           <div className={styles.contentContainer}>
             <WorkflowGrid 
               tiles={tilesData.results}
               hasMore={!!tilesData.next}
               onLoadMore={handleLoadMore}
               loading={loading}
               loadingMore={loadingMore}
               error={error}
             />
           </div>
         </div>
       </div>
     </div>
   </Box>
 );
};

export default WorkflowLibraryPage;