import React, { useEffect, useState,useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import { createUsersApi, fetchCustomersApiDropdown, updateUsersApi, updateUsersRolesApi } from "../../api/usermanagmentApi";
import { isTokenValid } from "../../utils/apiUtils";
import { refreshAccessToken } from "../../utils/tokenUtils";
import { reAuthenticationRequest } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { handleInfiniteScroll } from "./utils/userUltils";

const UsersForm = ({ userData, userRolesData, userTypesData, partnerData, customerData,getPartner ,nextPageUrl,setAlert, setIsModalOpen,getUsersData}) => {

  const menuRef = useRef(null);
  const partnerRef = useRef(null);
  const dispatch = useDispatch()
  let accessToken = useSelector((state) => state.auth.accessToken);
  const userType = useSelector((state) => state.auth.userDetails?.user_type);
  const initialFormData = {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    user_type: '',
    customer_id:'',
    partner_id:'',
    roles: [],

  }
  const [userTypes, setUserTypes] = useState([]);
  const [customerNames, setCustomerNames] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [selectedPartnerId, setSelectedPartnerId] = useState(null);
  const [userResponse, setUserResponse] = useState('');
  const [nextPageCustomers, setNextPageCustomers] = useState('partner/customer-list/?page=1');
  const navigate = useNavigate()
  const [UsersData, setUsersData] = useState(initialFormData);

  useEffect(() => {
    if (userData) {
      const mappedData = Object.keys(initialFormData).reduce((acc, key) => {
        acc[key] = userData.hasOwnProperty(key) ? userData[key] : initialFormData[key];
        return acc;
      }, {});
      setUsersData(mappedData);
    } else {
      setUsersData(initialFormData);

    }
  }, [userData]);
  
  useEffect(() => {
    if (userResponse) {
      const timer = setTimeout(() => {
        setUserResponse('')
      }, 8000);
      // Clean up the timer if the component unmounts before the timeout completes
      return () => clearTimeout(timer);
    }
  }, [userResponse, navigate]);

  useEffect(() => {
    if (customerData) {
      setCustomerNames(customerData)
    }
    if (userTypesData) {
      setUserTypes(userTypesData)
    }
 
  }, [ customerData, userTypesData, userRolesData])
  const getCustomer = async () => {
    try {
      // if (!isTokenValid(accessToken)) {
      //   accessToken = refreshAccessToken();
      //   if (accessToken) dispatch(reAuthenticationRequest(accessToken));
      // }
      const data = await fetchCustomersApiDropdown(accessToken, selectedPartnerId,nextPageCustomers); // Call the API to fetch templates
      if (data != undefined) {
        setCustomerNames((prev) => [...prev, ...data.results]); // Set the 'results' array from the response
        setNextPageCustomers(data.next ? data.next.split("/api/")[1] : null); // Update next page URL
      } else {
        setNextPageCustomers(null);
      }

    } catch (error) {

      //  setLoading(false); // Set loading to false even on error
    }
  };
  useEffect(() => {
    if (UsersData.partner_id !== undefined){
      getCustomer(); 
    }
   
  }, [UsersData.partner_id])
  const handleCustomerChange = (event) => {
    const { value } = event.target; // Destructure value directly
    setUsersData((prevState) => ({
      ...prevState,
      customer_id: value,
    }));
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes('billing_address')) {
      const [_, field] = name.split('.');
      setUsersData((prev) => ({
        ...prev,
        billing_address: { ...prev.billing_address, [field]: value }
      }));
    } else {
      setUsersData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };
  const handleTypeChange = (event) => {
    const { value } = event.target;
    setSelectedUserType(Number(value));
    setUsersData((prevState) => ({
      ...prevState,
      user_type: value,
    }));
  }

  const handleRoleChange = (event) => {
    const {
      target: { value },
    } = event;
    const updatedRoles = userRolesData.filter(role => value.includes(role.id));
    setUsersData((prevState) => ({
      ...prevState,
      roles: updatedRoles, // Store full role objects
    }));
  };
  const handlePartnerChange = (event) => {
    const { value } = event.target; // Destructure value directly
    setNextPageCustomers('partner/customer-list/?page=1')
    setCustomerNames([])
    if (value) {
      setSelectedPartnerId(value)
    }
    setUsersData((prevState) => ({
      ...prevState,
      partner_id: value,
    }));

  }
  const removeEmptyStringKeys = (obj) => {
    const excludedKeys = ["user_type", "phone_number", "email", "last_name", "first_name", "roles"];
    
    return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => {
        return value !== "" || excludedKeys.includes(key)  && key !== "roles";
      })
    );
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const filteredData = removeEmptyStringKeys(UsersData);
    try {
      if (filteredData.id) {
        const { id, ...updatedData } = filteredData
        const [result, result1] = await Promise.all([
          updateUsersApi(accessToken, updatedData, filteredData.id),
          updateUsersRolesApi(accessToken, {
            user_id: filteredData.id,
            roles: Array.isArray(UsersData.roles) ? UsersData.roles.map(role => role.id) : []
          })
        ]);

        // Collect error messages if any API fails
        let errorMessages = [];

        if (result.message!=="User updated successfully.") {
          if (result && result.type === "validation_error") {
            const messages = Array.isArray(result.errors)
              ? result.errors.map((err) => `${err.attr}: ${err.detail}`)
              : ["Unknown validation error"];
            errorMessages.push(...messages);
          } else {
            errorMessages.push("Failed to update user data.");
          }
        }

        if (result1.message !== "User role updated successfully" && result1.message !== "User role created successfully") {
          if (result1 && result1.type === "validation_error") {
            const messages = Array.isArray(result1.errors)
              ? result1.errors.map((err) => `${err.attr}: ${err.detail}`)
              : ["Unknown validation error"];
            errorMessages.push(...messages);
          } 
          else  if (result1 && result1.type === "server_error") {
            const messages = Array.isArray(result1.errors)
              ? result1.errors.map((err) => `${err.attr}: ${err.detail}`)
              : ["Unknown validation error"];
            errorMessages.push(...messages);}
            else {
            errorMessages.push("Failed to update user roles.");
          }
        }
       

        if (errorMessages.length > 0) {
          setUserResponse(errorMessages);
          setAlert((prev) => ({ ...prev, open: true, message: errorMessages, type: "failure" }));
          return;
        }

        setAlert((prev) => ({ ...prev, open: true, message:result.message, type: "success" })); 
        setTimeout(() => {
          setIsModalOpen(false)
          getUsersData()
        }, 2000); 
        setUserResponse(result.message || "User data and roles updated successfully!");

      } else {
        const result = await createUsersApi(accessToken, filteredData);
        // Check if the first API request failed
        if (result.message !=="User registered successfully.") {
          let errorMessages = ["Failed to create user."];
          if (result && result.type === "validation_error" && Array.isArray(result.errors)) {
            errorMessages = result.errors.map(err => `${err.attr}: ${err.detail}`);
          }
          setAlert((prev) => ({ ...prev, open: true, message: errorMessages, type: "failure" }));
          setUserResponse(errorMessages);
          return;
        }
        // Ensure result.data.id exists before making the second API call
        if (!result.data || !result.data.id) {
          return;
        }
        // Extract role IDs (Only if roles exist)
        const roleIds = UsersData.roles?.map(role => role.id) || [];

        // If roles exist, call second API
        if (roleIds.length > 0) {
          const payload = {
            user_id: result.data.id,
            roles: roleIds
          };

          // Call second API (Update Roles)
          const result1 = await updateUsersRolesApi(accessToken, payload);

          // Check if the second API request failed
          if (result.message =="User role created successfully") {
            let errorMessages = ["Failed to update user roles."];

            if (result1 && result1.type === "validation_error" && Array.isArray(result1.errors)) {
              errorMessages = result1.errors.map(err => `${err.attr}: ${err.detail}`);
            }

            setUserResponse(errorMessages);
            setAlert((prev) => ({ ...prev, open: true, message: errorMessages, type: "failure" }));
            return;
          }
        }

        setAlert((prev) => ({ ...prev, open: true, message:result.message, type: "success" })); 
        setTimeout(() => {
          setIsModalOpen(false)
          getUsersData()
        }, 2000); 
        setUserResponse(result.message || "User created and roles updated successfully!");
      }

    } catch (error) {
      setUserResponse(error)
    }
  };
  // Function to determine dropdown visibility
  const shouldShowDropdown1 = () => {
    if (userType === 3 && selectedUserType === 3) return false; // Hide both dropdowns
    if (userType === 2 && selectedUserType === 5) return false; // Only Dropdown 2 should be shown
    if (userType === 2 && selectedUserType === 3) return false; // Only Dropdown 2 should be shown
    if (userType === 4 && selectedUserType === 5) return false; // Only Dropdown 2 should be shown

    return (userType === 0 || userType === 1) && (selectedUserType === 3 || selectedUserType === 5 || UsersData.user_type === 3 || UsersData.user_type === 5);
  };

  const shouldShowDropdown2 = () => {
    if (userType === 3 && selectedUserType === 3) return false; // Hide both dropdowns
    if (userType === 2 && selectedUserType === 5) return true; // Show only Dropdown 2
    if (userType === 2 && selectedUserType === 3) return false; // Only Dropdown 2 should be shown
    if (userType === 4 && selectedUserType === 5) return false; // Show only Dropdown 2

    return (userType === 0 || userType === 1) && (selectedUserType === 5 || UsersData.user_type=== 5);
  };

  return (
    <Box sx={{ pt:3 }}>
    
      <form fullWidth onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* General Info */}
          <Grid item xs={12} sm={6} spacing={2}>
            <InputLabel htmlFor="name" sx={{ mb: 1 }}>First Name:</InputLabel>

            <TextField
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              size="small"
              name="first_name"
              value={UsersData.first_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="last_name" sx={{ mb: 1 }}>Last Name:</InputLabel>
            <TextField
              fullWidth
              size="small"
              name="last_name"
              value={UsersData.last_name}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="phone_no" sx={{ mb: 1 }}>Phone No:</InputLabel>
            <TextField
              fullWidth
              size="small"

              name="phone_number"
              value={UsersData.phone_number}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="email_id" sx={{ mb: 1 }}>Email ID:</InputLabel>
            <TextField
              fullWidth
              size="small"

              name="email"
              value={UsersData.email}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <InputLabel id="types-label" sx={{ mb: 1 }}>
              User Types
            </InputLabel>
            <Select
              labelId="types-label"
              id="roles-dropdown"
              fullWidth
              inputProps={{ "aria-label": "Without label" }}
              value={UsersData.user_type}
              onChange={handleTypeChange}
              input={<OutlinedInput label="Types" />}
              renderValue={(selected) => {
                // Find the user type name based on the stored ID
                const selectedType = userTypes.find((type) => type.value === selected);

                return selectedType ? selectedType.name : <span style={{ color: "#9e9e9e" }}>Select Type</span>;
              }}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>Select Type</em>
              </MenuItem>

              {Array.isArray(userTypes) && userTypes.length > 0 ? (
                userTypes.map((type) => (
                  <MenuItem key={type.id} value={type.value}>
                    {type.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No types available</MenuItem>
              )}
            </Select>

          </Grid>
          <Grid item xs={12} sm={6}>

          </Grid>
          {shouldShowDropdown1() && (
            <Grid item xs={12} sm={6}>
              <InputLabel id="Partner-label" sx={{ mb: 1 }}>
                Partner
              </InputLabel>
              <Select
                labelId="Partner-label"
                id="Partner-dropdown"
                fullWidth
                value={UsersData.partner_id} // Bind state to value prop
                onChange={handlePartnerChange}
                input={<OutlinedInput label="Partner" />}
                MenuProps={{
                  PaperProps: {
                    onScroll:  (event) => handleInfiniteScroll(event, nextPageUrl, getPartner),
                    ref: menuRef,
                    style: {
                      maxHeight: 250, // Same height restriction here
                      overflowY: "auto",
                    },
                  }
                }}
                renderValue={(selected) => {

                  const selectedType = partnerData.find((type) => type.id === selected);
          
                  return selectedType ? selectedType.name : <span style={{ color: "#9e9e9e" }}>Select partner</span>;
                }}
                displayEmpty
              >
                {Array.isArray(partnerData) && partnerData.length > 0 ? (
                  partnerData.map((item,index) => (
                    // <MenuItem key={item.id} value={{ key: item.id, name: item.name }}>
                    <MenuItem key={`${item.id}-${index}`} value={item.id}    >
                      {item.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No partners available</MenuItem>
                )}
                  {nextPageUrl && <MenuItem disabled>Loading...</MenuItem>}

              </Select>
            </Grid>
          )}
          {shouldShowDropdown2() && (
            <Grid item xs={12} sm={6}>
              <InputLabel id="Customer-label" sx={{ mb: 1 }}>
                Customer
              </InputLabel>
              <Select
                labelId="Customer-label"
                id="Customer-dropdown"
                fullWidth
                value={UsersData.customer_id}
                onChange={handleCustomerChange}
                MenuProps={{
                  PaperProps: {
                    onScroll:  (event) => handleInfiniteScroll(event, nextPageCustomers, getCustomer),
                    ref: partnerRef,
                    style: {
                      maxHeight: 250, // Same height restriction here
                      overflowY: "auto",
                    },
                  }
                }}
                input={<OutlinedInput label="Types" />}
                renderValue={(selected) => {
                  const selectedType = customerNames?.find((type) => type.id=== selected);
                  console.log("selected type",selectedType)
                  return selectedType ? selectedType.name: <span style={{ color: "#9e9e9e" }}>Select customer</span>;
                }}
                displayEmpty
              >
                <MenuItem disabled value="">
                  <em>Select Customer</em>
                </MenuItem>
                {Array.isArray(customerNames) && customerNames.length > 0 ? (
                  customerNames.map((item, index) => {
                    const stringValue = item.name || item.customer_id;
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {stringValue}
                      </MenuItem>
                    )
                  })
                ) : (
                  <MenuItem disabled>No customers available</MenuItem>
                )}
                 {nextPageCustomers && <MenuItem disabled>Loading...</MenuItem>}
              </Select>
            </Grid>
          )}
          <Grid item xs={12} >

            <InputLabel id="roles-label" sx={{ mb: 1 }}>
              Role
            </InputLabel>
            <Select
              labelId="roles-label"
              id="roles-dropdown"
              multiple
              value={UsersData.roles.map(role => role.id)}
              onChange={handleRoleChange}
              input={<OutlinedInput label="Types" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((roleId) => {
                    const role = userRolesData.find(r => r.id === roleId);
                    return <Chip key={roleId} label={role?.name} sx={{ backgroundColor: "#E3F2FD", color: "#000" }} />;
                  })}
                </Box>
              )}
              displayEmpty
            >
              <MenuItem disabled value="">
                <em>Select Roles</em>
              </MenuItem>
              {Array.isArray(userRolesData) && userRolesData.length > 0 ? (
                userRolesData.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>No roles available</MenuItem>
              )}
            </Select>
          </Grid>
          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{
                alignItems: 'center',
                width: '160px',
                height: '32px',
                borderRadius: '24px',
                backgroundColor: '#4F63BE',
                boxShadow: 'none',
                textTransform: 'none',
                '& .MuiButton-startIcon': {
                  borderRadius: '50%',
                  padding: '0px',
                },
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default UsersForm;