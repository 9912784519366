// api/notificationApi.js
import { fetchApi } from "../utils/apiUtils";

export const fetchNotificationsApi = async () => {
  return fetchApi('notification-read-list', {
    method: 'GET',
    requiresAuth: true
  });
};

export const markNotificationsAsReadApi = async (notificationIds) => {
  return fetchApi('notification-bulk-mark-read/', {
    method: 'POST',
    requiresAuth: true,
    body: { notification_ids: notificationIds }
  });
};

export const markNotificationsAsUnreadApi = async (notificationIds) => {
  return fetchApi('notification-bulk-mark-unread/', {
    method: 'POST',
    requiresAuth: true,
    body: { notification_ids: notificationIds }
  });
};

export const deleteNotificationsApi = async (notificationIds) => {
  return fetchApi('notification-bulk-delete/', {
    method: 'POST',
    requiresAuth: true,
    body: { notification_ids: notificationIds }
  });
};