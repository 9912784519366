// reducers/authReducer.js


import { isTokenValid } from '../../utils/tokenUtils';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_FAILURE,
    REAUTHENTICATE_REQUEST,
    REAUTHENTICATE_SUCCESS,
    REAUTHENTICATE_FAILED,
    REFRESH_TOKEN_REQUEST,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_FAILURE,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    STORE_TOKENS,
    CLEAR_TOKENS,
    STORE_USER_DETAILS,
    SET_ACCESS_TOKEN,
    LOGOUT_USER,
    CLEAR_SIGNUP_MESSAGE,
    RESET_PASSWORD_REQUEST, 
    RESET_PASSWORD_SUCCESS, 
    RESET_PASSWORD_FAILURE,
    SETNEW_PASSWORD_REQUEST,
    SETNEW_PASSWORD_SUCCESS,
    SETNEW_PASSWORD_FAILURE,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_FAILURE,
    GOOGLE_LOGIN_REQUEST,
    LINKEDIN_LOGIN_SUCCESS,
    LINKEDIN_LOGIN_FAILURE,
    LINKEDIN_LOGIN_REQUEST,
    MICROSOFT_LOGIN_SUCCESS,
    MICROSOFT_LOGIN_FAILURE,
    MICROSOFT_LOGIN_REQUEST,
    SOCIAL_MEDIA_LOGIN_REQUEST, 
    SOCIAL_MEDIA_LOGIN_SUCCESS,
    SOCIAL_MEDIA_LOGIN_FAILURE, 
    SOCIAL_MEDIA_LOGIN_CALLBACK,
    
} from '../actions/authActions';


const initialState = {
    userDetails: {
        // isAuthenticated: localStorage.getItem('accessToken') && isTokenValid(localStorage.getItem('accessToken')),
        first_name: '',
        last_name: '',
        email: '',
        profile_image_url:'',
        permissions:[],
        user_type:null
      },
    isAuthenticated: false,
    accessToken: null,
    refreshToken: null,
    loading: false,
    error: null,
    registering: false,
    registrationSuccess: null,
    registrationError: null,
    partnersData: [],
    paginationMeta: {
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        pageSize: 10,
    },
};
export default function authReducer(state = initialState, action) {
    console.log('auth reducer action.type ',action.type ,"  payload ",action.payload)
    switch (action.type) {
        case REGISTER_REQUEST:
            return {
              ...state,
              registering: true,
              registrationSuccess: null,
              registrationError: null,
            };
          case REGISTER_SUCCESS:
            return {
              ...state,
              registering: false,
              registrationSuccess: action.payload,
            };
          case REGISTER_FAILURE:
            return {
              ...state,
              registering: false,
              registrationError: action.payload,
            };
            case CLEAR_SIGNUP_MESSAGE:
                return {
                    ...state,
                    registrationSuccess: '', // Clear the message
                };
        case LOGIN_REQUEST:
            console.log("LOGIN REQUEST Auth Reducer.......")
            return { ...state, loading: true, error: null };
        case LOGIN_SUCCESS:
            console.log("LOGIN SUCCESS Auth Reducer.......")
            return {
                ...state,
                isAuthenticated: true,
                accessToken: action.payload.access,
                refreshToken: action.payload.refresh,
                loading: false,
            };
        
        case LOGIN_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case LOGOUT_REQUEST:
            return {
                ...state,
                refresh: action.payload.refresh,
                access: action.payload.access,
                loading: true, 
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
                isAuthenticated: false,
                user: {},
            };
        case REFRESH_TOKEN_REQUEST:
            return {
                ...state,
                isRefreshingToken: true,
                error: null, // Clear previous errors
            };
          
        case REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
                accessToken: action.payload.access,
            };
        case REFRESH_TOKEN_FAILURE:
            return {
                ...state,
                isRefreshingToken: false,
                error: action.payload.error,
            };
        case STORE_TOKENS:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                isAuthenticated: true, 
            };
        case CLEAR_TOKENS:
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
            };
        case STORE_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                isAuthenticated: true,
                accessToken: action.payload,
            };
        case LOGOUT_USER:
            return {
                ...state,
                isAuthenticated: false,
                accessToken: null,
            };
        case REAUTHENTICATE_REQUEST:
            console.log("REAUTHENTICATE SUCCESS Auth Reducer.......")
            return {
                ...state,
                // During re-authentication, keep the current state
                loading: true,
                isAuthenticated: false, // Assume false initially until token is validated
                error: null
            };
        case REAUTHENTICATE_SUCCESS:
            console.log("REAUTHENTICATE SUCCESS Auth Reducer.......")
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
            };
        case REAUTHENTICATE_FAILED:
            console.log("RE_AUTHENTICATE....FAILED ")
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                error: action.error,
            };
        case RESET_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload.message,
            };
        case RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.error,
            };
        case SETNEW_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                successMessage: null,
                errorMessage: null,
            };
        case SETNEW_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                successMessage: action.payload.message,
            };
        case SETNEW_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMessage: action.payload.error,
            };
        case GOOGLE_LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                error: null,
            };   
        case GOOGLE_LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload, // Store user data
                error: null,
            };
        case GOOGLE_LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                error: action.payload, // Store error message
            }; 
        case LINKEDIN_LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                error: null,
            };   
        case LINKEDIN_LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload, // Store user data
                error: null,
            };
        case LINKEDIN_LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                error: action.payload, // Store error message
            }; 
        case MICROSOFT_LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                error: null,
            };   
        case MICROSOFT_LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload, // Store user data
                error: null,
            };
        case SOCIAL_MEDIA_LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                error: action.payload, // Store error message
            }; 
        case SOCIAL_MEDIA_LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
                loginData: action.payload,
                error: null,
            };   
        case SOCIAL_MEDIA_LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload, // Store user data
                error: null,
            };
        case SOCIAL_MEDIA_LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                error: action.payload, // Store error message
            }; 
        case SOCIAL_MEDIA_LOGIN_CALLBACK:
            return {
                ...state,
                isAuthenticated: false,
                user: action.payload, // Store user data
                error: null,
            };
        
        default:
            return state;
    }
}



