import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from "@mui/material";
import CustomPagination from "../../common/CustomPagination";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";


const DynamicTable = ({ columns, data, totalPages, currentPage, handlePageChange, totalItems }) => {
    return (
        <Grid2 sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: "1.81px solid #ECEEF6", borderRadius: "25px" }}>
                <Table sx={{ p: 2, borderCollapse: "unset" }} >
                    <TableHead>
                        <TableRow >
                            {columns.map((column) => (
                                <TableCell key={column.field} align={column.align || "left"} sx={{ pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>
                                    {column.headerName}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {columns.map((column) => (
                                    <TableCell key={column.field} align={column.align || "left"} sx={{ pt: 2, pb: 2 }}>
                                        {row[column.field]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
            <Box
                display="flex"
                justifyContent="flex-end"
                alignContent='center'
                sx={{
                    width: '100%',
                    position: 'relative',


                    alignItems: "center",
                    border: '0px solid #ECEEF6',
                }}>
                {totalPages > 1 && (
                    <CustomPagination
                        totalPages={totalPages}
                        curPage={currentPage}
                        onPageChange={handlePageChange}
                    />
                )}

            </Box>
        </Grid2>

    );
};

export default DynamicTable;
