import React from 'react';
import { Grid, Card, CardContent, Typography, Box, Button } from '@mui/material';
import integrationimage from '../assetts/images/Integration.svg';
import workflowimage from '../assetts/images/Workflow.svg';
import experiance_runmybotimg from '../assetts/images/ExperianceRunmybot.svg'
import './Activities.css';
import { Link, useNavigate } from 'react-router-dom';

const Activities = () => {
  const navigate = useNavigate();
  // Handle button click event
  const handlIntegrationButtonClick = () => {
    // Logic to handle the click event
    navigate('/integrations');
    // You can add more functionality here, like navigation, opening a modal, etc.
  };
  const handleWorkflowButtonClick = () => {
    // Logic to handle the click event
    navigate('/workflow-library');
    // You can add more functionality here, like navigation, opening a modal, etc.
  };
  const handleExprunmybotClick = () =>{
    navigate('/exp-runmybot');
  }
  return (
    <Box className="activitiesContainer" sx={{ height: '85vh', padding: { xs: 2,  }}}> {/* Responsive padding */}
      <Grid container spacing={2} sx={{display:"flex"}}>
        {/* Integration Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className="imageCard" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}> {/* Flexible height */}
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <Box className="imageContainer" sx={{display: 'flex',  textAlign: 'center', mt:2, mb: 1, padding: 0, overflow: 'hidden', height: 'auto' }}>
                <img
                  src={integrationimage}
                  alt="Integrations"
                  style={{
                    width: '100%',   // Set width to 100% to fit the container
                    height: 'auto',  // Auto height to maintain aspect ratio
                    
                  }}
                />
              </Box>

              <Box>
                <Typography sx={{ color: 'text.secondary', textAlign: 'left', fontSize: '20px', fontWeight: 600, lineHeight: '24.2px', letterSpacing: '0.01em', mb:1 }}>
                  Integrations
                </Typography>
                <Typography variant="body1" className="cardText">
                  Integrations are connections between multiple applications, services, or systems that enable seamless collaboration. Tailor workflows to meet your specific needs, ensuring smooth and efficient operations.
                </Typography>
                <Typography variant="body1" className="cardText">
                  Choose from our wide range of integrations to link your applications and protocols effortlessly.
                </Typography>
              </Box>
              
              <Box sx={{ textAlign: 'left', marginBottom: 5 }}> 
                <button className="viewButton" onClick={handlIntegrationButtonClick}>View</button>
              </Box> 
            </CardContent>
          </Card>
        </Grid>

        {/* Workflow Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className="imageCard" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Box className="imageContainer" sx={{display: 'flex',  textAlign: 'center', mt:2, mb: 1, padding: 0, overflow: 'hidden', height: 'auto' }}>
                <img
                  src={workflowimage}
                  alt="Workflows"
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </Box>

              <Box>
                <Typography sx={{ color: 'text.secondary', textAlign: 'left', fontSize: '20px', fontWeight: 600, lineHeight: '24.2px', letterSpacing: '0.01em' }}>
                  Workflow Library
                </Typography>
                <Typography variant="body1" className="cardText">
                  Our library provides ready-made solutions with predefined workflows for quick and efficient integration of specific applications, services, or systems. Streamline operations and achieve rapid deployment.
                </Typography>
                <Typography variant="body1" className="cardText">
                  Unlock new automation possibilities with our extensive collection of prebuilt templates.
                </Typography>
              </Box>

              <Box sx={{ textAlign: 'left', marginBottom: 5 }}>
                <button className="viewButton" onClick={handleWorkflowButtonClick}>View</button>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Experience RUNMYBOT Card */}
        <Grid item xs={12} sm={6} md={4}>
          <Card className="imageCard" sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Box className="imageContainer" sx={{display: 'flex',  textAlign: 'center', mt:2, mb: 1, padding: 0, overflow: 'hidden', height: 'auto' }}>
                <img
                  src={experiance_runmybotimg}
                  alt="Experience RUNMYBOT"
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </Box>
              
              <Box>
                <Typography sx={{color: 'text.secondary', textAlign: 'left', fontSize: '20px', fontWeight: 600, lineHeight: '24.2px', letterSpacing: '0.01em' }}>
                <span style={{ fontFamily: "'Inter', sans-serif" }}> Experience </span><span style={{ fontFamily: "'Red Hat Display', sans-serif" , fontStyle: 'italic'}}>RUN</span><span style={{ fontStyle: 'normal', fontWeight:'400' }}>MY</span>BOT
                </Typography>
                <Typography variant="body1" className="cardText">
                <span style={{ fontFamily: "'Inter', sans-serif" }}> Experience </span><span style={{ fontFamily: "'Red Hat Display', sans-serif" , fontStyle: 'italic'}}>RUN</span><span style={{ fontStyle: 'normal', fontWeight:'400' }}>MY</span>BOT is the streamlined version of our premium <span style={{ fontFamily: "'Red Hat Display', sans-serif" , fontStyle: 'italic'}}>RUN</span><span style={{ fontStyle: 'normal', fontWeight:'400' }}>MY</span>BOT product. This free tool gives you access to a curated selection of automations that you can execute anytime, at your convenience.
                </Typography>
                <Typography variant="body1" className="cardText">
                  Discover the power of automation without the need for upfront commitments.
                </Typography>
              </Box>

              <Box sx={{ textAlign: 'left', marginBottom: 5 }}>
                <button className="viewButton" onClick={handleExprunmybotClick}>View</button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>


  );
};

export default Activities;