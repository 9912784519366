import React, { useState, useEffect } from "react";
import styles from "./Sidebar.module.css";
import { Accordion, AccordionDetails, AccordionSummary, Box, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import { ReactComponent as SystemSettingsIcon } from "../../../assetts/images/systemSettings.svg";
import { ReactComponent as DropdownIconClosed } from "../../../assetts/images/dropdownIconClosed.svg";
import { ReactComponent as DropdownIconOpen } from "../../../assetts/images/dropdownIconOpen.svg";
import { ReactComponent as SearchIcon } from "../../../assetts/images/searchIcon.svg";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Sidebar = ({ items, onSelect, setShowSchedulerNew, setShowCalendarNew }) => {
  const [isSystemOpen, setIsSystemOpen] = useState(false);
  const [isDataWranglerOpen, setIsDataWranglerOpen] = useState(true);
  const [selectedTag, setSelectedTag] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [dataWrangler, setDataWrangler] = useState([
    { id: 1, name: "Calendar" },
    { id: 2, name: "Schedule" },
  ]);
  const [selectedFunction, setSelectedFunction] = useState('');
  const handleChange = (event) => {
    setSelectedFunction(event.target.value);
    onSelect(event.target.value); // Notify parent component about the selection
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);
  useEffect(() => {
    if (selectedTag) {
      onSelect(selectedTag);
    }
  }, [selectedTag])
  const handleCheckboxChange = (tagId) => {
    const newSelectedTag = selectedTag === tagId ? null : tagId;
    setSelectedTag(newSelectedTag);
    setShowCalendarNew(false);
    setShowSchedulerNew(false);
  };

  // const filteredFilters = filters.filter((filter) =>
  //   filter.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
  // );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className={styles.IntegrationsSidebar}>
      <div className={styles.searchContainer}>
        <SearchIcon className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search..."
          className={styles.searchInput}
          value={searchTerm}
          onChange={handleSearch}
        />
        <hr className={styles.dottedLine} />
      </div>
      <div className={styles.menu}>
        <div className={styles.listHeader} onClick={() => setIsDataWranglerOpen(!isDataWranglerOpen)}>
          <div className={styles.listHeaderContent}>
            <SettingsSuggestOutlinedIcon sx={{ fontSize: 40 }} />
            <span className={styles.sectionTitle}>Select Function</span>
          </div>
          {isDataWranglerOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isDataWranglerOpen && (
          <ul className={styles.filterList}>
            {items.map((filter) => (
              filter.label.toLowerCase().includes(searchTerm.toLowerCase()) ? (
                <li key={filter.value} className={styles.listItem}>
                  <input
                    type="checkbox"
                    checked={selectedTag === filter.value}
                    className={styles.checkbox}
                    onChange={() => handleCheckboxChange(filter.value)}
                  />
                  <Tooltip title={filter.name} arrow>
                    <span className={styles.listItemText}>{filter.label}</span>
                  </Tooltip>
                </li>
              ) : null
            ))}
          </ul>
        )}
      </div>
      <Box sx={{ width: '100%', maxWidth: 240, bgcolor: 'background.paper' }}>
        {/* <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <settings_suggest sx={{ marginRight: 1 }} > </settings_suggest>
            <Typography variant="h6">Select Function</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl component="fieldset">
              <RadioGroup value={selectedFunction} onChange={handleChange}>
                {items.map((item) => (
                  <FormControlLabel
                    key={item.value}
                    value={item.value}
                    control={<Radio />}
                    label={<Typography
                      variant="palette"
                      sx={{
                        color: selectedFunction === item.value ? 'sidebar.selected' : 'sidebar.normal',
                      }}
                    >
                      {item.label}
                    </Typography>} // Use Typography for better styling
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion> */}
      </Box>
      {/* <div className={styles.menu}>
        <div className={styles.listHeader} onClick={() => setIsSystemOpen(!isSystemOpen)}>
          <div className={styles.listHeaderContent}>
            <SystemSettingsIcon className={styles.icon} />
            <span className={styles.sectionTitle}>By System</span>
          </div>
          {isSystemOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isSystemOpen && (
          <ul className={styles.filterList}>
            {filteredFilters.map((filter) => (
              <li key={filter.id} className={styles.listItem}>
                <input
                  type="checkbox"
                  checked={selectedTag === filter.id}
                  className={styles.checkbox}
                  onChange={() => handleCheckboxChange(filter.id)}
                />
                <Tooltip title={filter.name} arrow>
                  <span className={styles.listItemText}>{filter.name}</span>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
      </div> */}

      {/* <div className={styles.menu}>
        <div className={styles.listHeader} onClick={() => setIsDataWranglerOpen(!isDataWranglerOpen)}>
          <div className={styles.listHeaderContent}>
            <DataWrangler className={styles.icon} />
            <span className={styles.sectionTitle}>Data Wrangler</span>
          </div>
          {isDataWranglerOpen ? (
            <DropdownIconOpen className={styles.expandIcon} />
          ) : (
            <DropdownIconClosed className={styles.expandIcon} />
          )}
        </div>

        {isDataWranglerOpen && (
          <ul className={styles.filterList}>
            {dataWrangler.map((filter) => (
              <li key={filter.id} className={styles.listItem}>
                <input
                  type="checkbox"
                  checked={selectedTag === filter.id} 
                  className={styles.checkbox}
                  onChange={() => handleCheckboxChange(filter.id)}
                />
                <Tooltip title={filter.name} arrow>
                  <span className={styles.listItemText}>{filter.name}</span>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
      </div> */}
    </div>
  );
};

export default Sidebar;
