
import { fetchCustomersApi, fetchPartnersDetailApi, handlePartnerUserFetch } from "../../../api/usermanagmentApi";



export const getPartnerDetail = async ({accessToken,id,setSelectedPartnerDetail,setLoading,setIsModalLoading}) => {

    try {
        // if (!isTokenValid(accessToken)) {
        //     accessToken = refreshAccessToken();
        //     if (accessToken) dispatch(reAuthenticationRequest(accessToken));
        // }
        const data = await fetchPartnersDetailApi(accessToken, id); // Call the API to fetch templates
        console.log('&&&&&&& data ', data)
        if (data != undefined) {
            setSelectedPartnerDetail(data);
        }
        setIsModalLoading(false)
        setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
        console.error('Failed to fetch templates:', error);
        setIsModalLoading(false)
        //setError(true); // Set error state if the API call fails
        setLoading(false); // Set loading to false even on error
    }
};
export const getPartnerCustomerDetails = async ({accessToken,id,page, setPartnerCustomers,setIsModalLoading,setIsModalOpen,setModalType}) => {
    setIsModalLoading(true)
    setIsModalOpen(true)
    setModalType("viewpartnercustomers")
    try {
        // if (!isTokenValid(accessToken)) {
        //     accessToken = refreshAccessToken();
        //     if (accessToken) dispatch(reAuthenticationRequest(accessToken));
        // }
        const data = await fetchCustomersApi(accessToken, id, page); // Call the API to fetch templates
        setPartnerCustomers((prev) => ({
            ...prev,
            data: data.results, // Adjust based on API response
            totalCount: data.count,
            nextPage: data.next ? page + 1 : null,
            prevPage: data.previous ? page - 1 : null,
            currentPage: page,
            totalPages: Math.ceil(data.count / 10), // Assuming 10 items per page
          }));
          setIsModalLoading(false);
        console.log('data.workflow_count ', data.results)
        // setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
        console.error('Failed to fetch templates:', error);
        // setError(true); // Set error state if the API call fails
        setIsModalLoading(false); // Ensure loading state resets on error
        // setLoading(false); // Set loading to false even on error
    }
};
export const getpartnerUsersDetails =async ({accessToken,id,page,setPartnerUsers,setIsModalLoading, setIsModalOpen,setModalType})=>{
    setIsModalLoading(true)
    setIsModalOpen(true)
    setModalType("viewpartnerusers")
    try {
        // if (!isTokenValid(accessToken)) {
        //     accessToken = refreshAccessToken();
        //     if (accessToken) dispatch(reAuthenticationRequest(accessToken));
        // }
        const data = await handlePartnerUserFetch(accessToken, id, page); // Call the API to fetch templates
        setPartnerUsers((prev) => ({
            ...prev,
            data: data.results, // Adjust based on API response
            totalCount: data.count,
            nextPage: data.next ? page + 1 : null,
            prevPage: data.previous ? page - 1 : null,
            currentPage: page,
            totalPages: Math.ceil(data.count / 10), // Assuming 10 items per page
          }));
          setIsModalLoading(false);
        console.log('data.workflow_count ', data.results)
        // setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
        console.error('Failed to fetch templates:', error);
        // setError(true); // Set error state if the API call fails
        setIsModalLoading(false); // Ensure loading state resets on error
        // setLoading(false); // Set loading to false even on error
    }
}