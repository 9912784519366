import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import useRouteBreadcrumbs from './hooks/useRouteBreadcrumbs';
import Settings from './pages/Settings';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Reports from './pages/Reports';
import Profile from './pages/Profile';
import Logs from './pages/Logs';
import MyTemplates from './pages/MyTemplates';
import Inbox from './pages/notifications/inbox/Inbox';
import PrivateRoute from './PrivateRoute';

import Dashboard from './pages/Dashboard';
import DashboardOverview from './components/DashboardOverview';
import WorkflowLibraryPage from './pages/workflows/pages/WorkflowLibraryPage/WorkflowLibraryPage';
import IntegrationsLibraryPage from './pages/integrations/pages/IntegrationsLibraryPage/IntegrationsLibraryPage';
import DetailViewPage from './features/DetailView.js/pages/DetailViewPage/DetailViewPage';

import WorkflowBuilder from './features/workflowBuilder/WorkflowBuilder/WorkflowBuilder';
import EventProPage from './pages/EventProPage';
import ForgotPassword from './pages/password-reset/ForgotPassword';
import SetNewPassword from './pages/password-reset/SetNewPassword'
import ExpRunmybotPage from './pages/exp-runmybot/pages/ExpRunmybotPage/ExpRunmybotPage';
import GoogleLoginCallback from './components/authenthication/GoogleLoginCallback';
import SocialMediaCallback from './components/authenthication/SocialMediaCallback';
import Partner from './pages/UserManagement/Partner';
import PageNotFound from './pages/PageNotFound';
import Customer from './pages/UserManagement/Customer';
import Users from './pages/UserManagement/Users';
import EmailVerificationPage from './pages/EmailVerification/EmailVerification';
import PartnerDashboard from './pages/PartnerDashboard/PartnerDashboard';

const AppRoutes = () => {
  useRouteBreadcrumbs();

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    sessionStorage.setItem('currentPage', location.pathname);
  }, [location]);
  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPage');
    const currentPath = window.location.pathname;
    if (storedPage && storedPage !== currentPath) {
      navigate(storedPage);
    }
  }, [navigate]);
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify-email/:code/:token" element={<EmailVerificationPage />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/setnew-password/:userId/:token" element={<SetNewPassword />} />
      <Route path="/accounts/social/google/login/callback" element={<SocialMediaCallback />} />
      <Route path="/accounts/social/microsoft/login/callback" element={<SocialMediaCallback />} />
      <Route path="/accounts/social/linkedin/login/callback" element={<SocialMediaCallback />} />
      {/* Protected Routes */}
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<DashboardLayout />}>
          <Route path="user-management/partner" element={<Partner />} />
          <Route path="user-management/customer" element={<Customer />} />
          <Route path="user-management/users" element={<Users />} />
          <Route path="choicescreen" index element={<DashboardOverview />} />
          <Route path="dashboard" index element={< Dashboard />} />
          <Route path="partner-dashboard" index element={<PartnerDashboard />} />
          <Route path="integrations" index element={<IntegrationsLibraryPage />} />
          <Route path="workflow-library" index element={<WorkflowLibraryPage />} />
          <Route path="exp-runmybot" index element={<ExpRunmybotPage />} />
          <Route path="event-pro" index element={<EventProPage />} />
          <Route path="my-templates" index element={<MyTemplates />} />
          <Route path="user-inbox/:notificationId?" index element={<Inbox />} />
          <Route path="logs" index element={<Logs />} />
          <Route path="profile" element={<Profile />} />
          <Route path="insight/:type" element={<Reports />} />
          <Route path="settings" element={<Settings />} />
          <Route path="process-flow/:id" element={<DetailViewPage />} />
          <Route path="workflow-builder/:type/:id" element={<WorkflowBuilder />} />
        </Route>
      </Route>
      {/* 404 - Page Not Found */}
      <Route path="*" element={<PageNotFound />} />
    </Routes>

  );
};

export default AppRoutes;
