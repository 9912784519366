// api/processFlowApi.js

import { fetchApi } from "../utils/apiUtils";

export const fetchProcessFlowListApi = async (params, page = 1) => {
  return fetchApi(`run_history_list/?page=${page}`, {
    method: 'POST',
    body: params,
    requiresAuth: true
  });
};

export const fetchProcessFlowDetailApi = async (id) => {
  return fetchApi(`run_history_detail/${id}`, {
    method: 'POST',
    requiresAuth: true
  });
};

export const fetchStatusMasterApi = async () => {
  return fetchApi('run_history_status_master/', {
    method: 'GET',
    requiresAuth: true
  });
};