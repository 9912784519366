import store from '../redux/store'
import {logoutSuccess, setAccessToken, storeTokens} from '../redux/actions/authActions';
import { jwtDecode } from "jwt-decode";
const BASE_URL = 'https://staging.runmybot.com/api';

export const isTokenValid = (token) => {
  if (!token) return false;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // current time in seconds
    return decoded.exp > currentTime;
  } catch (error) {
    console.error('Invalid token:', error);
    return false;
  }
  // const payload = JSON.parse(atob(token.split('.')[1]));
  // return payload.exp * 1000 > Date.now();
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  // console.log('####### refreshAccessToken  ', refreshToken)
  if (!refreshToken) return null;

  const response = await fetch(`${BASE_URL}/token/refresh/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refresh: refreshToken }),
  });

  if (response.ok) {
    const data = await response.json();

    // console.log('data.access ',data.access)
    localStorage.setItem('accessToken', data.access);
    localStorage.setItem('refreshToken',data.refresh);

    return data.access;
  }

  return null;
};

// export const fetchApi = async (endpoint, { method, headers = {}, body, requiresAuth = false } = {}) => {
//   console.log('fetchApi -- endpoint ',endpoint);
//   console.log(' headers ' , headers);
//   console.log(' method ' , method);
//   // try {
//     let accessToken = localStorage.getItem('accessToken');
//     if (requiresAuth && !isTokenValid(accessToken)) {
//       accessToken = await refreshAccessToken();
//       if (!accessToken) {
//         window.alert('Session expired. Please log in again.');
//         localStorage.removeItem('accessToken');
//         localStorage.removeItem('refreshToken');
//         window.location.href = '/login';
//         return; // Stop further execution
//       }
//       store.dispatch(storeTokens({accessToken}));
//       // store.dispatch(setAccessToken(accessToken, refreshToken));
//       // store.dispatch(storeTokens({ accessToken }));
//     }
//     console.log('requiresAuth  ',requiresAuth)
//     const authHeaders = requiresAuth
//       ? { Authorization: `Bearer ${accessToken}` }
//       : {};
//     const combinedHeaders = {
//       'Content-Type': 'application/json',
//       ...headers,
//       ...authHeaders,
//     };
//     console.log('method ',method)
//     console.log('combinedHeaders ',combinedHeaders)
//     console.log(' BASE_URL ' , BASE_URL , '--  endpoint ', endpoint  ,' method :',method ,' accessToken : ',accessToken, ' # body ',body);
//     const response = await fetch(`${BASE_URL}/${endpoint}`, {
//       method,
//       headers: combinedHeaders,
//       body: body ? JSON.stringify(body) : undefined,
//     });
//     console.log('~~~ response ... ',response)
//     const contentType = response.headers.get('Content-Type');

//     console.log('response  ----------- ',response)
//     if (!response.ok) {
//       if(endpoint=='logout/'){
//         localStorage.clear()
//         window.location.href='/login'
//       }
//       return await response.json();
//     }
//     if(endpoint=='logout/'){
//       localStorage.clear()
//       window.location.href='/login'
//     }
//     return await response.json() ;
//     // const text = await response.text();
//     // return text ? JSON.parse(text) : {};
//   // } catch (error) {
//   //   console.error(`Error in fetchApi: ${error.message}`);
//   //   throw error;
//   // }
// };

let refreshInProgress = false;
let refreshPromise = null;
export const fetchApi = async (endpoint, { method, headers = {}, body, requiresAuth = false } = {}) => {
  console.log('fetchApi -- endpoint ',endpoint);
  console.log(' headers ' , headers);
  console.log(' method ' , method);
  try {
    let accessToken = localStorage.getItem('accessToken');
    if (requiresAuth && !isTokenValid(accessToken)) {

      if (refreshInProgress) {
        // Wait for the ongoing refresh to complete
        accessToken = await refreshPromise;
      } else {
        refreshInProgress = true;
        refreshPromise = refreshAccessToken().finally(() => {
          refreshInProgress = false;
          refreshPromise = null;
        });

        accessToken = await refreshPromise;
      }
      if (!accessToken) {
        // window.alert('Session expired. Please log in again.');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        localStorage.clear();
        // store.dispatch(logoutSuccess())
        // sessionStorage.clear();
        window.location.href = '/login';
        return;
      }
      store.dispatch(storeTokens({accessToken}));
    }
    console.log('requiresAuth  ',requiresAuth)
    const authHeaders = requiresAuth
      ? { Authorization: `Bearer ${accessToken}` }
      : {};
    const combinedHeaders = {
      'Content-Type': 'application/json',
      ...headers,
      ...authHeaders,
    };
    console.log('method ',method)
    console.log('combinedHeaders ',combinedHeaders)
    console.log(' BASE_URL ' , BASE_URL , '--  endpoint ', endpoint  ,' method :',method ,' accessToken : ',accessToken, ' # body ',body);
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method,
      headers: combinedHeaders,
      body: body ? JSON.stringify(body) : undefined,
    });
    console.log('~~~ response ... ',response)
    const contentType = response.headers.get('Content-Type');

    console.log('response  ----------- ',response)
    if (!response.ok) {
      if(endpoint=='logout/'){
        localStorage.clear()
        window.location.href='/login'
      }
      const errorData = await response.json();
      throw errorData;
    }
    if(endpoint=='logout/'){
      localStorage.clear()
      window.location.href='/login'
    }
    return await response.json();
  } catch (error) {
    console.error(`Error in fetchApi:`, error);
    throw error;
  }
};