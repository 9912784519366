// customerService.js

import { CustomerEditApi, handleCustomerUserFetch } from "../../../api/usermanagmentApi";

export const fetchCustomerUsers = async ({
  accessToken,
  id,
  page,
  setCustomerUsers,
  setIsModalLoading,
  setIsModalOpen,
  setModalType,
  setAlert
}) => {
  setIsModalLoading(true);
  setIsModalOpen(true);
  setModalType("viewUsers");

  try {
    const customerUsersdata = await handleCustomerUserFetch(accessToken, id, page);

    setCustomerUsers((prev) => ({
      ...prev,
      data: customerUsersdata.results, // Adjust based on API response
      totalCount: customerUsersdata.count,
      nextPage: customerUsersdata.next ? page + 1 : null,
      prevPage: customerUsersdata.previous ? page - 1 : null,
      currentPage: page,
      totalPages: Math.ceil(customerUsersdata.count / 10), // Assuming 10 items per page
    }));

    setIsModalLoading(false);

  } catch (error) {
    console.error("Failed to fetch customer users:", error);
    const errorMessage = error?.errors?.length ? 
    error.errors.map(err => err.detail).join('. ') : 
    'Failed to load';
    setAlert((prev) => ({ ...prev, open: true, message:errorMessage , type: "failure" })); 
    setIsModalLoading(false); // Ensure loading state resets on error
    setIsModalOpen(false)
  }
};

export const handleCustomerEdit = async ({accessToken, id,setAlert,setIsModalLoading,setCustomerData})=>{
 try{
    const response = await CustomerEditApi(accessToken,id);
    setCustomerData(response)
    setIsModalLoading(false)

 }
 catch(error)
 {
  const errorMessage = error?.errors?.length ? 
  error.errors.map(err => err.detail).join('. ') : 
  'Failed to load';
  setAlert((prev) => ({ ...prev, open: true, message:errorMessage , type: "failure" })); 

 }
}