import { createTheme } from '@mui/material/styles';
import '@fontsource/inter'; // Ensure the font is imported

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    heading: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '24.2px',
      letterSpacing: '0.01em',
    },
    suheadding: {
      fonSize: 20,
      fontWeight: 600,
      lineHeight: 24.,
      letterSpacing: '0.01em',
      textAlign: 'left',
      color: '#000000'
    },
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    tableCellHeader: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16.94px',
      letterSpacing: '0.01em',
      color: '#949494'
    },
    tableCellData: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '16.94px',
      letterSpacing: '0.01em',
      color: '#000000'
    },

  },
  palette: {
    primary: {
      main: '#4F63BE',
    },
    secondary: {
      main: '#7398C7', // Example secondary color
    },
    text: {
      primary: '#FFFFFF', // Example text color
      secondary: '#000000',
    },
    sidebar: {
      normal: '#6B6B6B',
      selected: '#4F63BE'
    },
    background: {
      default: '#F5F9FF', // Example background color
      paper: '#FFFFFF', // Background color for paper components
    },

    // --------------------- done--------------------- ///
    success: {
      main: '#62E4B5',
    },
    inprogress: {
      main: '#E4C554'
    },
    warning: {
      main: '#FF8F51'

    },
    error: {
      main: '#EF6A6A'

    }
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter, sans-serif', // Ensure this applies to wrapper
        },
        select: {
          fontFamily: 'Inter, sans-serif', // Apply to selected value
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '19.36px',
          color: '#6B6B6B',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter, sans-serif',
          fontSize: '16px',
          color: '#6B6B6B',
          borderRadius:"8px"
        },
        input: {
          fontFamily: 'Inter, sans-serif',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '19.36px',
          color: '#6B6B6B',
        },
        popupIndicator: {
          color: '#6B6B6B !important', // Dropdown arrow color
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          color: '#6B6B6B',
          fontFamily: 'Inter, sans-serif',
          '&.Mui-selected': {
            color: '#4F63BE', // Text color when selected
            backgroundColor: '#ECEEF6', // Background color when selected
          },
          '&.Mui-disabled': {
            color: '#B0B0B0 !important', // Light gray color for disabled items
            backgroundColor: 'transparent !important', // Ensure no background color
          },
          '&.Mui-selected:hover': {
            backgroundColor: '#E0E4F2', // Lighter background on hover
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '36px',
          padding: '0.5rem',
          border: '1px solid #e2e8f0',
          borderRadius: '4px',
          fontSize: '0.875rem',
          width: '100%',
          transition: 'all 0.2s ease',
          boxSizing: 'border-box',
          background: 'white',
          '&:hover': {
            borderColor: '#4F63BE',
          },
          '&.Mui-focused': {
            outline: 'none',
            borderColor: '#4F63BE',
            boxShadow: '0 2px 4px rgba(79, 99, 190, 0.1)',
          },
        },
        notchedOutline: {
          border: 'none  !important', // Remove the border
        },

        input: {
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '19.36px',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
          color: '#6B6B6B',
          '&::placeholder': {
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.94px',
            textAlign: 'left',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            color: '#C4C4C5',
          },
        },
      },

    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '36px', // Set height
          padding: '0.5rem', // Padding inside the input
          border: '1px solid #DEDEDE', // Default border color
          borderRadius: '4px', // Rounded corners
          fontSize: '0.875rem',
          width: '100%',
          transition: 'all 0.2s ease',
          boxSizing: 'border-box',
          background: 'white', // Background color

          '&:hover': {
            borderColor: '#4F63BE', // Change border on hover
          },
          '& .MuiSelect-select': {
            height: '100%', // Ensure the inner div takes full height
            display: 'flex',
            alignItems: 'center', // Align selected text vertically
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {

            borderColor: 'transparent',
          },
        },
        notchedOutline: {
          border: 'none  !important', // Remove the border
          display: 'none !important',

        },
        input: {
          fontFamily: 'Inter, sans-serif',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '19.36px',
          textAlign: 'left',
          color: '#6B6B6B',

          '&::placeholder': {
            fontFamily: 'Inter, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            color: '#C4C4C5', // Placeholder color
          },
          ':-webkit-autofill': {
            backgroundColor: 'none !important',
            color: '#6B6B6B',
            // WebkitBoxShadow: '0 0 0px 1000px white inset !important',
            transition: 'background-color 5000s ease-in-out 0s',
            height: '1px'
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 500,
          color: '#1c1c1c', // Default text color for label
          lineHeight: '19.36px',



        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          padding: '8px 16px',
          textTransform: 'none',
          fontWeight: 600,
        },
        contained: {
          backgroundColor: '#4F63BE',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#3e50b4',
          },
        },
        outlined: {
          borderColor: '#4F63BE',
          color: '#4F63BE',
          '&:hover': {
            borderColor: '#3e50b4',
            color: '#3e50b4',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter, Arial, sans-serif',
          textAlign: 'left',
          textUnderlinePosition: 'from-font',
          textDecorationSkipInk: 'none',
          borderBottom: 'none', // No border
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#000000',
          fontWeight: 600,
          fontSize: '14px',
          ml: 2,
          lineHeight: '16.94px',
          borderBottom: 'none', // No bottom border
          padding: '8px 14px',
          userSelect: 'none',
          // '&:hover': { backgroundColor: '#f0f0f0'},
        }
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {

          '& .MuiTableCell-root': {
            color: '#949494', // Change header text color
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "16.94px"

          },
        },
      },
    },
  },
});

export default theme;