import React,{useEffect} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSidebar,hideSidebar,showSidebar } from '../redux/actions/actions';  // Import the toggle action
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Navbar';
import { Box } from '@mui/material';

const DashboardLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const isVisible = useSelector(state => {
    return state.sideBar?.isVisible;
  });

  // Define routes where the sidebar should be hidden (optional)
  const pagesWithHiddenSidebar = ['/login', '/signup', '/forgot-password','/exp-runmybot','/event-pro'];

  const isOnRestrictedPage = pagesWithHiddenSidebar.includes(location.pathname);


 
  useEffect(() => {
    if (isOnRestrictedPage) {
      dispatch(hideSidebar()); // Ensure sidebar is hidden on these pages
    } else {
      dispatch(showSidebar()); // Restore sidebar when leaving a restricted page
    }
  }, [isOnRestrictedPage, dispatch]);

  // Sidebar should follow the Redux visibility state
  const isHidden = !isVisible;
  

  
  
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Topbar />
      <Sidebar 
        isHidden={isHidden} 
        toggleSidebar={handleToggleSidebar} 
        sx={{ marginTop:'73px' }}  
      />
      
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: isHidden ? 0 : '273px',
          transition: 'margin-left 0.3s ease',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardLayout;

// import React, { useEffect, useState } from 'react';
// import { Outlet, useLocation } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import Sidebar from '../components/Sidebar';
// import Topbar from '../components/Navbar';
// import { Box } from '@mui/material';
// // import { collapseSidebar, expandSidebar } from '../actions/sidebarActions';

// const DashboardLayout = ({ children }) => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
//   const dispatch = useDispatch();
//   // Toggle sidebar visibility
//   const toggleSidebar = () => {
//     setSidebarHidden(!isSidebarHidden);
//   };
//   const location = useLocation();
//   // Define routes where the sidebar should not be shown (e.g., login)
//   const noSidebarRoutes = ['/login'];
//   // Define pages where the sidebar should be hidden
//   const pagesWithHiddenSidebar = ['/login','/signup', '/integrations', '/workflow-library','/workflow-builder','/exp-runmybot','/event-pro'];
//   // Check if the current page should hide the sidebar
//   let cleanedPath = location.pathname.replace(/\/\d+$/, '');  
//   const isPageWithHiddenSidebar = pagesWithHiddenSidebar.includes(cleanedPath);

//   // State to control the sidebar visibility
//   const [isSidebarHidden, setSidebarHidden] = useState(false);
//   useEffect(() => {
//     // Update the sidebar visibility based on the current page
//     setSidebarHidden(isPageWithHiddenSidebar);
//   }, [location.pathname, isPageWithHiddenSidebar]);


//   return (
//     <Box sx={{ display: 'flex' }}>
   
//       <Sidebar isHidden={isSidebarHidden} toggleSidebar={toggleSidebar} />
//       <Topbar toggleSidebar={toggleSidebar} />
//       <Box
//         className="content"
//         sx={{
//           width: '100%',
//           marginLeft: isSidebarHidden ? '0' : '250px', // Apply dynamic margin based on sidebar visibility
//           transition: 'margin-left 0.3s ease', // Optional: Add smooth transition effect
//         }}
//       >
//         <Outlet />
//       </Box>
      
//       </Box>
    
//   );
// };

// export default DashboardLayout;
