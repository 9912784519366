import React, { useState, useEffect } from "react";
import { MoveRight, Plus, Trash2 } from "lucide-react";
import CustomDropdown from "../../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import DynamicPopup from "../../../../components/reusableComponents/DynamicPopup/DynamicPopup";
import FieldMappingContent from "../FormContent/FieldMappingContent";
import styles from "./MappingForm.module.css";

const MappingForm = ({ mappingConfig, onMappingChange }) => {
  const [showFieldMapping, setShowFieldMapping] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);
  const [selectedMappingId, setSelectedMappingId] = useState(null);
  const [mappingsArray, setMappingsArray] = useState([]);

  const { currentNode, sourceNodes, relevantMappings } = mappingConfig;

  useEffect(() => {
    if (relevantMappings && relevantMappings.length > 0) {
      const validMappings = relevantMappings.filter(mapping =>
        sourceNodes.some(node => node.data?.function_identifier === mapping.source_task)
      );

      if (validMappings.length > 0) {
        const initialMappings = validMappings.map(mapping => ({
          sourceId: sourceNodes.find(node =>
            node.data?.function_identifier === mapping.source_task
          )?.id,
          isExisting: true,
          mappingData: mapping
        }));
        setMappingsArray(initialMappings);
      } else {
        setMappingsArray([{}]);
      }
    } else {
      setMappingsArray([{}]);
    }
  }, [relevantMappings, sourceNodes]);

  const handleAddRow = () => {
    setMappingsArray(prev => [...prev, {}]);
  };

  const handleRemoveRow = (index) => {
    const mapping = mappingsArray[index];
    if (mapping.isExisting) {
      const updatedMappings = currentNode.mappings.filter(
        m => m.id !== mapping.mappingData.id
      );
      onMappingChange(updatedMappings);
    }
    setMappingsArray(prev => prev.filter((_, i) => i !== index));
  };

  const handleSourceSelect = (value, index) => {
    setMappingsArray(prev => {
      const updated = [...prev];
      updated[index] = { ...updated[index], sourceId: value };
      return updated;
    });
  };

  const getSourceNode = (sourceId) => {
    return sourceNodes.find(n => n.id === sourceId);
  };

  const handleOpenMapping = (index) => {
    const mapping = mappingsArray[index];
    setSelectedRowIndex(index);
    setSelectedMappingId(mapping.isExisting ? mapping.mappingData.id : 'new');
    setShowFieldMapping(true);
  };

  const canAddNewRow = () => {
    if (mappingsArray.length === 0) return true;
    const lastMapping = mappingsArray[mappingsArray.length - 1];
    return !!lastMapping.sourceId && !!currentNode?.id;
  };

  const getDisabledSourceIds = (currentIndex) => {
    return mappingsArray
      .filter((mapping, index) => index !== currentIndex && mapping.sourceId)
      .map(mapping => mapping.sourceId);
  };

  return (
    <>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.headerRow}>
              <th className={styles.headerCell}>Mapping Name</th>
              <th className={`${styles.headerCell} ${styles.sourceNode}`}>Source Node</th>
              <th className={styles.headerCell}></th>
              <th className={`${styles.headerCell} ${styles.targetNode}`}>Target Node</th>
              <th className={`${styles.headerCell} ${styles.actions}`}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {mappingsArray.map((mapping, index) => {
              const sourceId = mapping.sourceId;
              const canMapFields = !!sourceId;
              const isExisting = mapping.isExisting;
              const disabledSourceIds = getDisabledSourceIds(index);

              return (
                <tr key={index} className={styles.dataRow}>
                  <td className={styles.cell}>
                    {isExisting && (
                      <span className={styles.mappingName}>
                        {mapping.mappingData.name}
                      </span>
                    )}
                  </td>
                  <td className={styles.cell}>
                    {isExisting ? (
                      <input
                        className={styles.input}
                        value={getSourceNode(sourceId)?.data?.dynamic?.properties?.label || sourceId}
                        disabled
                      />
                    ) : (
                      <CustomDropdown
                        items={sourceNodes.map(node => ({
                          value: node?.id || null,
                          label: node.data?.dynamic?.properties?.label || node.id,
                        }))}
                        value={sourceId}
                        onChange={(e) => handleSourceSelect(e, index)}
                        disabledItems={disabledSourceIds}
                        style={{
                          borderRadius: "8px",
                          color: "#6B6B6B",
                          width: '100%',
                          height: '42px',
                          background: "#F0F7FF"
                        }}
                      />
                    )}
                  </td>
                  <td className={styles.arrowCell}>
                    <MoveRight className={styles.arrowIcon} size={16} />
                  </td>
                  <td className={styles.cell}>
                    <input
                      className={styles.input}
                      value={currentNode.data?.dynamic?.properties?.label || currentNode.id}
                      disabled
                    />
                  </td>
                  <td className={styles.actionsCell}>
                    <div className={styles.actionsWrapper}>
                      <button
                        className={`${styles.mapButton} ${!canMapFields ? styles.disabled : ''}`}
                        onClick={() => handleOpenMapping(index)}
                        disabled={!canMapFields}
                      >
                        <MoveRight size={16} />
                        <span>Map Fields</span>
                      </button>
                      <button
                        onClick={() => handleRemoveRow(index)}
                        className={styles.removeButton}
                        title="Remove mapping"
                      >
                        <Trash2 size={16} />
                      </button>
                      <button
                        onClick={handleAddRow}
                        disabled={!canAddNewRow()}
                        className={`${styles.removeButton} ${!canAddNewRow() ? styles.disabled : ''}`}
                      >
                        <Plus size={16} />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className={styles.addButtonWrapper}>

      </div>

      {showFieldMapping && (
        <DynamicPopup
          title={`Mapping [${getSourceNode(mappingsArray[selectedRowIndex].sourceId)?.data?.dynamic?.properties?.label || 'Source'
            } → ${currentNode?.data?.dynamic?.properties?.label || 'Target'
            }]`}
          onClose={() => {
            setShowFieldMapping(false);
            setSelectedMappingId(null);
          }}
          draggable={false}
          style={{ minHeight: "30rem", minWidth: "70%", background: "#fff" }}
          expanded
        >
          <FieldMappingContent
            sourceNode={getSourceNode(mappingsArray[selectedRowIndex].sourceId)}
            currentNode={currentNode}
            onMappingChange={onMappingChange}
            initialMappingId={selectedMappingId}
          />
        </DynamicPopup>
      )}
    </>
  );
};

export default MappingForm;