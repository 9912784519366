// api/integrationApi.js
import { fetchApi } from "../utils/apiUtils";

export const fetchIntegrationTilesApi = async (params, url = null) => {
 const endpoint = url ? url : 'integration-list/';
 return fetchApi(endpoint, {
   method: 'POST',
   requiresAuth: true,
   body: params
 });
};

export const fetchTagsApi = async () => {
 return fetchApi('tag-list/', {
   method: 'GET', 
   requiresAuth: true
 });
};