import React, { useState, useRef, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { DataGrid, GridColumnMenu, GridColumnMenuProps } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import 'chart.js/auto';
import GraphicslReports from '../components/reports/GraphicalReports';
// import TabularReports from '../components/reports/TabularReports';


const Reports = () => {

  const parentRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState("100%");
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const { id, type } = useParams();
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setMaxWidth(entry.contentRect.width - 5); // Update width when div resizes
        console.log("widthm ---> ", parentRef.current)
      }
    });



    if (parentRef.current) {
      observer.observe(parentRef.current);
      console.log("width ---> ", parentRef.current)
    }

    return () => {
      if (parentRef.current) {
        observer.unobserve(parentRef.current);
        console.log("width1 ---> ", parentRef.current)
      }
    };
  }, []);

  let xValues = ["EMEA", "APAC", "LATAM"];
  let inner = "Success";
  let outer = "Errors";
  let barColors = [
    "#5264AE",
    "#CA82B7",
    "#595A5A",
    // "#A9ABD6"
  ];
  let yValues1 = [5, 10, 8];
  let yValues2 = [95, 240, 142];
  let yValues2_1 = [6, 9, 30];
  let yValues2_2 = [668, 450, 520];
  let yValues3_1 = [70, 175, 100];
  let yValues3_2 = [20, 50, 40];
  let yValues3_3 = [10, 20, 10];
  let yValues3_4 = [0, 5, 2]


  function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slotProps={{
          columnMenuSortAsc: { display: "none" }, // Hides "Sort by Asc"
          columnMenuSortDesc: { display: "none" }, // Hides "Sort by Desc"
        }}
      />
    );
  }
  const columns = [
    { field: 'id', headerName: 'SL#', flex: 1 },
    { field: 'DocNO', headerName: 'Document Number', flex: 1 },
    { field: 'RevDocNo', headerName: 'Reversal Doc Number', flex: 1 },
    {
      field: 'Status', headerName: 'Status', flex: 1, renderCell: (params) => (
        <Box display="flex" alignItems="center">
          {params.value === "In Progress" && (
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: "50%",
                backgroundColor: "orange",
                marginRight: 1,
              }}
            />
          )}
          {params.value}
        </Box>
      ),
    },
    { field: 'Username', headerName: 'User Name', flex: 1 },
    { field: 'CreatedOn', headerName: 'Created On', flex: 1 },
    { field: 'ReceivedOn', headerName: 'Received On', flex: 1 },
    { field: 'JEType', headerName: 'JE Type', flex: 1 },
    { field: 'Message', headerName: 'Message', flex: 1 },
    { field: 'TaskName', headerName: 'Task Name', flex: 1 },
  ];
  const data = [
    {
      id: 1,
      DocNO: "503119XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "James",
      CreatedOn: "16:25 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00413",

    },
    {
      id: 2,
      DocNO: "503118XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "James",
      CreatedOn: "16:15 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00412",

    },
    {
      id: 3,
      DocNO: "503117XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "Oliver",
      CreatedOn: "16:10 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00411",

    },
    {
      id: 4,
      DocNO: "503116XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "James",
      CreatedOn: "16:09 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00410",

    },
    {
      id: 5,
      DocNO: "503115XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "Oliver",
      CreatedOn: "16:00 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00409",

    },
    {
      id: 6,
      DocNO: "503114XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "James",
      CreatedOn: "15:45 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00408",

    },
    {
      id: 7,
      DocNO: "503113XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "Oliver",
      CreatedOn: "15:35 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00407",

    },
    {
      id: 8,
      DocNO: "503112XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "Oliver",
      CreatedOn: "15:30 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00406",

    },
    {
      id: 9,
      DocNO: "503111XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "James",
      CreatedOn: "15:20 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00405",

    },
    {
      id: 10,
      DocNO: "503110XKP",
      RevDocNo: "",
      Status: "In Progress",
      Username: "James",
      CreatedOn: "15:10 Jul 12, 2024",
      ReceivedOn: "",
      JEType: "JE",
      Message: "Successfully Updated",
      TaskName: "T00404",

    }
  ];


  const graphsData = [
    {
      type: "bar",
      title: "Monthly Summary of Journal Entry Posting from SFTP to NetSuite",
      data: {
        labels: ["EMEA", "APAC", "LATAM"],
        datasets: [
          {
            label: "Success",
            backgroundColor: "#5264AE",
            data: [300, 200, 450]
          }, {
            label: "Failure",
            backgroundColor: "#CA82B7",
            data: [25, 35, 10]
          }
        ]
      },
    },
    {
      type: "bar",
      title: "Monthly Failure Summary",
      data: {
        labels: ["Bot Error", "Netsuite Error", "Invalid Data"],
        datasets: [
          {
            label: "Failure Count",
            barPercentage: 0.45,
            backgroundColor: ["#CA82B7", "#CA82B7", "#CA82B7"],
            data: [50, 75, 500],
            hoverBorderColor: "red",
            hoverBorderWidth: 2

          }
        ],
      },
    },
    // {
    //   type: "doughnut",
    //   title: "Journal Entry Posting from Sharepoint to NetSuite - Monthly Summary",
    //   data: {
    //     labels: xValues,
    //     datasets: [{
    //       label: outer,
    //       backgroundColor: barColors,
    //       data: yValues1,
    //     },
    //     {
    //       label: inner,
    //       backgroundColor: barColors,
    //       data: yValues2,
    //     }]
    //   }
    // },
    {
      type: "bar",
      title: "Journal Entry Posting from Sharepoint to NetSuite - Failure Type",
      data: {
        labels: ["Failure Summary"],
        datasets: [
          {
            label: "Journal Entry size more than 15MB",
            backgroundColor: "#7398C7",
            data: [1]
          }, {
            label: "Bot unable to launch NetSuite URL",
            backgroundColor: "#5264AE ",
            data: [1]
          }, {
            label: "Bot unable to login to NetSuite",
            backgroundColor: "#ADE0EA",
            data: [2]
          },
          {
            label: "Bot unable to navigate between screens",
            backgroundColor: "#A9ABD6",
            data: [2]
          }, {
            label: "Multiple search results for Journal Entry",
            backgroundColor: "#CA82B7",
            data: [3]
          },
          {
            label: "Journal Entry file not found",
            backgroundColor: "#595A5A",
            data: [8]
          }
        ]
      },
    },

    {
      type: "doughnut",
      style: {
        cutout: 75
      },

      title: "Journal Entry from SharePoint - Monthly Summary",
      data: {
        labels: xValues,
        datasets: [{
          label: outer,
          backgroundColor: barColors,
          data: yValues2_1,
        },
        {
          label: inner,
          backgroundColor: barColors,
          data: yValues2_2,
        }]
      }
    },
    {
      type: "doughnut",
      style: {
        cutout: 35
      },
      title: "Journal Entry Posting from Sharepoint to NetSuite - Case Frequency",
      data: {
        labels: xValues,
        datasets: [{
          label: "WD22 to WD31",
          backgroundColor: barColors,
          data: yValues3_1
        },
        {
          label: "WD15 to WD21",
          backgroundColor: barColors,
          data: yValues3_2
        },
        {
          label: "WD8 to WD14",
          backgroundColor: barColors,
          data: yValues3_3
        },
        {
          label: "WD1 to WD7",
          backgroundColor: barColors,
          data: yValues3_4
        }]
      }
    },
  ];




  return (
    <Box ref={parentRef} sx={{ p: 5, mt: 8 }}>
      {type === "graph" ? <GraphicslReports data={graphsData} /> :
        <div style={{ display: "flex", flexDirection: "column", maxWidth: maxWidth, width: "100%", height: "100%", overflow: "hidden" }}>
          <DataGrid
            rows={data}
            columns={columns}
            pagination
            pageSizeOptions={[10, 20]} // Dropdown for page sizes
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            slots={{
              columnMenu: CustomColumnMenu
            }}
            disableColumnSelector
            slotProps={{
              pagination: {
                sx: {
                  "& .MuiTablePagination-root": { color: "#444", fontSize: "14px", padding: "0px" },
                  "& .MuiTablePagination-displayedRows": { color: "#444" },
                  "& .MuiTablePagination-selectLabel": { color: "#444" },
                  "& .MuiTablePagination-input": {
                    width: "80px"
                  },
                  "& .MuiTablePagination-select": {
                    backgroundColor: "#f0f0f0",
                    borderRadius: "5px",
                    padding: "6px 20px",
                  },
                },
              },
            }}
            sx={{
              overflow: "hidden",
              "& .MuiDataGrid-root": {
                fontSize: "14px",
                color: "#333",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f5f5f5",
                color: "#000",
                fontSize: "16px",
                fontWeight: "bold"
              },
              "& .MuiDataGrid-cell": {
                color: "#444",
                fontSize: "14px"
              }
            }}

          />
        </div>
      }
    </Box >
  );
};

export default Reports;
