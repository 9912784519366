export const shouldShowPartnerdropdown = (userType) => {
    if (userType === 2 || userType === 3 || userType === 4 ||userType === 5 ) {return false;}
    return true // Hide both dropdowns    
  };
  
 export const shouldShowCustomerDropdown = (userType) => {
    if (userType === 4 ||userType === 5 ){return false;  }
    return true  
  

  };
  
 export const shouldShowUserTypeDropdown = (userType) => {
    if (userType === 4 ||userType === 5 ){return false;  }
    return true  
  

  };
  