import React from "react";
import { Snackbar, Alert } from "@mui/material";

const CustomAlert = ({ open, message, type = "info", onClose }) => {
  const severityStyles = {
    success: { backgroundColor: "#4caf50", color: "#fff" },
    failure: { backgroundColor: "#f44336", color: "#fff" },
    warning: { backgroundColor: "#ff9800", color: "#fff" },
    info: { backgroundColor: "#2196f3", color: "#fff" },
  };
  
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000} // Closes after 3 seconds
      onClose={onClose}
      sx={{mt:3}}
      anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position
    >
      <Alert onClose={onClose} severity={type} variant="filled"  sx={{
            width: '100%', ...severityStyles[type]
          }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlert;