import React, { useEffect, useState } from 'react';
import { Settings } from 'lucide-react';
import { useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import { useConfigurationList } from '../sharedHelpers/useConfigurationList';
import ConfigurationLayout from '../sharedHelpers/ConfigurationLayout';
import TransformationRow from './TransformationRow';
import Preloader from '../../../../../../../components/common/Preloader';
import { fetchTransformationTypes } from '../../../../../../../api/mappingTypeApi';

const TransformationContent = ({ onClose, onSave, initialSteps = [] }) => {
 const [transformationTypes, setTransformationTypes] = useState({});
 const [transformationTypeOptions, setTransformationTypeOptions] = useState([]);
 const [isLoading, setIsLoading] = useState(true);
 const [error, setError] = useState(null);
 const [showError, setShowError] = useState(false);
 const accessToken = useSelector(state => state.auth.accessToken);

 const handleCloseError = (event, reason) => {
   if (reason === 'clickaway') return;
   setShowError(false);
 };

 useEffect(() => {
   const loadTransformationTypes = async () => {
     try {
       setIsLoading(true);
       setError(null);
       const response = await fetchTransformationTypes();
       
       setTransformationTypes(response);
       setTransformationTypeOptions(
         Object.entries(response).map(([value, config]) => ({
           value,
           label: config.label
         }))
       );
     } catch (error) {
       const errorMessage = error?.errors?.length ? 
         error.errors.map(err => err.detail).join('. ') : 
         'Failed to load transformation types';
       setError(errorMessage);
       setShowError(true);
       console.error('Error loading transformation types:', error);
     } finally {
       setIsLoading(false);
     }
   };

   if (accessToken) {
     loadTransformationTypes();
   }
 }, [accessToken]);

 const {
   items: steps,
   errors,
   canUndo,
   canRedo,
   handleAdd,
   handleChange,
   handleDelete,
   handleSave,
   undo,
   redo,
   getDisabledItems
 } = useConfigurationList({
   initialItems: initialSteps,
   getTypeParams: (type) => transformationTypes[type]?.params || [],
   requiredField: 'function',
   onSave,
   typeConfigMap: transformationTypes
 });

 const renderTransformationRow = (step, index) => (
   <TransformationRow
     key={step.id}
     step={step}
     functionTypeOptions={transformationTypeOptions}
     transformationTypes={transformationTypes}
     onChange={handleChange}
     onDelete={handleDelete}
     errors={errors[step.id] || {}}
     index={index}
     disabledItems={getDisabledItems(step.id)}
   />
 );

 if (isLoading) {
   return (
     <div className="flex justify-center items-center h-64">
       <Preloader />
     </div>
   );
 }

 return (
   <>
     <ConfigurationLayout
       items={steps}
       onAdd={handleAdd}
       onSave={handleSave}
       onClose={onClose}
       onUndo={undo}
       onRedo={redo}
       canUndo={canUndo}
       canRedo={canRedo}
       emptyStateIcon={Settings}
       emptyStateTitle="No transformation steps configured"
       emptyStateMessage="Click the Add button to create a transformation step"
       headerTitle="Transformation Rules"
       headerSubtitle="Configure transformation rules for this field"
       renderItem={renderTransformationRow}
     />

     <Snackbar 
       open={showError} 
       autoHideDuration={5000} 
       onClose={handleCloseError}
       anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
     >
       <Alert 
         onClose={handleCloseError} 
         severity="error" 
         elevation={6} 
         variant="filled"
       >
         {error}
       </Alert>
     </Snackbar>
   </>
 );
};

export default TransformationContent;