// workflowMappingApi.js
import { fetchApi } from "../utils/apiUtils";

export const createWorkflowMapping = async (params) => {
  return fetchApi('user_workflow-mapping-create/', {
    method: 'POST',
    requiresAuth: true,
    body: {
      name: params.name,
      mapping_schema: params.mapping_schema || {},
      source_task: params.source_task,
      destination_task: params.destination_task,
      workflow: params.workflow_id
    }
  });
};

export const listWorkflowMappings = async (params) => {
  return fetchApi('user_workflow-mapping-list/', {
    method: 'POST',
    requiresAuth: true,
    body: {
      source_task: params.source_task,
      destination_task: params.destination_task,
      workflow_id: params.workflow_id
    }
  });
};

export const updateWorkflowMapping = async (mappingId, params) => {
  return fetchApi(`user_workflow-mapping-update/${mappingId}/`, {
    method: 'POST',
    requiresAuth: true,
    body: {
      name: params.name,
      mapping_schema: params.mapping_schema || {},
      source_task: params.source_task,
      destination_task: params.destination_task,
      workflow: params.workflow_id
    }
  });
};