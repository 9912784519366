import React, { useMemo, useState } from "react";
import { useCalendarDispatch, useCalendarState, useDateAPI } from "@use-date-input/core";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { styled } from '@mui/material/styles';
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { formatNames } from "@use-date-input/common";

const createYearSource = (
    fromDate,
    { addYears, startOfMonth, subtractYears, toFormattedDate }
) => {
    const yearStart = subtractYears(startOfMonth(fromDate), 5);
    return [...Array(20)].map((unused, index) => {
        const year = toFormattedDate(
            addYears(yearStart, index),
            formatNames.HEADER
        );
        return {
            key: `year-${year}`,
            value: year,
            label: year,
        };
    });
};



const DemoHeader = (() => {
    // const classes = useStyles();
    const [selectedValue, setSelectedValue] = useState('2025')
    const { visibleFromDate } = useCalendarState();
    const dateAPI = useDateAPI();
    const { navigateNext, navigatePrevious, setVisibleFromDate } =
        useCalendarDispatch();
    const yearSource = useMemo(
        () => createYearSource(visibleFromDate, dateAPI),
        [dateAPI, visibleFromDate]
    );
    const handlePrevious = () => {
        navigatePrevious();
    };
    const handleNext = () => {
        navigateNext();
    };
    const handleYearChange = (year) => {
        const newVisibleFromDate = dateAPI.set(visibleFromDate, { year });
        setVisibleFromDate(newVisibleFromDate);
        console.log("newVisibleFromDate", newVisibleFromDate)
        setSelectedValue(year)
    };

    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "10px" }} >
            <IconButton
                aria-label="previous month"
                onClick={handlePrevious}
                size="small"
            >
                <ChevronLeft fontSize="inherit" />
            </IconButton>

            <FormControl sx={{ margin: 1, minWidth: 120 }}>
                {/* <InputLabel id="select-label-year">{selectedValue}</InputLabel> */}
                <Select
                    labelId="select-label-year"
                    onChange={(e) => handleYearChange(e.target.value)}
                    input={<Input>{selectedValue}</Input>}
                    value={selectedValue}
                    sx={{
                        border: "none",
                        "&.Mui-focused": {
                            boxShadow: "none"
                        }
                    }}
                >
                    {yearSource.reduce(
                        (accumulator, item) => [
                            ...accumulator,
                            <MenuItem key={item.key} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ],
                        []
                    )}
                </Select>
            </FormControl>
            <IconButton aria-label="next month" onClick={handleNext} size="small">
                <ChevronRight fontSize="inherit" />
            </IconButton>
        </div>
    );
});
export default React.memo(DemoHeader);
