import React from 'react';
import { AlertCircle } from 'lucide-react';
import DatePicker from "react-datepicker";
import { ReactComponent as SystemSettingsIcon } from "../../../../../../../assetts/images/systemSettings.svg";
import CustomDropdown from '../../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown';
import styles from './ValidationParam.module.css';
import "react-datepicker/dist/react-datepicker.css"; // Don't forget to import styles

const ValidationParam = ({ param, value, onChange, error, validation }) => {
  const handleChange = (event) => {
    const inputValue = event.target.value;
    
    // Handle array type
    if (param.type === 'array') {
      const values = inputValue.endsWith(',') 
        ? [...inputValue.split(',').map(v => v.trim())] 
        : inputValue.split(',').map(v => v.trim()).filter(Boolean);
      
      onChange(param.name, values);
      return;
    }
    
    // Handle number type
    if (param.type === 'number' && inputValue !== '') {
      onChange(param.name, Number(inputValue));
      return;
    }
    
    // Handle all other types
    onChange(param.name, inputValue);
  };

  const renderInput = () => {
    if (param.type === 'date') {
      return (
        <div className={styles.datePickerContainer}>
          <DatePicker
            selected={value ? new Date(value) : null}
            onChange={(date) => onChange(param.name, date)}
            className={`${styles.dateInput} ${error ? styles.error : ''}`}
            dateFormat="yyyy-MM-dd"
            placeholderText={param.placeholder || "Select date"}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Today"
            isClearable
            name={`${validation.id}-${param.name}`}
            {...param.validation}
          />
          {param.icon && <SystemSettingsIcon className={styles.dateIcon} />}
        </div>
      );
    }

    if (param.type === 'select') {
      return (
        <CustomDropdown
          items={param.options}
          value={value}
          onChange={val => onChange(param.name, val)}
          placeholder={`Select ${param.label}`}
          style={{ height: "2.375rem" }}
          className={`${styles.input} ${error ? styles.error : ''}`}
          name={`${validation.id}-${param.name}`}
          aria-invalid={!!error}
          {...param.validation}
        />
      );
    }

    const displayValue = param.type === 'array' 
      ? (Array.isArray(value) ? value.join(', ') : '') 
      : (value ?? '');

    return (
      <input
        type={param.type}
        value={displayValue}
        onChange={handleChange}
        placeholder={param.placeholder || (param.type === 'array' ? "Enter values, separated by commas" : "Enter value")}
        className={`${styles.input} ${error ? styles.error : ''}`}
        name={`${validation.id}-${param.name}`}
        aria-invalid={!!error}
        {...param.validation}
      />
    );
  };

  return (
    <div className={styles.fieldWrapper}>
      <label className={styles.label}>
        {param.label}
        {param.validation?.required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.fieldContainer}>
        {renderInput()}
        {error && (
          <div className={styles.errorMessage}>
            <AlertCircle size={14} className={styles.errorIcon} />
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(ValidationParam);