import React, { useState } from "react";
import Box from '@mui/material/Box'
import TextField from "@mui/material/TextField";
import DayFrequency from "./reusableModule/DayFrequency";
import Grid from '@mui/material/Grid'
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TimeFrequency from "./reusableModule/TimeFrequency";
import TimeModule from "./reusableModule/TimeModule";

const MonthlyScheduler = () => {
    const [frequency, setFrequency] = useState('1');

    return (

        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {/* Month(s) Selection */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <Typography style={{ width: "12%" }}>Month(s):</Typography>
                <RadioGroup
                    row
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                >
                    <FormControlLabel value="1" control={<Radio />} label="At" />
                    <FormControlLabel value="2" control={<Radio />} label="Every" />
                </RadioGroup>
            </div>

            {/* Recurrence Input */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <Typography style={{ width: "12%" }}>Recurs:</Typography>
                <TextField
                    type="number"
                    inputProps={{ min: 1, max: 12 }}
                    sx={{
                        width: "38%",
                        height: "48px",
                        "& .MuiInputBase-root": { height: "100%" },
                        "& .MuiInputBase-input": { height: "100%", fontWeight: "400", padding: "10px" },
                    }}
                />
            </div>

            {/* Day Frequency Component */}
            <DayFrequency occurence={"Monthly"} />

            {/* Time Module / Time Frequency */}
            <div >
                {frequency === "1" ? <TimeModule /> : <TimeFrequency />}
            </div>
        </div>

    )
}

export default MonthlyScheduler