import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./EventproHeader.module.css";
import backArrowRounded from '../../../assetts/images/leftRoundedArrow.svg'; // "../../../../assetts/images/leftRoundedArrow.svg";
import searchIcon from "../../../assetts/images/searchIcon.svg";
import { generateBreadcrumbElements } from "../../../utils/breadcrumbUtils";

const Header = ({ showCalendarNew, setShowCalendarNew, showSchedulerNew, setShowSchedulerNew, type, setType }) => {
  const navigate = useNavigate();
  const breadcrumbs = useSelector((state) => state.breadcrumbs.breadcrumbs);

  const handleBackButtonClick = () => {
    switch (type) {
      case "Calendar":
        if (showCalendarNew) {
          setShowCalendarNew(false);
          setType("")
        }
        break;
      case "Schedule":
        if (showSchedulerNew) {
          setShowSchedulerNew(false);
          setType("")
        }
        break;
      default:
        navigate(-1);
        break;

    }

  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.titleSection}>
        <h1 className={styles.title}>Event Pro</h1>
        <div className={styles.breadcrumb}>
          {generateBreadcrumbElements(breadcrumbs, navigate)}
        </div>
      </div>
      <div className={styles.searchSection}>
        <button className={styles.backButton} onClick={handleBackButtonClick}>
          <img src={backArrowRounded} alt="Back" className={styles.backIcon} />
        </button>
        {!(showSchedulerNew || showCalendarNew) && <div className={styles.searchWrapper}>
          <img src={searchIcon} alt="Search" className={styles.searchIcon} />
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search..."
          />
        </div>}
      </div>
    </div>
  );
};

export default Header;
