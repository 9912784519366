import React, { useState, useEffect } from "react";
import styles from "./RunDetails.module.css";
import EmptyState from "../../../../components/reusableComponents/EmptyState/EmptyState";
import { AlertCircle } from 'lucide-react';

const RunDetailsComponent = ({ runDetails,statusOptions }) => {
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (runDetails) {
      setActiveTab(Object.keys(runDetails)[0]);
    }
  }, [runDetails]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderTable = (data) => {
    const headers = Object.keys(data).filter((key) => key !== "execution_log");
    return (
      <table className={styles.detailsTable}>
        <thead className={styles.thead}>
          <tr className={styles.tr}>
            {headers.map((header) => (
              <th key={header} className={styles.th}>
                {header
                  .replace(/_/g, " ")
                  .toLowerCase()
                  .replace(/^\w/, (c) => c.toUpperCase())}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className={styles.tr}>
            {headers.map((header) => (
               <td key={header} className={styles.td}>
               {header === 'status' && statusOptions ? 
                 statusOptions.find(status => status.value === data[header])?.label || "N/A" :
                 data[header] || "N/A"}
             </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  const renderExecutionLog = (logArray) => {
    return (
      <div className={styles.automationResultContainer}>
        <div className={styles.automationResultHeader}>Automation Result</div>
        <div className={styles.automationResultBody}>
          {logArray && logArray.length > 0 ? (
            logArray.map((logEntry, index) => (
              <span key={index} className={styles.logEntry}>
                {logEntry}
              </span>
            ))
          ) : (
            <span className={styles.logEntry}>No execution log available.</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.runDetailsContainer}>
      <h3 className={styles.header}>Run Details</h3>
      {!runDetails ? (
        <EmptyState
          icon={AlertCircle}
          title="No Run Details Available"
          message="Select a node to view its execution details"
          iconSize={32}
        />
      ) : (
        <>
          <div className={styles.tabs}>
            {Object.keys(runDetails).map((tab) => (
              <button
                key={tab}
                className={`${styles.tabButton} ${activeTab === tab ? styles.activeTab : ""}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab
                  .replace("_", " ")
                  .toLowerCase()
                  .replace(/^\w/, (c) => c.toUpperCase())}
              </button>
            ))}
          </div>

          <div className={styles.runDetailsContent}>
            {activeTab === "info" && runDetails?.info && (
              <div className={styles.infoContent}>
                {renderTable(runDetails.info)}
                {runDetails.info.execution_log &&
                  renderExecutionLog(runDetails.info.execution_log)}
              </div>
            )}

            {activeTab === "attachments" && runDetails?.attachments && (
              <div className={styles.infoContent}>
                {renderTable(runDetails.attachments)}
              </div>
            )}

            {activeTab === "note" && runDetails?.note && (
              <div className={styles.infoContent}>
                {renderTable(runDetails.note)}
              </div>
            )}

            {!runDetails[activeTab] && (
              <EmptyState
                icon={AlertCircle}
                title="No Data Available"
                message={`No ${activeTab} data available for this node`}
                iconSize={24}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RunDetailsComponent;