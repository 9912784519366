
// field validation

export const validationRules = {
    name: { required: true, maxLength: 100 },
    start_date: { required: true },
    end_date: {
        required: true,
        customValidation: (value, data) =>
            new Date(data.start_date) > new Date(value)
                ? "End date must be after start date"
                : null
    },
    description: { required: true },
};


export const validateForm = (data, rules) => {
    const errors = {};

    Object.keys(rules).forEach((field) => {
        const value = data[field];
        const fieldRules = rules[field];
        const fieldName = field.replace(/_/g, " ").replace(/^./, (char) => char.toUpperCase());

        if (fieldRules.required && !value) {
            errors[field] = `${fieldName} is required`;
        }

        if (fieldRules.minLength && value?.length < fieldRules.minLength) {
            errors[field] = `${fieldName} must be at least ${fieldRules.minLength} characters`;
        }

        if (fieldRules.maxLength && value?.length > fieldRules.maxLength) {
            errors[field] = `${fieldName} must be less than ${fieldRules.maxLength} characters`;
        }

        if (fieldRules.pattern && !fieldRules.pattern.test(value)) {
            errors[field] = `Invalid ${fieldName}`;
        }

        if (fieldRules.customValidation) {
            const customError = fieldRules.customValidation(value, data);
            if (customError) errors[field] = customError;
        }
    });

    return errors;
};

