import { fetchCalendarMasterDataApi, fetchHolidayApi, getCalendarApi } from "../../../api/calendarApi";

export const getCountryMasterData = async (accessToken, setCountries, setWeekends) => {
    try {

        const data = await fetchCalendarMasterDataApi(accessToken);
        if (data.countries != undefined) {
            setCountries(data.countries);
            setWeekends(data.weekends)
        }

    } catch (error) {
        console.error('Failed to fetch templates:', error);
    }
};

export const getExistingData = async (accessToken, calendarId, setLoader, setCalendarData, setHolidays) => {
    try {

        const data = await getCalendarApi(accessToken, calendarId);
        if (data !== undefined) {
            setLoader(false)
            setCalendarData(data)
            setHolidays(data.holidays.map(item => ({ ...item, date: new Date(item.date) })));
        }

    } catch (error) {
        setLoader(false)
        console.error('Failed to fetch templates:', error);
    }
}

export const getHolidays = async (accessToken, country, setHolidays) => {
    try {

        const data = await fetchHolidayApi(accessToken, country);
        if (data !== undefined) {
            setHolidays(data.data.map(item => ({ ...item, description: item.info, date: new Date(item.day) })))
        }

    } catch (error) {
        console.error('Failed to fetch templates:', error);
    }
}