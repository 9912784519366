import React from "react";
import { Minus } from "lucide-react";
import styles from "./ConfigurationField.module.css";

const ConfigurationField = ({
  fieldKey,
  value,
  path,
  handleChange,
  handleRemoveField,
  multiple,
}) => {
  const inputType = value.type === "number" ? "number" : "text";

  const snakeCaseToCapitalized = (str) =>
    str
      .split("_")
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
      .join(" ");

  return (
    <div className={styles.inputWrapper}>
      <div className={styles.fieldContainer}>
        <label>{snakeCaseToCapitalized(fieldKey)}:</label>
        <input
          type={inputType}
          value={value.value}
          onChange={(e) => handleChange(path, e.target.value)}
          className={styles.inputField}
        />
      </div>
      {!value.dynamic && (
        <button
          className={styles.removeButton}
          onClick={() => handleRemoveField(path)}
          type="button"
          aria-label="Remove field"
        >
          <Minus size={18} />
        </button>
      )}
    </div>
  );
};

export default ConfigurationField;