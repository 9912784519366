import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { TextField } from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CustomTooltip from "../../../reusableComponents/CustomTooltip/CustomTooltip";

const TimeModule = () => {
    const [scheduleData, setScheduleData] = useState([{
        time: dayjs(),
    }]);

    const addRow = () => {
        setScheduleData([
            ...scheduleData,
            {
                time: dayjs()
            },
        ]);

    };

    const removeRow = (e, index) => {
        if (scheduleData.length === 1 && index === 0) {
            const list = [...scheduleData];
            list[index].time = dayjs();
            setScheduleData(list);
        } else {
            const list = [...scheduleData];
            list.splice(index, 1);
            setScheduleData(list);
        }

    };

    const handleTime = (e, index) => {
        const list = [...scheduleData];
        list[index].time = e;
        setScheduleData(list);
    };

    return (

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {scheduleData.map((singleService, index) => (
                <div
                    style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        rowGap: "16px",
                        marginBottom: "16px",
                        alignItems: "center"
                    }}
                    key={index}
                >
                    {/* Label */}
                    <div style={{ display: "flex", alignItems: "center", width: "12%" }}>
                        <Typography>Time:</Typography>
                    </div>

                    {/* Time Picker */}
                    <div style={{ display: "flex", width: "38%" }}>
                        <TimePicker
                            value={scheduleData[index].time}
                            onChange={(newTime) => handleTime(newTime, index)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            sx={{
                                height: "48px",
                                width: "100%",


                                "& .MuiInputBase-root": {
                                    height: "100%",
                                    minHeight: "45px",
                                    display: "flex",
                                    alignItems: "center", padding: "16px",
                                },
                                "& .MuiInputBase-input": {
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "400",
                                }
                            }}
                        />
                    </div>

                    {/* Action Buttons */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            minWidth: "120px"
                        }}
                    >
                        <CustomTooltip title="Remove">
                            <IconButton
                                onClick={(e) => removeRow(e, index)}
                                sx={{
                                    backgroundColor: "#F2F2F2",
                                    color: "#4F63BE",
                                    "&:hover": { backgroundColor: "#D9D9D9" },
                                }}
                                aria-label="delete"
                            >
                                <DeleteOutlineOutlinedIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                        </CustomTooltip>

                        <CustomTooltip title="Add">
                            <IconButton
                                onClick={(e) => addRow(e, index)}
                                sx={{
                                    backgroundColor: "#F2F2F2",
                                    color: "#4F63BE",
                                    "&:hover": { backgroundColor: "#D9D9D9" },
                                    visibility: scheduleData.length - 1 === index ? "visible" : "hidden"
                                }}
                                aria-label="add"
                            >
                                <AddIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                        </CustomTooltip>
                    </div>
                </div>

            ))}
        </LocalizationProvider>

    )

}

export default TimeModule;