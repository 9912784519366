import React, { useState, useEffect, useMemo, useCallback } from "react";
import MappingAndTransformationSection from "../MappingAndTransformationSection/MappingAndTransformationSection";
import AppearanceSettings from "./AppearanceSettings";
import ConfigurationSection from "./ConfigurationSection";
import styles from "./NodePropertiesModal.module.css";

const NodePropertiesModal = React.memo(({ node, onUpdate, onClose, nodes, edges }) => {
  const [nodeData, setNodeData] = useState({});
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    if (node?.data) {
      setNodeData(node.data);
      const allSections = Object.keys(node.data.dynamic || {});
      setActiveSection(allSections.includes("properties") ? "properties" : allSections[0]);
    }
  }, [node]);

  const handleSave = useCallback(() => {
    onUpdate({
      ...node,
      data: {
        ...node.data,
        ...nodeData,
        dynamic: {
          ...node.data.dynamic,
          ...nodeData.dynamic
        }
      }
    });
    onClose();
  }, [node, nodeData, onUpdate, onClose]);

  const updateNodeData = useCallback((newData) => {
    const { configurations, properties, ...otherData } = newData;
    setNodeData(prev => ({
      ...prev,
      ...otherData,
      dynamic: {
        ...prev.dynamic,
        ...(configurations && { configurations }),
        ...(properties && { properties })
      }
    }));
  }, []);

  const sectionTitles = {
    'properties': 'Properties',
    'configurations': 'Configurations',
    'mapping-and-transformations': 'Mappings'
  };

  const getSectionTitle = useCallback((key) =>
    sectionTitles[key] || key.charAt(0).toUpperCase() + key.slice(1)
    , []);

  const memoizedSectionContent = useMemo(() => {
    const sections = {
      "properties": () => (
        <AppearanceSettings
          label={nodeData?.dynamic?.properties?.label || ""}
          background={nodeData?.dynamic?.properties?.styles?.background || "#ffffff"}
          borderRadius={nodeData?.dynamic?.properties?.styles?.borderRadius || 0}
          icon={nodeData?.dynamic?.properties?.icon || ""}
          setLabel={(label) => updateNodeData({
            properties: { ...nodeData?.dynamic?.properties, label }
          })}
          setBackground={(background) => updateNodeData({
            properties: {
              ...nodeData?.dynamic?.properties,
              styles: { ...nodeData?.dynamic?.properties?.styles, background }
            }
          })}
          setBorderRadius={(borderRadius) => updateNodeData({
            properties: {
              ...nodeData?.dynamic?.properties,
              styles: { ...nodeData?.dynamic?.properties?.styles, borderRadius }
            }
          })}
          setIcon={(icon) => updateNodeData({
            properties: { ...nodeData?.dynamic?.properties, icon }
          })}
        />
      ),
      "configurations": () => (
        <ConfigurationSection
          configuration={nodeData.dynamic?.configurations}
          onSave={(configurations) => updateNodeData({ configurations })}
        />
      ),
      "mapping-and-transformations": () => (
        <MappingAndTransformationSection
          node={node}
          nodes={nodes}
          edges={edges}
          onSave={(mappings) => updateNodeData({ mappings })}
        />
      )
    };

    return sections[activeSection]?.() || (
      <div className={styles.emptyState}>Select a section to configure</div>
    );
  }, [activeSection, nodeData, node, nodes, edges, updateNodeData]);

  return (
    <div className={styles.modalOverlay} onClick={(e) => e.stopPropagation()}>
      <div className={styles.modalContent}>
        <nav className={styles.sectionToggle}>
          {Object.keys(nodeData?.dynamic || {}).map((sectionKey) => (
            <button
              key={sectionKey}
              className={`${styles.toggleButton} ${activeSection === sectionKey ? styles.active : ""}`}
              onClick={() => setActiveSection(sectionKey)}
            >
              {getSectionTitle(sectionKey)}
            </button>
          ))}
          <button
            className={`${styles.toggleButton} ${activeSection === "mapping-and-transformations" ? styles.active : ""}`}
            onClick={() => setActiveSection("mapping-and-transformations")}
          >
            Mappings
          </button>
        </nav>

        <div className={styles.contentContainer}>
          <div className={styles.sectionContent}>
            {memoizedSectionContent}
          </div>

          <footer className={styles.actionButtons}>
            <button
              type="button"
              className={styles.cancelButton}
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className={styles.saveButton}
              onClick={handleSave}
            >
              Save
              {/* Apply Changes */}
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
});

NodePropertiesModal.displayName = 'NodePropertiesModal';

export default NodePropertiesModal;