import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import 'chart.js/auto';
import { Bar, Doughnut } from 'react-chartjs-2'
import styles from './GraphicalReports.module.css'


const GraphicslReports = ({ data }) => {
    return (
        <div className={styles.maincontainer}>
            {data.map((graph, index) => (

                <Card className={styles.Graphcard}>
                    <CardHeader
                        subheader={graph.title}
                        className={styles.Graphcard_header}
                    />
                    {graph.type === "bar" ? (
                        <CardContent className={styles.Graphcard_content_bar}>
                            <Box sx={{ height: "85%" }}>
                                <Bar
                                    data={graph.data}
                                    options={{
                                        plugins: {
                                            legend: {
                                                position: 'bottom'
                                            }
                                        },
                                        responsive: true,
                                        maintainAspectRatio: false, // Important for resizing
                                        hover: {
                                            mode: null // Disable hover effect
                                        }
                                    }}
                                />
                            </Box>
                        </CardContent>
                    ) : graph.type === "doughnut" ? (
                        <CardContent className={styles.Graphcard_content_doughnut}>
                            <Box sx={{ height: "85%" }}>
                                <Doughnut
                                    data={graph.data}
                                    options={{
                                        aspectRatio: 1,
                                        cutout: graph.style.cutout,
                                        plugins: {
                                            legend: {
                                                position: 'bottom'
                                            },

                                        },
                                        hover: {
                                            mode: null // Disable hover effect
                                        }
                                    }}
                                />
                            </Box>
                        </CardContent>
                    ) : (
                        <CardContent className={styles.Graphcard_content_invalid}>
                            <Box sx={{ display: "flex", alignItems: "center" }}> <p style={{ color: "black" }}>Invalid Chart Type</p>  </Box>
                        </CardContent>
                    )}
                    {/* <Bar
                        data={graph.data}
                        options={{
                            plugins: {
                                legend: {
                                    position: 'bottom'
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false // Important for resizing
                        }}
                    /> */}

                </Card>

            ))}
        </div>
    );
};

export default GraphicslReports;
