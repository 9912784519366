import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import DetailViewHeader from "../../components/DetailViewHeader/DetailViewHeader";
import { setMode } from "../../../../redux/actions/actions";
import DetailViewSidebar from "../../components/DetailViewSidebar/DetailViewSidebar";
import AutomationDetailsPage from "../../components/AutomationDetails/AutomationDetails";
import styles from "./DetailViewPage.module.css";

const DetailViewPage = () => {
  const dispatch = useDispatch();
  const [selectedLog, setSelectedLog] = useState(null);
  const [title, setTitle] = useState('');
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    dispatch(setMode("view"));
  }, [dispatch]);

  const handleLogSelect = useCallback((log) => {
    setSelectedLog(log);
  }, []); 

  return (
    <div className={styles.DetailViewPage}>
      <div className={styles.headerAndContent}>
        <DetailViewHeader title={title} />
        <div className={styles.mainContent}>
          <DetailViewSidebar onLogSelect={handleLogSelect} setStatusOptions={setStatusOptions} statusOptions={statusOptions}/>
          <div className={styles.contentContainer}>
            <AutomationDetailsPage selectedLog={selectedLog} setTitle={setTitle} statusOptions={statusOptions}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailViewPage;
