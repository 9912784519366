import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchNotificationsApi,
    markNotificationsAsReadApi,
    markNotificationsAsUnreadApi,
    deleteNotificationsApi
} from '../api/notificationsApi';
import { updateNotifications } from '../redux/actions/actions';

export const useNotifications = (accessToken) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [localNotifications, setLocalNotifications] = useState([]);

    const reduxNotifications = useSelector(state => state?.notifications?.notifications ?? []);
    const notifications = reduxNotifications.length ? reduxNotifications : localNotifications;

    const transformNotification = (item) => ({
        id: item.notification.id,
        title: item.notification.title,
        description: item.notification.content,
        isRead: item.read,
        readAt: item.read_at,
        notificationType: item?.notification?.notification_type,
        timestamp: new Date(item.notification.created_at).toLocaleString(),
        createdDate: new Date(item.notification.created_at).toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", second: "numeric", hour12: true }).replace(/,([^,]*)$/, " |$1"),
        actionUrl: item.notification.action_url,
        requiresAction: item.notification.requires_action
    });

    const fetchNotifications = useCallback(async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await fetchNotificationsApi();
            const transformedNotifications = response.results.map(transformNotification);
            dispatch(updateNotifications(transformedNotifications));
            setLocalNotifications(transformedNotifications);
        } catch (error) {
            const errorMessage = error?.errors?.length ?
                error.errors.map(err => err.detail).join('. ') :
                'Failed to fetch notifications';
            setError(errorMessage);
            console.error('Error fetching notifications:', error);
        } finally {
            setLoading(false);
        }
    }, [accessToken, dispatch]);

    const markAsRead = useCallback(async (notificationIds) => {
        try {
            setError(null);
            const response = await markNotificationsAsReadApi(notificationIds);

            if (response.updated_notifications) {
                const updatedNotificationsMap = response.updated_notifications.reduce((acc, item) => {
                    acc[item.notification.id] = transformNotification(item);
                    return acc;
                }, {});

                const updatedNotifications = notifications.map(notification =>
                    updatedNotificationsMap[notification.id] || notification
                );

                dispatch(updateNotifications(updatedNotifications));
                setLocalNotifications(updatedNotifications);
            }
        } catch (error) {
            const errorMessage = error?.errors?.length ?
                error.errors.map(err => err.detail).join('. ') :
                'Failed to mark notifications as read';
            setError(errorMessage);
            console.error('Error marking notifications as read:', error);
        }
    }, [accessToken, notifications, dispatch]);

    const markAsUnread = useCallback(async (notificationIds) => {
        try {
            setError(null);
            const response = await markNotificationsAsUnreadApi(notificationIds);

            if (response.updated_notifications) {
                const updatedNotificationsMap = response.updated_notifications.reduce((acc, item) => {
                    acc[item.notification.id] = transformNotification(item);
                    return acc;
                }, {});

                const updatedNotifications = notifications.map(notification =>
                    updatedNotificationsMap[notification.id] || notification
                );

                dispatch(updateNotifications(updatedNotifications));
                setLocalNotifications(updatedNotifications);
            }
        } catch (error) {
            const errorMessage = error?.errors?.length ?
                error.errors.map(err => err.detail).join('. ') :
                'Failed to mark notifications as unread';
            setError(errorMessage);
            console.error('Error marking notifications as unread:', error);
        }
    }, [accessToken, notifications, dispatch]);

    const deleteNotifications = useCallback(async (notificationIds) => {
        try {
            setError(null);
            await deleteNotificationsApi(notificationIds);

            const updatedNotifications = notifications.filter(
                notification => !notificationIds.includes(notification.id)
            );
            dispatch(updateNotifications(updatedNotifications));
            setLocalNotifications(updatedNotifications);
        } catch (error) {
            const errorMessage = error?.errors?.length ?
                error.errors.map(err => err.detail).join('. ') :
                'Failed to delete notifications';
            setError(errorMessage);
            console.error('Error deleting notifications:', error);
        }
    }, [accessToken, notifications, dispatch]);

    useEffect(() => {
        fetchNotifications();
    }, [fetchNotifications]);

    return {
        notifications,
        loading,
        error,
        markAsRead,
        markAsUnread,
        deleteNotifications,
        refreshNotifications: fetchNotifications
    };
};