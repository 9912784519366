import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UsersForm from '../../components/user-management/UsersForm';
import UsersList from '../../components/user-management/UsersList';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import { isTokenValid } from '../../utils/apiUtils';
import { useDispatch, useSelector } from 'react-redux';
import { refreshAccessToken } from '../../utils/tokenUtils';
import { reAuthenticationRequest } from '../../redux/actions/authActions';
import { fetchCustomersApi, fetchCustomersApiDropdown, fetchPartnersApi, fetchUserDetailApi, fetchUserRolesApi, fetchUsersApi, fetchUserTypesApi, hanldeUserDeactivate } from '../../api/usermanagmentApi';
import Preloader from '../../components/common/Preloader';
import UserFilter from '../../components/user-management/UserFilter';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Paper from '@mui/material/Paper';
import CustomAlert from '../../components/reusableComponents/CustomAlert/CustomAlert';

const Users = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [modalType, setModalType] = useState('')
  const [usersData, setUsersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [userRoles, setUserRoles] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [partners, setPartners] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [userId, setUserId] = useState();
  const [filterQuery, setFilterQuery] = useState({
    name: '',
    customer: '',
    partner: '',
    usertype: ''
  })
  const [isVisible, setIsVisible] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState('partner/list/?page=1');
  const [nextPageCustomers, setNextPageCustomers] = useState('partner/customer-list/?page=1');
  const [alert, setAlert] = useState({ open: false, message: "", type: "info" });
  const toggleFilter = () => {
    setIsVisible((prev) => !prev); // Toggle state
  };
  let accessToken = useSelector((state) => state.auth.accessToken);
  const userType = useSelector((state) => state.auth.userDetails?.user_type);
  const userConfig = useSelector((state) => state.auth.userDetails?.permissionConfig?.user);
  const [selectedUserDetail, setSelectedUserDetail] = useState([]);
  const handleOpen = () => { setIsModalOpen(true); setModalType('createUser') }
  const handleClose = () => { setIsModalOpen(false); setSelectedUserDetail([]) }
  useEffect(() => {
    if (filterQuery.partner !== "" || userType === 2 || userType === 3) {
      getCustomer(); // Call the function
    }
  }, [filterQuery.partner])
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getPartner();
    }
    return () => {
      isMounted = false; // Cleanup function
    };
  }, []);
  const getCustomer = async () => {
    try {
      const data = await fetchCustomersApiDropdown(accessToken, filterQuery.partner, nextPageCustomers || 'partner/customer-list/?page=1'); // Call the API to fetch templates
      if (data != undefined) {
        setCustomers((prev) => [...prev, ...data.results]); // Set the 'results' array from the response
        setNextPageCustomers(data.next ? data.next.split("/api/")[1] : null); // Update next page URL
      } else {
        setNextPageCustomers(null);
      }

    } catch (error) {

      setError(true); // Set error state if the API call fails

    }
  };
  const getPartner = async () => {
    if (!nextPageUrl) return;
    try {
      const data = await fetchPartnersApi(accessToken, nextPageUrl); // Call the API to fetch templates

      if (data && data.results.length > 0) {
        setPartners((prev) => [...prev, ...data.results]);
        setNextPageUrl(data.next ? data.next.split("/api/")[1] : null); // Update next page URL
      } else {
        setNextPageUrl(null);
      }

    } catch (error) {


    }
  };
  useEffect(() => {
    const getUserRoles = async () => {
      try {
        const data = await fetchUserRolesApi(accessToken); // Call the API to fetch templates

        if (data != undefined) {
          setUserRoles(data.results); // Set the 'results' array from the response
        }
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Failed to fetch templates:', error);
        //setError(true); // Set error state if the API call fails
        setLoading(false); // Set loading to false even on error
      }
    };

    getUserRoles(); // Call the function


  }, [])
  useEffect(() => {
    const getUserTypes = async () => {
      try {
        const data = await fetchUserTypesApi(accessToken); // Call the API to fetch templates

        if (data != undefined) {
          setUserTypes(data); // Set the 'results' array from the response
        }
        setLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error('Failed to fetch templates:', error);
        //setError(true); // Set error state if the API call fails
        setLoading(false); // Set loading to false even on error
      }
    };
    getUserTypes(); // Call the function   
  }, [])
  const getUsersData = async () => {
    try {
      setLoading(true)
      const data = await fetchUsersApi(accessToken, currentPage, filterQuery); // Call the API to fetch templates
      if (data.results != undefined) {
        setUsersData(data.results); // Set the 'results' array from the response
        setTotalPages(Math.ceil(data.count / 10))
      }
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error('Failed to fetch templates:', error);
      //setError(true); // Set error state if the API call fails
      setLoading(false); // Set loading to false even on error
    }
  };
  useEffect(() => {
    getUsersData(); // Fetch templates when the component mounts
  }, [currentPage])

  const getUserDetail = async (id) => {

    try {
      const data = await fetchUserDetailApi(accessToken, id); // Call the API to fetch templates
      if (data != undefined) {
        setSelectedUserDetail(data); // Set the 'results' array from the response
      }
      setIsModalLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      const errorMessage = error?.errors?.length ?
        error.errors.map(err => err.detail).join('. ') :
        "Error fetching workflow details. Please try again later.";
      setAlert((prev) => ({ ...prev, open: true, message: errorMessage, type: "failure" }));
      console.error("Error fetching workflow details:", errorMessage);
      //setError(true); // Set error state if the API call fails
      setIsModalLoading(false); // Set loading to false even on error
    }
  };
  const deactivateUser = async (id, activeStatus) => {
    try {
      const res = await hanldeUserDeactivate(accessToken, id, activeStatus)
      setAlert((prev) => ({ ...prev, open: true, message: res.message, type: "success" }));
      setTimeout(() => {
        getUsersData()
      }, 2000); 
    } catch (error) {
      const errorMessage = error?.errors?.length ?
        error.errors.map(err => err.detail).join('. ') :
        'Action failed';
      setAlert((prev) => ({ ...prev, open: true, message: errorMessage, type: "failure" }));
    }

  }
  const handleUserActions = (type, id, activeStatus) => {
    switch (type) {
      case 'editUser':
        setIsModalLoading(true)
        setModalType("editUser")
        setIsModalOpen(true)

        if (id) { // Check if partnerId is not null
          getUserDetail(id); // Call the function
        }
        break;
      case 'deactivate':
        // setModalType("deactivate")
        deactivateUser(id, activeStatus)
        break;
    }

  };

  const handleFilter = () => {
    setCurrentPage(1); // Reset to first page
    getUsersData(); // Fetch new filtered data
  };

  return (
    <Box sx={{ p: 5 }}>
      <CustomAlert
        open={alert.open}
        message={alert.message}
        type={alert.type}
        onClose={() => setAlert({ ...alert, open: false })}
      />
      <Box className="buttonGroup" display="flex" marginTop={5}>
        {
          loading ? (
            <Preloader />
          ) : (
            <>
              <Grid2 sx={{ pb: 2, width: "100%" }}>
                <Box

                >
                  <Grid2 item xs={12} sx={{ padding: 2, backgroundColor: 'transparent', borderBottom: 'none', display: 'flex', justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                    <Paper elevation={1} sx={{
                      backgroundColor: 'transparent',
                      border: 'none', // Ensures no border
                      boxShadow: 'none', // Removes shadow
                    }}>
                      <Typography variant="h5" gutterBottom sx={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: 600,
                        color: '#000000', // Explicitly setting the font color to black
                      }}>
                        Users List
                      </Typography>

                    </Paper>
                    <Box>
                      {
                        userConfig.create && (
                          <Button
                            variant="contained"
                            sx={{
                              alignItems: 'left',
                              width: '160px',
                              height: '32px',
                              borderRadius: '24px',
                              backgroundColor: '#4F63BE',
                              boxShadow: 'none',
                              textTransform: 'none',
                              '& .MuiButton-startIcon': {
                                borderRadius: '50%',
                                padding: '0px',
                              },
                            }}
                            startIcon={<AddCircleOutlinedIcon sx={{ borderRadius: '50%' }} />}
                            onClick={handleOpen} // Open drawer on click
                          >
                            Create New
                          </Button>
                        )
                      }

                      <Button
                        variant="contained"
                        sx={{
                          width: '100px',
                          height: '32px',
                          borderRadius: '24px',
                          backgroundColor: '#7398C7',
                          boxShadow: 'none',
                          textTransform: 'none',
                          marginLeft: '8px', // Space between buttons
                          '&:hover': {
                            backgroundColor: '#7398C7',
                          },
                          '& .MuiButton-startIcon': {
                            borderRadius: '50%',
                            padding: '0px',
                          },
                        }}
                        startIcon={<FilterAltIcon sx={{ borderRadius: '50%', fontSize: 20, marginLeft: '5px' }} />}
                        onClick={toggleFilter}
                      >
                        Filter
                      </Button>

                    </Box>


                  </Grid2>
                  {isVisible && (
                    <UserFilter
                      userTypesData={userTypes}
                      partnerData={partners}
                      filterQuery={filterQuery}
                      setFilterQuery={setFilterQuery}
                      customers={customers}
                      setCustomers={setCustomers}
                      onApply={handleFilter}
                      getPartner={getPartner}
                      getCustomer={getCustomer}
                      nextPageCustomers={nextPageCustomers}
                      nextPageUrl={nextPageUrl}
                    />
                  )}


                  <UsersList
                    userDatas={usersData}
                    handleUserActions={handleUserActions}
                    setCurrentPage={setCurrentPage}

                    totalPages={totalPages}
                    currentPage={currentPage}
                    // onSelectUser={handleSelectUser} 
                    setLoading={setLoading}

                  />
                </Box>
              </Grid2>

            </>

          )
        }

        {/* Modal for UsersForm */}
        <Modal open={isModalOpen} onClose={handleClose}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            height: '90vh',
            width: '60vw',
            overflowY: 'auto',
            borderRadius: 2,
          }}
          >
            <Typography component="h2" sx={(theme) => ({
              ...theme.typography.heading, // Apply styles from theme.typography.heading
            })}>
              {modalType === "editUser" && " User Information"}
              {modalType === "createUser" && " Create User"}
            </Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                top: 8,
                right: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* Content of the modal */}
            {
              isModalLoading ? (
                <Preloader />
              )
                : (
                  <>
                    {userRoles !== null ? (
                      <UsersForm
                        userData={selectedUserDetail}
                        userRolesData={userRoles}
                        userTypesData={userTypes}
                        partnerData={partners}
                        customerData={customers}
                        getPartner={getPartner}
                        nextPageUrl={nextPageUrl}
                        getCustomer={getCustomer}
                        nextPageCustomers={nextPageCustomers}
                        setAlert={setAlert}
                        setIsModalOpen={setIsModalOpen}
                        getUsersData={getUsersData}
                      />
                    ) : (<></>)}
                  </>

                )
            }


          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Users;
