import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import styles from "./Tile.module.css";
import infoPink from "../../../../assetts/images/infoPink.svg";
import playButton from "../../../../assetts/images/playButton.svg";
import DialogBox from "../../../../components/reusableComponents/DialogBox/DialogBox"; // Import the DialogBox component

const Tile = ({ id, icon, title, category, info }) => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false); // State to manage dialog visibility

  const handleNavigate = (url, state = {}) => {
    if (id) {
      navigate(url, { state });
    } else {
      console.warn("No ID provided for navigation.");
    }
  };

  const handleInfoClick = (e) => {
    e.stopPropagation(); // Prevent triggering onClick for the tile itself
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false); // Function to close the dialog
  };

  return (
    <div className={styles.tile} role="button" onClick={() =>
      handleNavigate(`/workflow-builder/basic-integration/${id}`)
    }>
      <div className={styles.tileContent}>
        <div className={styles.iconContainer}>
          <img src={icon} alt="Icon" className={styles.tileIcon} />
        </div>
        <div className={styles.title} title={title}>
          {title}
        </div>
      </div>
      <div className={styles.footer}>
        <Tooltip title={category} arrow>
          <span className={styles.category}>{category}</span>
        </Tooltip>
        <div className={styles.actions}>
          <div className={styles.unit}>
            <p>30</p>
            <span>iu</span>
          </div>
          <div className={styles.infoWrapper} onClick={(e) => e.stopPropagation()}>
            <img
              src={infoPink}
              alt="Info"
              className={styles.infoIcon}
              onClick={handleInfoClick}
            />
            {showDialog && (
              <div className={styles.dialogContainer}>
                <DialogBox
                  content={info || "No descriptions found"}
                  onClose={handleClose} 
                  customStyles={{
                    backgroundColor: "#f8e1f4",
                    border: "2px solid #f8e1f4",
                    position: "absolute",
                    top: '18px',
                    left: '-207px',
                    zIndex: 1000,
                    width: '20rem'
                  }}
                />
              </div>
            )}
          </div>
          <img
            src={playButton}
            alt="Settings"
            className={styles.actionIcon}
            onClick={() =>
              handleNavigate(`/workflow-builder/basic-integration/${id}`)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Tile;
