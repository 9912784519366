export const handleInfiniteScroll = (event, nextPageUrl, fetchMoreData) => {
    const element = event.target;
  
    if (
      nextPageUrl && // Ensure there's more data to load
      Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 1
    ) {
      fetchMoreData(); // Fetch next set of data
    }
  };
  