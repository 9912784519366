import { fetchTimeZoneApi, fetchOccurenceApi } from "../../../api/schedulesApi";

export const getTimeZones = async (accessToken, setTimeZones) => {
    try {

        const data = await fetchTimeZoneApi(accessToken);
        if (data != undefined) {
            setTimeZones(data);
        }

    } catch (error) {
        console.error('Failed to fetch templates:', error);
    }
};

export const getOccurence = async (accessToken, setOccurence) => {
    try {

        const data = await fetchOccurenceApi(accessToken);
        if (data != undefined) {
            setOccurence(data);
        }

    } catch (error) {
        console.error('Failed to fetch templates:', error);
    }
};

