// src/components/SearchBox.js
import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBox = ({ searchTerm, setSearchTerm }) => {
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);

  // Debounce logic
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(debouncedTerm);
    }, 600);

    return () => clearTimeout(handler);
  }, [debouncedTerm, setSearchTerm]);

  return (
    <TextField
      variant="outlined"
      size="small"
      placeholder="Search..."
      value={debouncedTerm}
      onChange={(e) => setDebouncedTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{
        color: '#000000',
        width: '180px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '16px',
        },
        '& input::placeholder': {
          color: '#A7A7A7',
          opacity: 1,
        },
        '& input': {
          color: '#000000',
        },
      }}
    />
  );
};

const SearchComponent = ({ searchTerm, setSearchTerm }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 3,
        mb: 3,
      }}
    >
      <SearchBox searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
    </Box>
  );
};

export default SearchComponent;
