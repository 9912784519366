// // api/authApi.js
import { isTokenValid, refreshAccessToken } from '../utils/tokenUtils';

import { setAccessToken, logoutUser, clearTokens } from '../redux/actions/authActions';
import { fetchApi } from '../utils/apiUtils'

const BASE_URL = 'https://staging.runmybot.com/api';

export const loginApi = async (credentials) => {

  console.log("LOGIN API : ",credentials)
    const response = await fetchApi('login/', {method:'POST',body:credentials});
    return await response;
};
export const fetchUserTypeListApi = async() =>{
  const response = await fetchApi('user-type/list/', {method:'GET', requiresAuth: true} );
  return await response;
}
export const refreshTokenApi = async ({ refreshToken }) => {
    const response = await fetch('https://staging.runmybot.com/api/token/refresh/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
        throw new Error('Failed to refresh token');
    }

    return await response.json();
};
export const logoutRequestAPI = async(refreshToken, accessToken) =>{
  const response = await fetchApi('logout/', {method:'POST', requiresAuth: true, body:{refresh:refreshToken}});
  return response;
}

export const fetchWithToken = async (url, options = {}) => {
    let accessToken = localStorage.getItem('accessToken');
    
    // Check if the access token is valid
    if (!isTokenValid(accessToken)) {
      try {
        // Refresh the token if expired
        console.log("refreshAccessToken calling here.... fetchWithToken")
        accessToken = await refreshAccessToken();
        
        // Dispatch the new access token to Redux
       // store.dispatch(setAccessToken(accessToken));
        
      } catch (error) {
        // If refresh token fails, logout the user and clear localStorage
        //store.dispatch(logoutUser());
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        
        // Optionally redirect to the login page
        window.location.href = '/login';
        return Promise.reject(error);
      }
    }
  
    // Include the access token in the Authorization header
    const headers = {
      ...options.headers,
      'Authorization': `Bearer ${accessToken}`,
    };
  
    // Make the fetch request
    const response = await fetch(url, {
      ...options,
      headers,
    });
  
    return response;
  };
export const sendPasswordLinkRequest = async (email) => {
    const response = await fetch('https://staging.runmybot.com/api/password_reset/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
  
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to send reset email');
    }
  
    return await response.json(); // return success data
  };
  
  // API call function
  export const setNewPasswordRequest = async (passwordData) => {
    
    const { userId, token, Password } = passwordData;
    // console.log('setNewPasswordRequest userId .. ',userId, 'token ',token, 'Password ',Password)
    const response = await fetch(
      `https://staging.runmybot.com/api/password_reset_confirm/${userId}/${token}/`,  // Add comma here
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password: Password }),  // Match API expected format
      }
    );
    
    if (!response.ok) {
      const errorData = await response.json(); // capture the error details
      throw new Error(errorData.message || 'Password reset failed.');
    }
  
    return await response.json(); // Return response data if successful
  };

  export const socialMediaLoginRequestAPI = async (payload) => {


    const { provider, currentUrl} = payload;
    var loginurl = `https://staging.runmybot.com/api/accounts/social/${provider}/login/?current_url=${currentUrl}`
    const response = await fetch(loginurl, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      // body: JSON.stringify({ provider: provider }),
    });
    if (!response.ok) {
      throw new Error('Social media login failed');
    }
    return response.json();
  };
  
  export const socialMediaCallbackAPI = async (payload) => {
    const { provider, currentUrl, code} = payload;
    const callbackUrl = `https://staging.runmybot.com/api/accounts/social/${provider}/login/callback/?current_url=${ currentUrl}/&code=${code}`;

    const response = await fetch(callbackUrl, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      // body: JSON.stringify({ code }),
    });
    if (!response.ok) {
      throw new Error('Google login failed.');
    }
    return response.json();
  };

  

export const googleLoginRequest = async (googleToken) => {
  const response = await fetch('https://staging.runmybot.com/api/accounts/google/login/', {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ token: googleToken }),
  });

  if (!response.ok) {
    throw new Error('Google login failed');
  }
  return response.json();
};

export const googleLoginCallbackApi = async (code) => {
  const response = await fetch(`https://staging.runmybot.com/api/accounts/google/login/callback/?code=${code}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    // body: JSON.stringify({ code }),
  });
  if (!response.ok) {
    throw new Error('Google login failed.');
  }
  return response.json();
};

