// PrivateRoute.js
import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  // Access the authentication status from the Redux store
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const location = useLocation();
  const navigate = useNavigate(); 
  // If not authenticated, redirect to the login page
  console.log('### isAuthenticated ',isAuthenticated)
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }else{

    const storedPage = sessionStorage.getItem('currentPage');
    const currentPath = window.location.pathname;
    if (storedPage && storedPage !== currentPath) {
      console.log('storedPage .. ',storedPage)
      navigate(storedPage);

    }
  }

  // If authenticated, render the nested routes (DashboardLayout and its content)
  return <Outlet />;
};

export default PrivateRoute;
