import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Clock, CheckCircle, Circle, Trash2, ExternalLink, Mail, MailOpen } from 'lucide-react';
import styles from "./NotificationContent.module.css";
import warningRound from '../../../../assetts/images/warningRound.svg';
import alertRound from '../../../../assetts/images/alertRound.svg';
import reminderRound from '../../../../assetts/images/reminderRound.svg';
import actionRequiredRound from '../../../../assetts/images/actionRequiredRound.svg';

const NotificationContent = ({
  notification,
  onDelete,
  onMarkAsRead,
  onMarkAsUnread,
  totalNotifications
}) => {

  // console.log("notifications", notification)

  if (totalNotifications === 0) {
    return (
      <div className={styles.noSelection}>
        <div
          className={styles.emptyState}
        >
          <img src={reminderRound} alt="No messages" className={styles.emptyIcon} />
          <h3>No Messages Yet</h3>
          <p>You don't have any messages in your inbox.</p>
        </div>
      </div>
    );
  }

  const getNotificationIcon = (type) => {
    switch (type?.toLowerCase()) {
      case 'warning':
        return warningRound;
      case 'alert':
        return alertRound;
      case 'reminder':
        return reminderRound;
      case 'action_required':
      case 'action required':
        return actionRequiredRound;
      default:
        return reminderRound;
    }
  };

  return (
    notification && (<div
      className={styles.notificationContent}
    >
      <div className={styles.header}>
        <div className={styles.headerMain}>
          <img
            src={getNotificationIcon(notification?.notificationType)}
            alt={notification?.notificationType || 'Notification'}
            className={styles.typeIcon}
          />
          <div className={styles.headerText}>
            <h3 className={styles.senderName}>{notification?.notificationType}</h3>
            <div className={styles.timeWrapper}>
              {/* <Clock size={14} /> */}
              <span className={styles.timestamp}>{notification.createdDate}</span>
            </div>
          </div>
        </div>

        <div className={styles.actionLinks}>
          <button
            className={styles.actionButton}
            onClick={() => notification.isRead ? onMarkAsUnread() : onMarkAsRead()}
          >
            {notification.isRead ? <MailOpen size={16} /> : <Mail size={16} />}
            <span>{notification.isRead ? 'Unread' : 'Read'}</span>
          </button>


          {/* <button
            className={`${styles.actionButton} ${notification.isRead ? styles.unreadButton : styles.readButton}`}
            onClick={() => notification.isRead ? onMarkAsUnread() : onMarkAsRead()}
          >
            {notification.isRead ? <Circle size={16} /> : <CheckCircle size={16} />}
            <span>Mark as {notification.isRead ? 'Unread' : 'Read'}</span>
          </button> */}

          <button
            className={`${styles.actionButton} ${styles.deleteButton}`}
            onClick={onDelete}
          >
            <Trash2 size={18} />
          </button>
        </div>
      </div>

      <div
        className={styles.contentSection}
      >
        <h2 className={styles.title}>{notification.title}</h2>

        <div className={styles.body}>
          <p className={styles.description}>{notification.description}</p>
          {notification.content && (
            <p className={styles.additionalText}>{notification.content}</p>
          )}
          <div className={styles.signature}>
            <p className={styles.footerText}>
              Have a nice day,<br />
              <span className={styles.signatureName}>Yadu</span>
            </p>
          </div>
        </div>

        {notification.actionUrl && (
          <div
            className={styles.actions}
          >
            <button
              className={styles.primaryButton}
              onClick={() => window.location.href = notification.actionUrl}
            >
              <span>Go to Action</span>
              <ExternalLink size={16} />
            </button>
          </div>
        )}
      </div>
    </div>)
  );
};

export default NotificationContent;