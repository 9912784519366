import { fetchApi } from "../utils/apiUtils";


export const fetchScheduleListApi = async (accessToken, currentPage) => {

    const response = await fetchApi(`list-schedule/?page=${currentPage}`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`, // Explicitly add token
            },
            requiresAuth: true
        }
    );
    return await response;


}

export const fetchTimeZoneApi = async (accessToken) => {

    const response = await fetchApi(`timezone/list/`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`, // Explicitly add token
            },
            requiresAuth: true
        }
    );
    return await response;


}

export const fetchOccurenceApi = async (accessToken) => {

    const response = await fetchApi(`list-occurence/`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`, // Explicitly add token
            },
            requiresAuth: true
        }
    );
    return await response;


}