import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TimeModule from "./reusableModule/TimeModule";
import TimeFrequency from "./reusableModule/TimeFrequency";


const DailyScheduler = () => {
    const [frequency, setFrequency] = useState('1');

    return (

        <div style={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
            {/* Days Selection */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <Typography style={{ width: "12%" }}>Day(s):</Typography>
                <RadioGroup
                    row
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                >
                    <FormControlLabel value="1" control={<Radio />} label="At" />
                    <FormControlLabel value="2" control={<Radio />} label="Every" />
                </RadioGroup>
            </div>

            {/* Recurrence Selection */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: "12px" }}>
                <Typography style={{ width: "12%" }}>Recurs:</Typography>
                <TextField
                    type="number"
                    inputProps={{ min: 1 }}

                    sx={{
                        width: "38%",
                        height: "48px",
                        "& .MuiInputBase-root": {
                            height: "48px",
                        }
                    }}
                />
            </div>

            {/* Conditional Rendering of Time Module */}
            <div>
                {frequency === "1" ? <TimeModule /> : <TimeFrequency />}
            </div>
        </div>


    )


}

export default DailyScheduler;

