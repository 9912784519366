import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Search, Trash2, CheckSquare, Square, Mail, MailOpen } from "lucide-react";
import styles from "./NotificationsSidebarHeader.module.css";
import searchIcon from "../../../../assetts/images/searchIcon.svg";
import { ReactComponent as SearchIcon } from "../../../../assetts/images/searchIcon.svg";

const NotificationsSidebarHeader = ({
  notifications,
  selectedNotifications,
  onSelectAll,
  onBulkDelete,
  onBulkMarkAsRead,
  onBulkMarkAsUnread,
  searchTerm,
  setSearchTerm,
  totalResults
}) => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const hasSelectedItems = selectedNotifications.length > 0;

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerm(debouncedSearchTerm);
    }, 300);
    return () => clearTimeout(handler);
  }, [debouncedSearchTerm, setSearchTerm]);

  return (
    <div
      className={styles.actionsHeader}
    >

      <div className={styles.searchContainer}>
        <div className={styles.searchSection}>
          <div className={styles.searchWrapper}>
            <img src={searchIcon} alt="Search" className={styles.searchIcon} />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Search..."
            />
          </div>
        </div>
        {/* <SearchIcon className={styles.searchIcon} />
        <input
          type="text"
          placeholder="Search notifications..."
          className={styles.searchInput}
          value={debouncedSearchTerm}
          onChange={(e) => setDebouncedSearchTerm(e.target.value)}
        /> */}
        {totalResults > 0 && (
          <span className={styles.resultCount}>
            {totalResults} result{totalResults !== 1 ? 's' : ''}
          </span>
        )}
      </div>

      <div
        className={styles.bulkActions}
      >


        <div
          className={`${styles.actions} ${hasSelectedItems ? styles.active : ''}`}
          animate={{ opacity: hasSelectedItems ? 1 : 0.5 }}
        >
          <div className={styles.actionButtonContainer}>
            <label
              className={styles.selectAllLabel}
            >
              <div
                className={styles.checkbox}
                onClick={onSelectAll}
              >
                {selectedNotifications.length === notifications.length ? (
                  <CheckSquare size={18} className={styles.checkIcon} />
                ) : (
                  <Square size={18} className={styles.checkIcon} />
                )}
              </div>
            </label>
            <button
              className={styles.actionButton}
              onClick={onBulkMarkAsRead}
              disabled={!hasSelectedItems}
            >
              <Mail size={16} />
              <span>Read</span>
            </button>

            <button
              className={styles.actionButton}
              onClick={onBulkMarkAsUnread}
              disabled={!hasSelectedItems}
            >
              <MailOpen size={16} />
              <span>Unread</span>
            </button>
          </div>
          <button
            className={`${styles.actionButton} ${styles.deleteButton}`}
            onClick={onBulkDelete}
            disabled={!hasSelectedItems}
          >
            <Trash2 size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationsSidebarHeader;