import React, { useState, useEffect } from "react";
import { CssBaseline, Box } from "@mui/material";
import IntegrationsHeader from "../../components/IntegrationsHeader/IntegrationsHeader";
import IntegrationsSidebar from "../../components/IntegrationsSidebar/IntegrationsSidebar";
import IntegrationsGrid from "../../components/IntegrationsGrid/IntegrationsGrid";
import styles from "./IntegrationsLibraryPage.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIntegrationTilesApi,
  fetchTagsApi,
} from "../../../../api/integrationApi"; 

const IntegrationsLibraryPage = () => {
  const [filters, setFilters] = useState({ system: [], dataWrangler: [] });
  const [tilesData, setTilesData] = useState({ results: [], next: null });
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log('^^^^^^^ accessToken :',accessToken)
  const functionsList = ["Retail", "Finance"];

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        console.log('~~~~~~~~~~~~~~~~ isAuthenticated .. ',isAuthenticated, 'accessToken :',accessToken)
        // if (isAuthenticated && accessToken) {
          const tagsResponse = await fetchTagsApi();
          
          const categorizedTags = tagsResponse.reduce(
            (acc, tag) => {
              if (tag.tag_type === "System") {
                acc.system = tag.tag_names.map(tagName => ({
                  id: tagName.id,
                  name: tagName.name,
                }));
              } else if (tag.tag_type === "Data Wrangler") {
                acc.dataWrangler = tag.tag_names.map(tagName => ({
                  id: tagName.id,
                  name: tagName.name,
                }));
              }
              return acc;
            },
            { system: [], dataWrangler: [] }
          );
          
          setFilters(categorizedTags);

          const initialTilesData = await fetchIntegrationTilesApi({
            name: "",
          });

          setTilesData(initialTilesData);
        // } else {
        //   throw new Error("User is not authenticated");
        // }
      } catch (error) {
        const errorMessage = error?.errors?.length ? 
          error.errors.map(err => err.detail).join('. ') : 
          'Failed to load integration tiles or filters';
        setError(errorMessage);
        console.error('Error fetching initial data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
    
  }, [accessToken, isAuthenticated, dispatch]);

  const handleTagSelection = async (selectedTagId, selectedFunc) => {
    setLoading(true);
    setError(null);

    try {
      const requestPayload = {
        name: "",
        function: selectedFunc || "",
        ...(selectedTagId && { tag: selectedTagId }),
      };
      
      const newTilesData = await fetchIntegrationTilesApi(requestPayload);
      setTilesData(newTilesData);
    } catch (error) {
      const errorMessage = error?.errors?.length ? 
        error.errors.map(err => err.detail).join('. ') : 
        'Failed to fetch integrations with selected filters';
      setError(errorMessage);
      console.error('Error fetching filtered integrations:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    if (!tilesData.next || loadingMore) return;

    setLoadingMore(true);
    setError(null);

    try {
      const newData = await fetchIntegrationTilesApi({}, tilesData.next.substring(tilesData.next.indexOf('api/') + 4));
      
      setTilesData(prev => ({
        ...newData,
        results: [...prev.results, ...newData.results]
      }));
    } catch (error) {
      const errorMessage = error?.errors?.length ? 
        error.errors.map(err => err.detail).join('. ') : 
        'Failed to load more integrations';
      setError(errorMessage);
      console.error('Error loading more integrations:', error);
    } finally {
      setLoadingMore(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        overflowX: "hidden",
      }}
    >
      <CssBaseline />
      <div className={styles.IntegrationsLibraryPage}>
        <div className={styles.headerAndContent}>
          <IntegrationsHeader />
          <div className={styles.mainContent}>
            <IntegrationsSidebar
              filters={filters}
              functions={functionsList}
              onTagSelectionChange={handleTagSelection}
            />
            <div className={styles.contentContainer}>
              <IntegrationsGrid 
                tiles={tilesData.results} 
                hasMore={!!tilesData.next}
                onLoadMore={handleLoadMore}
                loading={loading}
                loadingMore={loadingMore}
                error={error} 
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default IntegrationsLibraryPage;