import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchCalendarListApi, deleteCalendarApi } from '../../../api/calendarApi';
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CustomPagination from '../../common/CustomPagination';
import Preloader from '../../common/Preloader';
import CustomTooltip from '../../reusableComponents/CustomTooltip/CustomTooltip';
import theme from '../../../theme';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CalendarNew from './CalendarNew';
const Calendar = ({ showCalendarNew, setShowCalendarNew, setType }) => {

  const [calendarData, setCalendarData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [calendarId, setCalendarId] = useState("")
  const [error, setError] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const accessToken = useSelector((state) => state.auth.accessToken);
  useEffect(() => {
    if (!showCalendarNew) {
      getCalenderList()
    }
  }, [showCalendarNew, currentPage])

  const getCalenderList = async () => {
    setLoading(true)
    try {
      const data = await fetchCalendarListApi(accessToken, currentPage);
      if (data.results != undefined) {
        setCalendarData(data.results);
        setTotalPages(Math.ceil(data.count / 10))
        setLoading(false)
      }

    } catch (error) {
      console.error('Failed to fetch calendarData:', error);
      setLoading(false)
    }
  };

  const handleEdit = (id) => {
    setCalendarId(id)
    setShowCalendarNew(true)
    setType('Calendar')
    console.log(`Start timer for template ${id}`);
  };

  const handleDelete = async (id) => {
    try {
      const data = await deleteCalendarApi(accessToken, id);
      getCalenderList()
    } catch (error) {
      console.error('Failed to delete calendarData:', error);
    }

  };


  const handleClickCreate = () => {
    setCalendarId("")
    setShowCalendarNew(true)
    setType('Calendar')
  }
  const handlePageChange = (selectedPage) => {
    setLoading(true)
    setCurrentPage(selectedPage);
  };


  if (loading && !showCalendarNew) {
    return <Preloader />; // Show preloader while loading
  }

  return (

    <Box>
      {!showCalendarNew ? (
        <Box sx={{ height: "calc(100vh - (188px))" }}>
          <Grid container item xs={12} alignItems="center" justifyContent="space-between">
            <Typography
              variant="h4"
              sx={{
                ml: 2,
                fontFamily: 'Inter',           // Set font-family to 'Inter'
                fontSize: '20px',              // Set font-size to 20px
                fontWeight: 600,               // Set font-weight to 600
                lineHeight: '24.2px',          // Set line-height to 24.2px
                letterSpacing: '0.01em',       // Set letter-spacing to 0.01em
                textAlign: 'left',             // Align text to the left
              }}
            >
              Calendars
            </Typography>
            <Button
              variant="contained"

              sx={{
                width: '145px',
                height: '32px',
                borderRadius: '24px',
                backgroundColor: '#7398C7',
                boxShadow: 'none',
                textTransform: 'none',
                marginRight: '16px', // Space between buttons
                '&:hover': {
                  backgroundColor: '#7398C7',
                },
                '& .MuiButton-startIcon': {
                  borderRadius: '50%',
                  padding: '0px',
                },
              }}
              startIcon={<AddCircleOutlinedIcon sx={{ borderRadius: '50%', fontSize: 20, marginLeft: '5px' }} />}
              onClick={handleClickCreate}

            >
              Create New
            </Button>
          </Grid>
          <Box
            sx={{
              borderRadius: '25px', // Outer border radius set to 25px
              p: 2, // Padding inside the box
              border: '1.81px solid #ECEEF6', // Applying the specific border style
              mt: 3, // Optional margin-top adjustment
            }}
          >
            <TableContainer sx={{ mb: 2 }}>
              <Table sx={{ borderCollapse: 'collapse' }}>
                <TableHead sx={{
                  borderBottom: "1.81px solid #EFEFEF"
                }}>
                  <TableRow>
                    <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2 }}>Name</TableCell>
                    <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(calendarData) && calendarData.length > 0 ? (
                    calendarData.map((template, index) => (
                      <TableRow key={index} sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        height: '25px', // Adjust the row height to reduce spacing
                        '&:hover': {
                          backgroundColor: '#f0f0f0', // Optional: Change background color on hover
                        },
                      }}>

                        <TableCell
                          // onClick={() => navigate(`/workflow-builder/template/${template.id}`)}
                          sx={{
                            cursor: 'pointer',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: '#000000',
                            fontWeight: 600,
                            fontSize: '14px',
                            ml: 2,
                            width: "70%",
                            lineHeight: '16.94px',
                            borderBottom: 'none', // No bottom border
                            padding: '8px 14px', // Reduced padding
                            userSelect: 'none',
                            // '&:hover': {
                            //   backgroundColor: '#f0f0f0', // Optional: Change background color on hover
                            // },
                          }}
                        >
                          {template.name}
                        </TableCell>


                        <TableCell align="left" sx={{ borderBottom: 'none', padding: '4px 8px', ml: 5, display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 0 }}>
                          <CustomTooltip title="Edit/View">
                            <IconButton
                              onClick={() => handleEdit(template.id)}
                              sx={{
                                backgroundColor: '#F2F2F2',
                                color: '#4F63BE',
                                mt: 1,
                                mr: 2,
                                p: 1,
                                '&:hover': {
                                  backgroundColor: '#D9D9D9',
                                },
                              }}
                              aria-label="timer"
                            >
                              <BorderColorOutlinedIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                          </CustomTooltip>
                          <CustomTooltip title="Delete">
                            <IconButton
                              onClick={() => handleDelete(template.id)}
                              sx={{
                                backgroundColor: '#F2F2F2',
                                color: '#4F63BE',
                                mt: 1,
                                mr: 1,
                                p: 1,
                                '&:hover': {
                                  backgroundColor: '#D9D9D9',
                                },
                              }}
                              aria-label="delete"
                            >
                              <DeleteOutlineOutlinedIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                          </CustomTooltip>
                        </TableCell>
                      </TableRow>
                    ))) : (
                    <TableCell colSpan={5} sx={{ textAlign: "center" }}>No data available.</TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignContent='center'
            sx={{
              width: '100%',
              position: 'relative',

              bottom: -30,
              alignItems: "center",
              border: '0px solid #ECEEF6',
            }}>

            <CustomPagination
              totalPages={totalPages}
              curPage={currentPage}
              onPageChange={handlePageChange}
            ></CustomPagination>

          </Box>
        </Box>
      ) : (
        <Box >
          <CalendarNew setShowCalendarNew={setShowCalendarNew} calendarId={calendarId} />
        </Box>
      )}
    </Box>

    // 

  );
}

export default Calendar;