import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import './barchart.css'

ChartJS.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

const BarChart = () => {
    const data = {
        labels: ['Premium Workflows', 'Freemium Workflows'],
        datasets: [
            {
                label: 'Success',
                data: [7, 2], // Premium Success, Freemium Success
                backgroundColor: '#4f63be',
                barPercentage: 0.9,
                categoryPercentage: 0.4,
            },
            {
                label: 'Failure',
                data: [3, 1], // Premium Failure, Freemium Failure
                backgroundColor: '#828282',

                barPercentage: 0.9,
                categoryPercentage: 0.4,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rect',
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        return value < 10 ? `0${value}` : value;
                    },
                },
            },
        },
        scales: {
            y: {
                ticks: {
                    callback: (value) => (value < 10 ? `0${value}` : value),
                    stepSize: 2,
                },
                beginAtZero: true,
                max: 10,
            },
            x: {
                grid: { display: false },
                ticks: {
                    color: "#5E5E5E"
                }
            },
        },
    };



    return (
        <div className='barchart-container' style={{ textAlign: 'center', position: 'relative' }}>
            <Bar data={data} options={options}  />
        </div>
    );
};

export default BarChart;
