import { Box, Button, IconButton, Modal, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomerForm from '../../components/user-management/CustomerForm';
import CustomersList from '../../components/user-management/CustomersList';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { fetchCustomersApi } from '../../api/usermanagmentApi';
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from '../../components/common/CustomPagination';
import DynamicTable from '../../components/reusableComponents/DynamicTable/DynamicTable';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Preloader from '../../components/common/Preloader';
import { fetchCustomerUsers, handleCustomerEdit } from '../../components/user-management/services/customerService';
import CustomAlert from '../../components/reusableComponents/CustomAlert/CustomAlert';

const Customer = () => {
  const [allCustomersData, setAllCustomersData] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [customerUsers, setCustomerUsers] = useState({
    data: [],
    totalCount: 0,
    nextPage: null,
    prevPage: null,
    currentPage: 1, // Start with page 1
    totalPages: 0, // Will be calculated dynamically
    currentCustomerId: null,
    loading: false
  });
  const [currentCustomerId, setCurrentCustomerId] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [modalType, setModalType] = useState()
  const [alert, setAlert] = useState({ open: false, message: "", type: "info" });
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const customerConfig = useSelector((state) => state.auth.userDetails?.permissionConfig?.customer);
  let accessToken = useSelector((state) => state.auth.accessToken);

  const handleOpen = () => {
    setIsModalOpen(true)
    setModalType('createCustomer')
  };
  const handleClose = () => setIsModalOpen(false);
  useEffect(() => {
    getAllCustomers();
  }, [currentPage])
  useEffect(() => {
    if (customerUsers.currentCustomerId) {
      fetchCustomerUsers({
        accessToken,
        id: customerUsers.currentCustomerId, // Explicitly set key `id`
        page: customerUsers.currentPage, // Explicitly set key `page`
        setCustomerUsers,
        setIsModalLoading,
        setIsModalOpen,
        setModalType,
        setAlert
      });
    }
  }, [customerUsers.currentCustomerId, customerUsers.currentPage]); // Dependency array
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  const getAllCustomers = async () => {
    setLoading(true);
    try {
      // if (!isTokenValid(accessToken)) {
      //   accessToken = refreshAccessToken();
      //   if (accessToken) dispatch(reAuthenticationRequest(accessToken));
      // }
      const data = await fetchCustomersApi(accessToken, "", currentPage); // Call the API to fetch templates
      setAllCustomersData(data.results); // Set the 'results' array from the response
      setTotalPages(Math.ceil(data.count / 10))
      setLoading(false)
    } catch (error) {
      const errorMessage = error?.errors?.length ? 
                error.errors.map(err => err.detail).join('. ') : 
                'Failed to load customers';
      setAlert((prev) => ({ ...prev, open: true, message:errorMessage , type: "failure" })); 

      setLoading(false)
      console.error('Failed to fetch templates:', error);

    }
  };

  const handleCustomerActions = async (type, id) => {
    switch (type) {
      case 'edit':
        setIsModalLoading(true)
        setCurrentCustomerId(id)
        setModalType("editCustomer")
        setIsModalOpen(true)
        handleCustomerEdit({accessToken, id,setAlert,setIsModalLoading,setCustomerData})
        // setCustomerData(data)
        // setIsModalLoading(false)
        break;
      case 'viewUsers':
        // setCustomerUsers((prev)=>({
        //   ...prev,
        //   currentCustomerId:id,
        //   currentPage:1
        // }))
        fetchCustomerUsers({
          accessToken,
          id,
          page: 1,
          setCustomerUsers,
          setIsModalLoading,
          setIsModalOpen,
          setModalType,
          setAlert
        }
        );

        break;
      default:
        console.log("unknown button type", type)
        break;
    }
  }
  return (

    <Box sx={{ p: 5, mt: 8 }}>
            <CustomAlert
                open={alert.open}
                message={alert.message}
                type={alert.type}
                onClose={() => setAlert({ ...alert, open: false })}
            />
      {
        loading ? (
          <Preloader />
        ) : (
          <>
            <Grid2 item xs={12} sx={{ backgroundColor: 'transparent', borderBottom: 'none', display: "flex", justifyContent: "space-between" }}>
              <Paper elevation={1} sx={{
                paddingBottom: 2, backgroundColor: 'transparent', border: 'none',  boxShadow: 'none', // Removes shadow
              }}>
                <Typography variant="h5" gutterBottom sx={{
                  fontFamily: 'Inter', fontSize: '20px', fontWeight: 600, lineHeight: "24.2px", color: '#000000', boxShadow: 'none'
                }}>
                  Customers List
                </Typography>

              </Paper>
              {customerConfig.create && (
                <Button
                variant="contained"
                sx={{ alignItems: 'left', width: '114px', height: '32px', fontSize: "12px", padding: "0px", borderRadius: '24px', backgroundColor: '#7398c7', boxShadow: 'none', textTransform: 'none', '& .MuiButton-startIcon': { borderRadius: '50%', padding: '0px', }, '&:hover': { backgroundColor: '#7398C7', }, }}
                startIcon={<AddCircleOutlinedIcon sx={{ borderRadius: '50%', width: "16px" }} />}
                onClick={handleOpen} // Open drawer on click
              >
                Create New
              </Button>
              )}
            </Grid2>

            <Box className="buttonGroup" sx={{ display: "flex", flexDirection: "column" }}>
              <CustomersList customersData={allCustomersData} handleCustomerActions={handleCustomerActions} />
              <Box
                display="flex"
                justifyContent="flex-end"
                alignContent='center'
                sx={{
                  width: '100%',
                  position: 'relative',

                  bottom: -30,
                  alignItems: "center",
                  border: '0px solid #ECEEF6',
                }}>

                <CustomPagination
                  totalPages={totalPages}
                  curPage={currentPage}
                  onPageChange={handlePageChange}
                ></CustomPagination>

              </Box>
            </Box>
          </>

        )}
      <Modal open={isModalOpen} onClose={handleClose}>

        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, maxHeight: "90vh", width: '60vw', overflowY: "auto", borderRadius: "24px", }}        >
          <Typography variant="h6" component="h2" sx={{
            fontFamily: "inter",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "29.05px",
            mb: 4
          }}>
            {modalType === "createCustomer" && "Create Customer"}
            {modalType === "editCustomer" && "Edit Customer"}
            {modalType === "viewUsers" && "Customer Users"}
          </Typography>

          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "32px",
              right: "32px",
              p: 0,
              color: (theme) => theme.palette.grey[500],
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          {isModalLoading ? (
            <Preloader />
          ) : (<>
            {/* Conditional Rendering based on modalType */}
            {modalType === "viewUsers" ? (
              customerUsers.totalCount > 0 ? (
                <DynamicTable
                  columns={[

                    { field: "first_name", headerName: "First Name" },
                    { field: "last_name", headerName: "Last Name" },
                    { field: "email", headerName: "Email" },
                  ]}
                  data={customerUsers.data}
                  totalItems={customerUsers.totalCount}
                  totalPages={customerUsers.totalPages}
                  currentPage={customerUsers.currentPage}
                  handlePageChange={(selectedPage) => { setCustomerUsers((prev) => ({ ...prev, currentPage: selectedPage })) }}
                />
              ) : (
                <div>No data available.</div>
              )
            ) : (
              <CustomerForm customerDataEdit={customerData} currentCustomerId={currentCustomerId}  setAlert={setAlert}  setIsModalOpen={setIsModalOpen} getAllCustomers={getAllCustomers}/>
            )}

          </>
          )}
        </Box>


      </Modal>
    </Box>
  );
};

export default Customer;
