import React, { useState, useRef, useEffect, forwardRef } from 'react';
import { ReactComponent as DropdownIconOpen } from '../../../assetts/images/dropdownIconOpen.svg';
import { ReactComponent as DropdownIconClosed } from '../../../assetts/images/dropdownIconClosed.svg';
import TruncatedText from './TruncatedText';
import DropdownItem from './DropdownItem';
import { searchInItems } from './utils';
import styles from './CustomDropdown.module.css';
import { StyleSharp } from '@mui/icons-material';

const CustomDropdown = forwardRef(({
  label,
  items = [],
  value,
  onChange,
  disabled = false,
  disabledItems = [],
  placeholder = 'Select',
  style = {},
  multiSelect = false,
  choosenShouldShow = 'label',
  name,
  'aria-invalid': ariaInvalid,
  className,
  onInvalid,
  ...validationProps
}, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const dropdownRef = useRef(null);
  const hiddenInputRef = useRef(null);

  // Forward ref for form validation
  React.useImperativeHandle(ref, () => hiddenInputRef.current);

  // Find label for a given value
  const getItemLabel = (itemValue) => {
    for (let item of items) {
      if (item.value === itemValue) return item.label;
      if (item.children) {
        const match = item.children.find(child => child.value === itemValue);
        if (match) return match.label;
      }
    }
    return null;
  };

  // Get display text for selected value(s)
  const isEmptyValue = () => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return value === undefined || value === null || value === '';
  };

  const getDisplayText = () => {
    if (isEmptyValue()) {
      return placeholder;
    }
    const formatValue = v => {

      return choosenShouldShow === 'value' ? v : getItemLabel(v);
    };

    return Array.isArray(value) ?
      value.map(formatValue).join(', ') :
      formatValue(value);
  };

  // Handle item selection
  const handleSelect = (selectedValue) => {
    if (disabled || disabledItems.includes(selectedValue)) return;

    const newValue = multiSelect ?
      (Array.isArray(value) ? value : []).includes(selectedValue) ?
        value.filter(v => v !== selectedValue) :
        [...(value || []), selectedValue] :
      selectedValue;

    onChange(newValue);

    // Update hidden input
    if (hiddenInputRef.current) {
      hiddenInputRef.current.value = Array.isArray(newValue) ? newValue.join(',') : newValue;
      hiddenInputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }

    if (!multiSelect) {
      setIsOpen(false);
      setSearchText('');
    }
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
        setSearchText('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Check validation on value change
  useEffect(() => {
    if (hiddenInputRef.current?.validity.valid === false) {
      onInvalid?.(new Event('invalid'));
    }
  }, [value, onInvalid]);

  const filteredItems = searchText ? searchInItems(items, searchText) : items;
  const isInvalid = ariaInvalid === true || ariaInvalid === 'true';
  const headerClasses = [
    style.background && styles.headerbackground,
    styles.header,
    isOpen && styles.open,
    disabled && styles.disabled,
    isInvalid && styles.invalid,
    className
  ].filter(Boolean).join(' ');

  return (
    <div className={styles.container} style={style} ref={dropdownRef}>
      {label && (
        <label className={`${styles.label} ${disabled ? styles.disabled : ''}`}>
          {label}
          {validationProps.required && <span className={styles.required}>*</span>}
        </label>
      )}

      <div className={styles.dropdown}>
        <div className={headerClasses} onClick={() => !disabled && setIsOpen(!isOpen)}>
          <div className={styles.headerContent}>
            <TruncatedText
              text={getDisplayText()}
              className={[
                styles.selectedText,
                isEmptyValue() ? styles.placeholder : null
              ].filter(Boolean).join(' ')}
            />
          </div>
          {isOpen ?
            <DropdownIconOpen className={styles.icon} /> :
            <DropdownIconClosed className={styles.icon} />
          }
        </div>

        {isOpen && (
          <div className={styles.list}>
            <div className={styles.search}>
              <input
                type="text"
                className={styles.searchInput}
                placeholder="Search..."
                value={searchText}
                onChange={e => setSearchText(e.target.value)}
                onClick={e => e.stopPropagation()}
              />
            </div>

            {filteredItems.length ?
              filteredItems.map(item => (
                <DropdownItem
                  key={item.value}
                  item={item}
                  onSelect={handleSelect}
                  isSelected={multiSelect ?
                    (value || []).includes(item.value) :
                    value === item.value
                  }
                  multiSelect={multiSelect}
                  value={value}
                  disabled={disabledItems.includes(item.value)}
                />
              )) :
              <div className={styles.noResults}>No results found</div>
            }
          </div>
        )}

        <input
          ref={hiddenInputRef}
          type="hidden"
          name={name}
          value={multiSelect ? (value || []).join(',') : (value || '')}
          {...validationProps}
        />
      </div>
    </div>
  );
});

CustomDropdown.displayName = 'CustomDropdown';
export default CustomDropdown;