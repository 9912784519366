import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import { TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CustomTooltip from "../../reusableComponents/CustomTooltip/CustomTooltip";

const Once = () => {
    const [scheduleData, setScheduleData] = useState([{
        date: dayjs(),
        time: dayjs(),
    }]);

    const addRow = () => {
        setScheduleData([
            ...scheduleData,
            {
                date: dayjs(),
                time: dayjs()
            },
        ]);

    };

    const removeRow = (e, index) => {
        if (scheduleData.length === 1 && index === 0) {
            const list = [...scheduleData];
            list[index].date = dayjs();
            list[index].time = dayjs();
            setScheduleData(list);
        } else {
            const list = [...scheduleData];
            list.splice(index, 1);
            setScheduleData(list);
        }

    };

    const handleDate = (e, index) => {
        const list = [...scheduleData];
        list[index].date = e;
        setScheduleData(list);
    };
    const handleTime = (e, index) => {
        const list = [...scheduleData];
        list[index].time = e;
        setScheduleData(list);
    };


    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                {scheduleData.map((singleService, index) => (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "nowrap",
                            rowGap: "16px",
                            marginBottom: "16px",
                            width: "100%",
                        }}
                        key={index}
                    >
                        <div style={{ minWidth: "100px", width: "12%", display: "flex", alignItems: "center" }}>
                            <Typography>Date:</Typography>
                        </div>
                        <div
                            style={{
                                width: "83.33%",
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                            }}
                        >
                            <DatePicker
                                slotProps={{
                                    desktopPaper: {
                                        sx: {
                                            backgroundColor: "#fff",
                                            color: "#1C1C1C",
                                            "& .MuiPickersDay-root": {
                                                color: "#6B6B6B",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "#C4C4C4",
                                                color: "white",
                                            },
                                        },
                                    },
                                }}
                                sx={{
                                    height: "48px",
                                    width: "100%",


                                    "& .MuiInputBase-root": {
                                        height: "100%",
                                        minHeight: "45px",
                                        display: "flex",
                                        alignItems: "center", padding: "16px",
                                    },
                                    "& .MuiInputBase-input": {
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "400",
                                    }
                                }}
                                value={scheduleData[index].date}
                                onChange={(newDate) => handleDate(newDate, index)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                            <Typography style={{ paddingRight: "12px" }}>Time:</Typography>
                            <TimePicker
                                value={scheduleData[index].time}
                                onChange={(newTime) => handleTime(newTime, index)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                sx={{
                                    height: "48px",
                                    width: "100%",


                                    "& .MuiInputBase-root": {
                                        height: "100%",
                                        minHeight: "45px",
                                        display: "flex",
                                        alignItems: "center", padding: "16px",
                                    },
                                    "& .MuiInputBase-input": {
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        fontWeight: "400",
                                    }
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "10px",
                                    minWidth: "80px",
                                    marginLeft: "15px"
                                }}
                            >
                                <CustomTooltip title="Remove">
                                    <IconButton
                                        onClick={(e) => removeRow(e, index)}
                                        sx={{
                                            backgroundColor: "#F2F2F2",
                                            color: "#4F63BE",
                                            "&:hover": { backgroundColor: "#D9D9D9" },
                                        }}
                                        aria-label="delete"
                                    >
                                        <DeleteOutlineOutlinedIcon sx={{ fontSize: 20 }} />
                                    </IconButton>
                                </CustomTooltip>
                                <CustomTooltip title="Add">
                                    <IconButton
                                        onClick={(e) => addRow(e, index)}
                                        sx={{
                                            backgroundColor: "#F2F2F2",
                                            color: "#4F63BE",
                                            "&:hover": { backgroundColor: "#D9D9D9" },
                                            visibility: scheduleData.length - 1 === index ? "visible" : "hidden"
                                        }}
                                        aria-label="add"
                                    >
                                        <AddIcon sx={{ fontSize: 20 }} />
                                    </IconButton>
                                </CustomTooltip>
                            </div>
                        </div>
                    </div>
                ))}
            </LocalizationProvider>
        </div>
    )

}

export default Once;