import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import CustomTooltip from "../../reusableComponents/CustomTooltip/CustomTooltip";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TimeModule from "./reusableModule/TimeModule";
import TimeFrequency from "./reusableModule/TimeFrequency";

const WeeklyScheduler = () => {
    const [selectedDays, setSelectedDays] = useState([]);
    const [frequency, setFrequency] = useState('1');
    const [timeFields, setTimeFields] = useState({
        0: ["10:30"],
        1: ["10:30"],
        2: ["10:30"],
        3: ["10:30"],
        4: ["10:30"],
        5: ["10:30"],
        6: ["10:30"],
    });
    const types = ["0", "1", "2", "3", "4", "5", "6"];

    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    const changeDay = (e) => {
        if (selectedDays.includes(e.target.value)) {
            setSelectedDays((selectedDays) => [
                ...selectedDays.filter(
                    (selectedDays) => selectedDays !== e.target.value
                ),
            ]);
        } else {
            setSelectedDays((selectedDays) => [...selectedDays, e.target.value]);
        }
        if (e.target.value === "all") {
            if (selectedDays.length === 7) {
                setSelectedDays([]);

            } else {
                setSelectedDays(["0", "1", "2", "3", "4", "5", "6"]);
            }
        }

    };

    const handleAddField = (type) => {
        setTimeFields((prev) => ({
            ...prev,
            [type]: [...(prev[type] || []), ""],
        }));
    };


    const handleRemoveField = (type, index) => {
        setTimeFields((prev) => ({
            ...prev,
            [type]: prev[type].filter((item, i) => i !== index),
        }));
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {/* Week(s) Selection */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <Typography style={{ width: "12%" }}>Week(s):</Typography>
                <RadioGroup
                    row
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                >
                    <FormControlLabel value="1" control={<Radio />} label="At" />
                    <FormControlLabel value="2" control={<Radio />} label="Every" />
                </RadioGroup>
            </div>

            {/* Recurrence Input */}
            <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
                <Typography style={{ width: "12%" }}>Recurs:</Typography>
                <TextField
                    type="number"
                    inputProps={{ min: 1, max: 12 }}
                    sx={{
                        width: "38%",
                        height: "48px",
                        "& .MuiInputBase-root": {
                            height: "48px",
                        }
                    }}
                />
            </div>
            {/* Days Selection */}
            <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
                <div style={{ marginLeft: "-10px", display: "flex", alignItems: "center" }}>
                    <Checkbox checked={selectedDays.length === 7} value="all" onChange={changeDay} />
                    <Typography style={{ marginRight: "10px" }}>All</Typography>
                </div>
                {types.map((type, index) => (
                    <div key={index} style={{ marginLeft: "-10px", display: "flex", alignItems: "center" }}>
                        <Checkbox
                            value={type}
                            checked={selectedDays.includes(type)}
                            onChange={changeDay}
                        />
                        <Typography style={{ marginRight: "10px" }}>{days[index]}</Typography>
                    </div>
                ))}
            </div>

            {/* Time Fields for Selected Days */}
            <div style={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                {types.map((type) =>
                    selectedDays.includes(type) && selectedDays.length !== 7 && frequency !== "2" ? (
                        <div key={type} style={{ display: "flex", alignItems: "center", marginTop: "18px" }}>
                            {/* Day Name */}
                            <div style={{ width: "100px" }}>
                                <Typography>{days[type]}</Typography>
                            </div>

                            {/* Time Fields */}
                            <div style={{ display: "flex", alignItems: "center", gap: "10px", flexWrap: "wrap" }}>
                                {(timeFields[type] || []).map((item, index) => (
                                    <div key={index} style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                        <TextField
                                            type="time"
                                            sx={{
                                                height: "48px",
                                                "& .MuiInputBase-root": {
                                                    height: "48px",
                                                }
                                            }}
                                        />
                                        {/* Remove Button */}
                                        <CustomTooltip title="Remove">
                                            <IconButton
                                                style={{
                                                    backgroundColor: "#F2F2F2",
                                                    color: "#4F63BE",
                                                    "&:hover": { backgroundColor: "#D9D9D9" },
                                                }}
                                                aria-label="delete"
                                                onClick={() => handleRemoveField(type, index)}
                                            >
                                                <DeleteOutlineOutlinedIcon style={{ fontSize: 20 }} />
                                            </IconButton>
                                        </CustomTooltip>
                                    </div>
                                ))}
                                {/* Add Button */}
                                <CustomTooltip title="Add">
                                    <IconButton
                                        style={{
                                            backgroundColor: "#F2F2F2",
                                            color: "#4F63BE",
                                            "&:hover": { backgroundColor: "#D9D9D9" },
                                        }}
                                        aria-label="add"
                                        onClick={() => handleAddField(type)}
                                    >
                                        <AddIcon style={{ fontSize: 20 }} />
                                    </IconButton>
                                </CustomTooltip>
                            </div>
                        </div>
                    ) : null
                )}
            </div>

            {/* Time Module / Time Frequency */}
            <div>
                {frequency === "1" && selectedDays.length === 7 ? <TimeModule /> : frequency === "2" ? <TimeFrequency /> : null}
            </div>
        </div>

    )

}

export default WeeklyScheduler
