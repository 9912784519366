import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCalendarApi, updateCalendarApi } from '../../../api/calendarApi';
import { getCountryMasterData, getExistingData, getHolidays } from '../services/calendarService';
import Grid from '@mui/material/Grid';
import CalendarForm from './CalendarForm.js';
import CalendarHeader from './CalendarHeader';
import EditHolidayModal from './EditHolidayModal';
import Preloader from '../../common/Preloader';
import { validateForm, validationRules } from '../utils/calendarUtils.js';
import { Calendar, Day, Header, useCalendarState } from "@use-date-input/core";
import { adapter as dateAdapter } from "@use-date-input/date-fns-adapter";
import { startOfYear, format as formatDate } from "date-fns";
import Typography from '@mui/material/Typography';
import styled from "styled-components";
import CustomAlert from '../../reusableComponents/CustomAlert/CustomAlert.js';
const Holiday = styled(Day)((props) => ({
    background: "rgba(2, 117, 216, 1)",
    color: "#ffffff !important",
    opacity: 1,
}));


const CalendarNew = ({ setShowCalendarNew, calendarId }) => {
    const [holidays, setHolidays] = useState([]);
    const [showEditHolidayModal, setShowEditHolidayModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedWeekend, setSelectedWeekend] = useState([]);
    const [countries, setCountries] = useState([]);
    const [weekends, setWeekends] = useState([]);
    const [loader, setLoader] = useState(true);
    const [errors, setErrors] = useState('')
    const [alert, setAlert] = useState({ open: false, message: "", type: "info" });
    const [apiResponse, setApiResponse] = useState("");

    const [calendarData, setCalendarData] = useState({
        country: '',
        name: '',
        start_date: formatDate(new Date(), "yyyy-MM-dd"),
        end_date: formatDate(new Date(), "yyyy-MM-dd"),
        weekend: [],
        no_end_date: false,
        recurring: false,
        description: '',
        holidays: []
    });

    let accessToken = useSelector((state) => state.auth.accessToken);

    useEffect(() => {
        getCountryMasterData(accessToken, setCountries, setWeekends);

        if (calendarId !== "") {
            getExistingData(accessToken, calendarId, setLoader, setCalendarData, setHolidays);
        } else {
            setLoader(false)
        }
    }, [])

    useEffect(() => {
        setCalendarData((prev) => ({
            ...prev,
            holidays: holidays.map(item => ({
                ...item,
                date: formatDate(item.date, "yyyy-MM-dd")
            }))
        }));

    }, [holidays])
    const saveHoliday = (holiday, description, perpetual) => {
        const filteredHolidays = [...holidays].filter((d) => {
            if (
                d.date.getFullYear() === holiday.getFullYear() &&
                d.date.getMonth() === holiday.getMonth() &&
                d.date.getDate() === holiday.getDate()
            ) {
                return false;
            }
            return true;
        });
        setHolidays([...filteredHolidays, { date: holiday, description, perpetual }]);
        setShowEditHolidayModal(false);
    }
    const removeHoliday = (holidayToRemove) => {
        const newHolidays = [...holidays].filter((holiday) => {
            if (
                holiday.date.getFullYear() === holidayToRemove.getFullYear() &&
                holiday.date.getMonth() === holidayToRemove.getMonth() &&
                holiday.date.getDate() === holidayToRemove.getDate()
            ) {
                return false;
            }
            return true;
        });
        setHolidays(newHolidays);
        setShowEditHolidayModal(false);
    }
    // const isSmallBreakpoint = useMediaQuery("(max-width:1000px)");
    // const numOfColumns = isSmallBreakpoint ? 4 : 3;

    const numOfColumns = 3;
    const changeWeekend = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedWeekend(
            typeof value === 'string' ? value.split(',') : value,
        );
        setCalendarData((prev) => ({
            ...prev,
            "weekend": typeof value === 'string' ? value.split(',') : value
        }));
    };
    const changeHoliday = (e) => {
        // getHolidays(accessToken, e.target.key, setHolidays)
    }

    const isHoliday = (day) => {
        for (const holiday of holidays) {
            if (
                holiday.date.getFullYear() === day.getFullYear() &&
                holiday.date.getMonth() === day.getMonth() &&
                holiday.date.getDate() === day.getDate()
            ) {
                return true;
            }
        }
        return false;
    }
    const HolidayRenderer = (props) => {
        if (isHoliday(props.day)) {
            return <Holiday {...props} onSelectDate={handleDateSelected} />;
        }
        return <Day {...props} onSelectDate={handleDateSelected} />;
    }

    const handleDateSelected = (newSelectedDate) => {

        setSelectedDate(newSelectedDate);
        setShowEditHolidayModal(true);
    }
    const handleSubmit = async () => {
        const errors = validateForm(calendarData, validationRules);
        setErrors(errors);

        if (Object.keys(errors).length) {
            return;
        }

        if (calendarId !== "") {
            const { customer, ...updatedCalendarData } = calendarData;
            const response = await updateCalendarApi(accessToken, calendarId, updatedCalendarData);
            let errorMessages = [];
            if (response.message !== "Calender updated successfully.") {
                if (response && response.type === "validation_error") {
                    const messages = Array.isArray(response.errors)
                        ? response.errors.map((err) => `${err.attr}: ${err.detail}`)
                        : ["validation error"];
                    errorMessages.push(...messages);

                } else {
                    errorMessages.push("Failed to update calendar.");
                }
                setAlert((prev) => ({ ...prev, open: true, message: errorMessages, type: "failure" }));
                return
            }
            setAlert((prev) => ({ ...prev, open: true, message: response.message, type: "success" }));
            setTimeout(() => {
                setShowCalendarNew(false)
            }, 2000);
        } else {
            try {
                const response = await createCalendarApi(accessToken, calendarData);
                let errorMessages = [];
                if (response.message !== "Calender created successfully.") {
                    if (response && response.type === "validation_error") {
                        const messages = Array.isArray(response.errors)
                            ? response.errors.map((err) => `${err.attr}: ${err.detail}`)
                            : ["validation error"];
                        errorMessages.push(...messages);
                    } else {
                        errorMessages.push("Failed to create calendar.");
                    }
                    setAlert((prev) => ({ ...prev, open: true, message: errorMessages, type: "failure" }));
                    return
                }
                setAlert((prev) => ({ ...prev, open: true, message: response.message, type: "success" }));
                setTimeout(() => {
                    setShowCalendarNew(false)
                }, 2000);

            } catch (error) {
            }
        }
    }
    if (loader) {
        return <Preloader />; // Show preloader while loading
    }


    return (
        <Grid container spacing={4} sx={{ pt: 4 }}>
            <CustomAlert
                open={alert.open}
                message={alert.message}
                type={alert.type}
                onClose={() => setAlert({ ...alert, open: false })}
            />
            <CalendarForm calendarData={calendarData} setCalendarData={setCalendarData} handleSubmit={handleSubmit} countries={countries} changeHoliday={changeHoliday} changeWeekend={changeWeekend} weekends={weekends} errors={errors} />
            <Grid item xs={6} sx={{ flexGrow: 1, overflow: "auto", maxHeight: "100%" }}>
                <Typography sx={{ fontWeight: "bold" }}>Holidays</Typography>
                <Calendar
                    adapter={dateAdapter}
                    numOfColumns={numOfColumns}
                    initialVisibleFromMonth={startOfYear(new Date())}
                    numOfVisibleMonths={12}
                    components={{
                        Header: CalendarHeader,
                        Day: HolidayRenderer,
                    }}

                    sx={{ maxHeight: "100%", overflowY: "auto" }}
                />

            </Grid>
            <EditHolidayModal open={showEditHolidayModal} selectedDate={selectedDate} info={
                holidays.find(
                    (elem) =>
                        elem.date.toDateString() ===
                        new Date(selectedDate).toDateString()
                )?.description
            }
                isRecurring={
                    holidays.find(
                        (elem) =>
                            elem.date.toDateString() ===
                            new Date(selectedDate).toDateString()
                    )?.perpetual ?? false
                } handleClose={() => setShowEditHolidayModal(false)} saveHoliday={saveHoliday} removeHoliday={removeHoliday} />
        </Grid>
    )

}

export default CalendarNew;