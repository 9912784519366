// src/components/users/UserList.js
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomTooltip from '../reusableComponents/CustomTooltip/CustomTooltip';
import { Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import theme from '../../theme';
import CustomPagination from '../common/CustomPagination';

const UsersList = ({ userDatas, setCurrentPage, totalPages, currentPage, setLoading, handleUserActions }) => {
  const [usersData, setUsersData] = useState([]);
  const handlePageChange = (selectedPage) => {
    setLoading(true)
    setCurrentPage(selectedPage);
  };
  const userConfig = useSelector((state) => state.auth.userDetails?.permissionConfig?.user);
  useEffect(() => {
    setUsersData(userDatas)
    setLoading(false)
  }, [userDatas])

  return (
    <Grid container spacing={2} sx={{ padding: 2, borderBottom: 'none' }}>
      <Grid item xs={12} sx={{
        borderRadius: '25px', // Outer border radius set to 25px
        p: 2, // Padding inside the box
        border: '1.81px solid #ECEEF6', // Applying the specific border style
      }}>
        <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* <TableCell sx={{ ...theme.typography.tableCellHeader }}>No</TableCell> */}
                <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>First Name</TableCell>
                <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Last name</TableCell>
                <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Email</TableCell>
                <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Roles</TableCell>
                <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {Array.isArray(usersData) && usersData.length > 0 ? (
                usersData.map((row, index) => (
                  <TableRow key={index}>
                    {/* <TableCell  sx={{ borderBottom: 'none' }}>{index+1}</TableCell> */}
                    <TableCell sx={{ borderBottom: 'none' }} style={{ opacity: row.active_status ? 1 : 0.5, }}>{row.first_name}</TableCell>
                    <TableCell sx={{ borderBottom: 'none' }} style={{ opacity: row.active_status ? 1 : 0.5, }}>{row.last_name} </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }} style={{ opacity: row.active_status ? 1 : 0.5, }}>{row.email} </TableCell>
                    <TableCell sx={{ borderBottom: 'none' }} style={{ opacity: row.active_status ? 1 : 0.5, }}>
                      {Array.isArray(row.roles) ? row.roles.map(role => role.name).join(", ") : ""}
                    </TableCell>
                    <TableCell sx={{ borderBottom: 'none', padding: '4px 8px', ml: 5, display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 0 }} >
                      {userConfig.update && (
                        <CustomTooltip title="Edit/View" style={{
                          cursor: row.active_status ? 'pointer' : 'not-allowed',
                          pointerEvents: row.active_status ? 'auto' : 'none',
                          opacity: row.active_status ? 1 : 0.5,
                        }}>
                          <IconButton
                            onClick={() => handleUserActions("editUser", row.id)} sx={{ backgroundColor: '#F2F2F2', color: '#4F63BE', mt: 1, mr: 2, p: 1, '&:hover': { backgroundColor: '#D9D9D9' }, }}
                            style={{
                              cursor: row.active_status ? 'pointer' : 'not-allowed',
                              pointerEvents: row.active_status ? 'auto' : 'none',
                              opacity: row.active_status ? 1 : 0.5,
                            }}
                          >
                            <BorderColorOutlinedIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </CustomTooltip>
                      )}
                      <CustomTooltip title={row.active_status ? "Deactivate":"Activate"}>
                        <IconButton onClick={() => handleUserActions("deactivate", row.id, row.active_status)} sx={{ backgroundColor: '#F2F2F2', color: '#4F63BE', opacity: 1, mt: 1, mr: 2, p: 1, '&:hover': { backgroundColor: '#D9D9D9' }, }}
                        >
                          <PersonOffOutlinedIcon sx={{ fontSize: 14 }} />
                        </IconButton>
                      </CustomTooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell colSpan={5} sx={{ textAlign: "center" }}>No data available.</TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignContent='center'
        sx={{
          width: '100%',
          position: 'relative',
          mt: 2,
          mb: 2,
          alignItems: "center",
          border: '0px solid #ECEEF6',
        }}>
        <CustomPagination
          totalPages={totalPages}
          curPage={currentPage}
          onPageChange={handlePageChange}
        ></CustomPagination>
      </Box>
    </Grid>
  );
};

export default UsersList;
