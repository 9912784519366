import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from '@mui/icons-material/Close';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const EditHolidayModal = ({ open, selectedDate, info, isRecurring, handleClose, saveHoliday, removeHoliday, }) => {

    const [customInfo, setCustomInfo] = useState("");
    const [recurring, setRecurring] = useState(false);

    useEffect(() => {
        setRecurring(isRecurring);
        setCustomInfo(info ?? "");

    }, [isRecurring, info]);
    return (
        <div>
            <Modal open={open} onClose={handleClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    height: "35%",
                    width: "30%",
                    borderRadius: 2,
                }}
                >
                    <Typography sx={{ fontFamily: "Inter", fontWeight: "600", fontSize: "18px" }}>
                        Date Settings
                    </Typography>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 20,
                            right: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Grid container sx={{ display: "flex", flexDirection: "column", rowGap: 3, mt: 3 }}>
                        <Grid item sx={{ display: "flex", gap: 2 }}>
                            <Typography sx={{ width: "100px" }}>
                                Description:
                            </Typography>
                            <TextField
                                multiline
                                minRows={2}
                                maxRows={4}
                                sx={{
                                    width: "75%",
                                    height: "100%",
                                    "& .MuiInputBase-root": {
                                        height: "100%",
                                    },
                                    "& .MuiInputBase-input": {
                                        height: "100%",
                                        resize: "vertical",
                                        fontWeight: "400"
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#C4C4C4 !important"
                                    },
                                }}
                                name="description"
                                variant="outlined"
                                onChange={(e) => setCustomInfo(e.target.value)}
                                defaultValue={info ?? ""}
                            />
                        </Grid>
                        <Grid item sx={{ display: "flex", gap: 2 }}>
                            <Typography sx={{ width: "100px" }}>
                                Perpetual:
                            </Typography>
                            <Checkbox checked={recurring} onClick={(e) => {
                                setRecurring((recurring) => !recurring);
                            }} />
                        </Grid>
                        <Grid item sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>

                            <Button
                                variant="contained"
                                sx={{
                                    width: '100px',
                                    height: '40px',
                                    borderRadius: '24px',
                                    backgroundColor: '#7398C7',
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                    marginLeft: '8px',
                                    '&:hover': {
                                        backgroundColor: '#7398C7',
                                    },
                                    '& .MuiButton-startIcon': {
                                        borderRadius: '50%',
                                        padding: '0px',
                                    },
                                }}
                                onClick={() => removeHoliday(selectedDate)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                sx={{
                                    width: '100px',
                                    height: '40px',
                                    borderRadius: '24px',
                                    backgroundColor: '#4F63BE',
                                    boxShadow: 'none',
                                    textTransform: 'none',
                                    '& .MuiButton-startIcon': {
                                        borderRadius: '50%',
                                        padding: '0px',
                                    },
                                }}
                                onClick={() => saveHoliday(selectedDate, customInfo, recurring)}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    )

}

export default EditHolidayModal