// sagas/rootSaga.js

import { all } from 'redux-saga/effects';
import authSaga, { watchReAuthenticate, watchRegister, watchResetPassword, watchSetNewPassword, 
    watchSocialMediaLogin,watchLogoutSaga,watchPartners } from '../sagas/authSaga'; // Import auth sagas
import { watchTokenExpiration } from '../sagas/watchTokenExpiration'; // Import token expiration saga

export default function* rootSaga() {
    console.log("rootSaga .... root SAGA");
    yield all([
        authSaga(),              // This will handle login and refresh token sagas\
        watchLogoutSaga(),
        watchRegister(),         // This will handle the registration saga
        watchTokenExpiration(),  // This will handle the token expiration
        watchReAuthenticate(),   // This will handle the re-authentication
        watchResetPassword(),
        watchSetNewPassword(),
        watchSocialMediaLogin(),
        watchPartners(),
        // Add more sagas if needed
    ]);
}
