import React from 'react';
import { AlertCircle } from 'lucide-react';
import DatePicker from "react-datepicker";
import { ReactComponent as SystemSettingsIcon } from "../../../../../../../assetts/images/systemSettings.svg";
import CustomDropdown from '../../../../../../../components/reusableComponents/CustomDropdown/CustomDropdown';
import "react-datepicker/dist/react-datepicker.css";
import styles from './TransformationParam.module.css';

const TransformationParam = ({ param, value, onChange, error, stepId }) => {
  const shared = {
    name: `${stepId}-${param.name}`,
    'aria-invalid': !!error,
    className: `${styles.input} ${error ? styles.error : ''}`
  };

  const handleChange = (newValue) => {
    onChange(param.name, newValue);
  };

  const renderInput = () => {
    if (param.type === 'date') {
      return (
        <div className={styles.datePickerContainer}>
          <DatePicker
            selected={value ? new Date(value) : null}
            onChange={(date) => handleChange(date)}
            className={`${styles.dateInput} ${error ? styles.error : ''}`}
            dateFormat="yyyy-MM-dd"
            placeholderText={param.placeholder || "Select date"}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Today"
            isClearable
            name={`${stepId}-${param.name}`}
            {...param.validation}
          />
          {param.icon && <SystemSettingsIcon className={styles.dateIcon} />}
        </div>
      );
    }

    if (param.type === 'select') {
      return (
        <CustomDropdown
          items={param.options}
          value={value}
          onChange={handleChange}
          placeholder={param.placeholder || `Select ${param.label}`}
          style={{ height: "2.375rem" }}
          multiSelect={param.multiSelect}
          {...shared}
          {...param.validation}
        />
      );
    }

    return (
      <input
        type={param.type || 'text'}
        value={value ?? ''}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={param.placeholder}
        {...shared}
        {...param.validation}
      />
    );
  };

  return (
    <div className={styles.paramWrapper}>
      <label className={styles.label}>
        {param.label}
        {param.validation?.required && <span className={styles.required}>*</span>}
      </label>
      <div className={styles.inputContainer}>
        {renderInput()}
        {error && (
          <div className={styles.errorMessage}>
            <AlertCircle size={14} className={styles.errorIcon} />
            <span>{error}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransformationParam;