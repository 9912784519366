import React from 'react';
import DatePicker from 'react-datepicker';
import { getValidationAttributes } from '../../../../../utils/validationUtils';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DateTimeField.module.css';

const DateTimeField = ({ field, value, onChange, error, style = {} }) => {
    const validationAttributes = getValidationAttributes(field);
    const selectedDateTime = value ? new Date(value) : null;

    return (
        <div className={styles.fieldContainer} style={style.container}>
            <label htmlFor={field.id} className={styles.label} style={style.label}>
                {field.label}
            </label>
            <DatePicker
                id={field.id}
                selected={selectedDateTime}
                onChange={(date) => onChange(date ? date.toISOString() : '')}
                className={styles.dateTimeInput}
                placeholderText="Select date and time"
                dateFormat="MM/dd/yyyy h:mm aa"
                showTimeInput
                timeFormat="hh:mm aa"
                timeInputLabel="Time:"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                todayButton="Today"
                timeIntervals={1}
                isClearable={!field.required}
                required={field.required}
                {...validationAttributes}
                customInput={<input style={style.input} />}
            />
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default DateTimeField;