// api/mappingTypeApi.js
import { fetchApi } from "../utils/apiUtils";

export const fetchValidationTypes = async () => {
  return fetchApi('validation/list', {
    method: 'GET',
    requiresAuth: true
  });
};

export const fetchTransformationTypes = async () => {
  return fetchApi('transformation/list', {
    method: 'GET',
    requiresAuth: true
  });
};