import React from 'react'
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';

const DashboardCards = ({cardData}) => {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
    {cardData.map((card, index) => (
      <Grid item key={index} xs={12} sm={6} md={6} lg={3}>
        <Card sx={{ display: 'flex', alignItems: 'center', boxShadow:"none",border: "1px solid #D9D9D9",borderRadius:"16px"}}>
          <Box sx={{ mr: 2,p:2,minWidth:"50px" , display:"flex",justifyContent:"center",background: "#F5F9FF"}}>{card.icon}</Box>
          <CardContent sx={{p:2}}>
            <Typography variant="h5" component="div" sx={{fontWeight:"700",fontSize:"28px",lineHeight:"100%",letterSpacing:"1%",color:"#232323"}}>
              {card.count}
            </Typography>
            <Typography color="text.secondary" sx={{fontSize:"14px",fontWeight:"400",lineHeight:"100%",letterSpacing:"1%",color:"#5e5e5e"}}>{card.label}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
  )
}

export default DashboardCards