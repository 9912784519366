import { fetchApi } from "../utils/apiUtils";

const BASE_URL = process.env.REACT_APP_API_URL+ '/api/';

// API call function using fetch
export const fetchPartnersList = async (accessToken, page) => {
  return fetchApi('partner/list/?page=' + page,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Explicitly add token
      },
      requiresAuth: true
    }
  );
}
export const fetchPartnersApi = async(accessToken, nextPageUrl)=> {
  
  const response = await fetchApi(nextPageUrl, 
    {
      method:'GET', 
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`, // Explicitly add token
    },
     requiresAuth:true
    }
  );
  console.log('fetchPartnersApi ...  response ',response)
  return await response;
}
export const fetchPartnersFullListApi = async(accessToken)=> {
  
  const response = await fetchApi(`partner/full_list/`, 
    {
      method:'GET', 
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`, // Explicitly add token
    },
     requiresAuth:true
    }
  );
  console.log('fetchPartnersApi ...  response ',response)
  return await response;
}
export const fetchPartnersDetailApi = async(accessToken, id)=> {
  
  const response = await fetchApi('partner/details/'+id ,
    {
      method:'GET', 
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Explicitly add token
      },
      requiresAuth:true
    }
  );
  console.log('fetchPartnersApi ...  response ',response)
  return await response;
}
export const fetchUserDetailApi = async(accessToken, id)=> {
  
return await fetchApi(`user/detail/${id}` ,
    {
      method:'GET', 
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Explicitly add token
      },
      requiresAuth:true
    }
  );

}

export const fetchCustomersApiDropdown = async(accessToken, partnerId,nextPageUrl)=> {
  const response = await fetchApi(nextPageUrl, 
    {
      method:'POST', 
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Explicitly add token
      },
      body: partnerId ? { partner_id: partnerId } : {}, 
      requiresAuth:true
    }
  );
  return await response
}
export const fetchCustomersApi = async(accessToken, partnerId, page)=> {
   return await fetchApi(`partner/customer-list/?page=${page}`, 
    {
      method:'POST', 
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Explicitly add token
      },
      body: partnerId ? { partner_id: partnerId } : {}, 
      requiresAuth:true
    }
  );

}
export const CustomerEditApi= async(accessToken,id)=>{
   return await fetchApi(`partner/customer/detail/${id}/`,{
    method:'GET',
    headers:{
      'Content-Type':'application/json',
      Authorization:`Bearer ${accessToken}`
    },
    requiresAuth:true

  })
}
export const hanldeUserDeactivate= async(accessToken,id,activeStatus)=>{
  return await fetchApi(`user/activate/`,{
   method:'POST',
   headers:{
     'Content-Type':'application/json',
     Authorization:`Bearer ${accessToken}`
   },
   body:{
    "user_list" : [Number(id)],
    active_status: activeStatus === true ? "false" : "true"
  },
   requiresAuth:true

 })
}
export const handleCustomerUserFetch= async(accessToken,id,page)=>{
  const response= await fetchApi(`user/list/?page=${page}`,{
    method:'POST',
    headers:{
      'Content-Type':'application/json',
      Authorization:`Bearer ${accessToken}`,
     
    },
    body:{
      "customer_id":`${id}`
    },
    requiresAuth:true

  })
  
  return await response
}
export const handlePartnerUserFetch= async(accessToken,id,page)=>{
  const response= await fetchApi(`user/list/?page=${page}`,{
    method:'POST',
    headers:{
      'Content-Type':'application/json',
      Authorization:`Bearer ${accessToken}`,
     
    },
    body:{
      "partner_id":`${id}`
    },
    requiresAuth:true

  })
  
  return await response
}
export const fetchUsersApi = async(accessToken, page,filterQuery)=> {
  const payload={
    partner_id:filterQuery.partner,
    name : filterQuery.name,
    user_type:filterQuery.usertype,
    customer_id:filterQuery.customer
}
console.log("payload------------",payload)
  const response = await fetchApi(`user/list/?page=${page}`, 
    {
      method:'POST', 
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Explicitly add token
      },
      body: payload,
     requiresAuth:true
    }
  );
  return await response
}
export const fetchUserRolesApi = async(accessToken)=> {
  const response = await fetchApi('role/list/with-group/', 
    {
      method:'GET', 
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Explicitly add token
      },
      requiresAuth:true
    }
  );
  return await response
}
export const fetchUserTypesApi = async(accessToken)=> {
  const response = await fetchApi('user-type/list/', 
    {
      method:'GET', 
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`, // Explicitly add token
      },
      requiresAuth:true
    }
  );
  return await response
}

export const createPartnerApi = async (accessToken, partnerData) =>{
    try {
      const response = await fetchApi('partner/create/', 
        {
          method:'POST', 
          // headers: {
          //   'Content-Type': 'application/json',
          //   Authorization: `Bearer ${accessToken}`, // Explicitly add token
          // },
          body: partnerData,
          requiresAuth:true
        }
      );
      console.log('API response:', response);
      // if (!response.ok) {
      //     throw new Error(`Error: ${response.status} - ${response.statusText}`);
      // }
    
      
      return response;
        } catch (error) {
        console.error('Error submitting CustomerData data:', error);
        return error;
        }

}
export const editPartnerApi = async (accessToken, partnerData,partnerId) =>{
    try {
    console.log(" accessToken ",accessToken ,' partnerData ',partnerData, ' partnerId ',partnerId)

      const response = await fetchApi(`partner/update/${partnerId}/`,
        {
          method:'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
         
          body: partnerData, // Convert the data to JSON
          requiresAuth:true
        }
      );
      console.log('API response:', response);
      // if (!response.ok) {
      //     throw new Error(`Error: ${response.status} - ${response.statusText}`);
      // }
      const result = response;
      
      return result;
        } catch (error) {
      return error
      } finally {
        // setLoading(false); // Hide loading indicator
      }

}

export const createCustomerApi = async (accessToken, CustomerData) =>{
    try {
    console.log('accessToken ',accessToken, '  Customer Data .. ',CustomerData)
        const response = await fetchApi(`customer/create/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: CustomerData, // Convert the data to JSON
          requiresAuth:true
        });
  
        // if (!response.ok) {
        //   throw new Error(`Error: ${response.status} - ${response.statusText}`);
        // }
        const result = response;
        // const result = await response.json();
        console.log('API Response:', result);
        return result;
      } catch (error) {
        return error

      } finally {
        // setLoading(false); // Hide loading indicator
      }

}
export const UpdateCustomerApi = async (accessToken, CustomerData,id) =>{
    // try {
    console.log('accessToken ',accessToken, '  Customer Data .. ',CustomerData)
    return await fetchApi(`customer/update/${id}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: CustomerData, // Convert the data to JSON
          requiresAuth:true
        });

        // if (!response.ok) {
        //   throw new Error(`Error: ${response.status} - ${response.statusText}`);
        // }
      //   const result = response;
      //   // const result = await response.json();
      //   console.log('API Response:', result);
      //   return result;
      // } catch (error) {
      //   return error

      // } finally {
      //   // setLoading(false); // Hide loading indicator
      // }

}
export const createUsersApi = async (accessToken, UsersData) =>{
    try {
    console.log('accessToken ',accessToken, '  Users Data .. ',UsersData)
        const response = await fetchApi(`user/create/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: UsersData, // Convert the data to JSON
          requiresAuth:true
        });
  
        // if (!response.ok) {
        //   throw new Error(`Error: ${response.status} - ${response.statusText}`);
        // }
  
        // const result = await response.json();

        return response;
    } catch (error) {
         console.error('Error submitting User data:', error);
         return error
      } finally {
    //   setLoading(false); // Hide loading indicator
    }

}
export const updateUsersApi = async (accessToken, UsersData,id) =>{
    try {
    console.log('accessToken ',accessToken, '  Users Data .. ',UsersData)
        const response = await fetchApi(`user/update/${id}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: UsersData, // Convert the data to JSON
          requiresAuth:true
        });
  

        return response;
    } catch (error) {
      return error
        
      } finally {
    //   setLoading(false); // Hide loading indicator
    }

}
export const updateUsersRolesApi = async (accessToken, rolesData) =>{
    try {
    console.log('accessToken ',accessToken, '  Users Data .. ',rolesData)
        const response = await fetchApi(`user/role/create/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: rolesData, // Convert the data to JSON
          requiresAuth:true
        });
  
        // if (!response.ok) {
        //   throw new Error(`Error: ${response.status} - ${response.statusText}`);
        // }
  
        // const result = await response.json();
        const result = response;
        console.log('API Response:', result);
        return result;
    } catch (error) {
         console.error('Error submitting User data:', error);
         return error
      } finally {
    //   setLoading(false); // Hide loading indicator
    }

}