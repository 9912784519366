import createPermissionConfig from "../config/createPermissionConfig";

export const decodePermission = async(permissions)=>{

    let decodedPermissions
    try {
      if (typeof permissions === "string" && permissions.trim() !== "") {
          const decodedString = atob(permissions); // Decode the Base64 string
          decodedPermissions = JSON.parse(decodedString); // Parse the JSON
          console.log("Decoded Permissions:", decodedPermissions);
          const permissionConfig = createPermissionConfig(decodedPermissions);
          return permissionConfig;
          
      } else {
        console.warn("Permissions string is blank or null.");
      }
    } catch (error) {
      console.error("Failed to decode or parse permissions:", error);
    }
}