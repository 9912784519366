import React from "react";
import Grid from '@mui/material/Grid';
import DatePicker from "react-datepicker";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { startOfYear, format as formatDate } from "date-fns";
import theme from "../../../theme";

const CalendarForm = ({ calendarData, setCalendarData, handleSubmit, countries, changeHoliday, changeWeekend, weekends, errors }) => {
    const handleChange = (event) => {
        const { name, value, checked, type } = event.target;
        if (type === "checkbox") {
            setCalendarData((prev) => ({
                ...prev,
                [name]: checked
            }));
        } else {
            setCalendarData((prev) => ({
                ...prev,
                [name]: value
            }));
        }

    }

    const handleDateChange = (field, newDate) => {
        setCalendarData((prev) => ({
            ...prev,
            [field]: formatDate(newDate, "yyyy-MM-dd")
        }));
    };
    const CustomDatePicker = ({ selectedDate, onChange, name, minDate, helperText }) => (
        <DatePicker
            selected={selectedDate}
            onChange={(date) => onChange(name, date)}
            dateFormat="yyyy-MM-dd"
            minDate={minDate}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            todayButton="Today"
            isClearable={false}
            customInput={
                <TextField
                    sx={{
                        height: "48px",
                        "& .MuiInputBase-root": { height: "100%" },
                        "& .MuiInputBase-input": { height: "100%" }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ "& .MuiIconButton-root": { padding: "1px" } }}>
                                <IconButton size="small">
                                    <CalendarTodayIcon fontSize="small" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    error={!!helperText}
                    helperText={helperText}
                />
            }
        />
    );


    return (<Grid item xs={6} rowGap={2} sx={{ height: "100px" }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", mb: 4, alignItems: "center" }}>
            <Typography>
                Country:
            </Typography>
            <Select
                sx={{
                    width: "75%",
                    height: "48px",
                }}
                onChange={(e) => { handleChange(e); changeHoliday(e); }}
                value={calendarData.country}
                name='country'
            >
                {countries.map((country, index) => (
                    <MenuItem key={index} value={country.id}>
                        {country.name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", mb: 4, alignItems: "flex-start" }}>
            <Typography>
                Name: <span style={{ color: theme.palette.error.main }}>*</span>
            </Typography>
            <TextField
                sx={{
                    width: "75%",
                    height: "48px",
                    "& .MuiInputBase-root": {
                        height: "100%",
                    },
                    "& .MuiInputBase-input": {
                        height: "100%",
                    },
                }}
                name="name"
                variant="outlined"
                onChange={handleChange}
                value={calendarData.name}
                error={true}
                helperText={errors.name}
            />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", mb: 4, alignItems: "center" }} >
            <Grid item xs={4}>
                <Typography>
                    Start Date:
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", gap: 1 }} >
                <CustomDatePicker
                    selectedDate={calendarData.start_date}
                    onChange={handleDateChange}
                    name="start_date"
                    helperText={errors.start_date}
                />
                {/* <DateField field={{ required: true }} value={calendarData.start_date} onChange={(e) => handleDateChange("start_date", e)} error={errors.start_date} /> */}
                <Typography sx={{ width: "200px", pl: "10px" }}>
                    End Date:
                </Typography>
                <CustomDatePicker
                    selectedDate={calendarData.end_date}
                    onChange={handleDateChange}
                    name="end_date"
                    minDate={calendarData.start_date}
                    helperText={errors.end_date}
                />
                {/* <DateField field={{ required: true }} value={calendarData.end_date} onChange={(e) => handleDateChange("end_date", e)} error={errors.end_date} /> */}
            </Grid>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", mb: 4, alignItems: "center" }}>
            <Typography>
                Weekend:
            </Typography>

            <Select
                sx={{
                    width: "75%",
                    height: "48px",
                }}
                multiple
                value={calendarData.weekend}
                onChange={changeWeekend}
                renderValue={(selected) =>
                    selected
                        .map((value) => weekends.find((day) => day === value))
                        .join(', ')
                }

            >
                {weekends.map((day) => (
                    <MenuItem key={day} value={day}>
                        <Checkbox checked={calendarData.weekend.includes(day)} />
                        <ListItemText primary={day} />
                    </MenuItem>
                ))}
            </Select>

        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", mb: 4, alignItems: "center" }} >
            <Grid item xs={3}>
                <Typography>
                    No End Date
                </Typography>

            </Grid>
            <Grid item xs={9} sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 2 }}>
                <Checkbox name="no_end_date" checked={calendarData.no_end_date} onClick={handleChange} />
                <Typography>
                    Recurring
                </Typography>
                <Checkbox name="recurring" checked={calendarData.recurring} onClick={handleChange} />
            </Grid>


        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between", mb: 4, alignItems: "flex-start" }} >
            <Typography>
                Description: <span style={{ color: theme.palette.error.main }}>*</span>
            </Typography>
            <TextField
                multiline
                minRows={4}
                maxRows={10}
                sx={{
                    width: "75%",
                    height: "75%",
                    "& .MuiInputBase-root": {
                        height: "100%",
                    },
                    "& .MuiInputBase-input": {
                        height: "100%",
                        resize: "vertical",
                        fontWeight: "400"
                    }
                }}
                name="description"
                variant="outlined"
                onChange={handleChange}
                value={calendarData.description}
                error={!!errors.description}
                helperText={errors.description} />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mb: 4, alignItems: "flex-start" }} >
            <Button
                variant="contained"
                sx={{
                    width: "156px",
                    height: "48",
                    fontSize: "16px",
                    borderRadius: '32px',
                    backgroundColor: '#4F63BE',
                    boxShadow: 'none',
                    textTransform: 'none',
                    '&:hover': {
                        backgroundColor: '#4F63BE',
                    },
                }}
                onClick={handleSubmit}
            >
                Save
            </Button>
        </Grid>
    </Grid>)
}

export default CalendarForm;
