import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import profile from '../../../assetts/images/partnerdashboard/profile.svg'
import iuconsumed from '../../../assetts/images/partnerdashboard/iuconsumed.svg'
import pending from '../../../assetts/images/partnerdashboard/pending.svg'
import licenses from '../../../assetts/images/partnerdashboard/licenses.svg'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
import DonutChart from '../../../components/Charts/DonutChart';
import BarChart from '../../../components/Charts/BarChart';
import './customercard.css'
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement);

const dataArray = [
  {
    title: "Customer 1",
    stats: [
      { icon: profile, label: "No.of Users", count: 1200 },
      { icon: iuconsumed, label: "No.of IU Consumed", count: 345 },
      { icon: licenses, label: "No.of Licenses", count: 89 },
      { icon: pending, label: "No.of Pending Payments", count: 89 }
    ],
    barCharts: [
      {
        title: "Premium Workflows", data: [
          { name: 'Success', value: 7, color: "#4F63BE" },
          { name: 'Failure', value: 3, color: "#828282" },

        ]
      },
      {
        title: "Freemium Workflows", data: [
          { name: 'Success', value: 2, color: "#4F63BE" },
          { name: 'Failure', value: 2, color: "#828282" },

        ]
      }
    ],
    pieChart: {
      title: "Market Share",
      data: [40, 30, 30],
      labels: ['Product A', 'Product B', 'Product C'],
      colors: ['#0088FE', '#00C49F', '#FFBB28']
    }
  },
  {
    title: "Customer 2",
    stats: [
      { icon: profile, label: "No.of Users", count: 120 },
      { icon: iuconsumed, label: "No.of IU Consumed", count: 375 },
      { icon: licenses, label: "No.of Licenses", count: 89 },
      { icon: pending, label: "No.of Pending Payments", count: 89 }
    ],
    barCharts: [
      {
        title: "Premium Workflows", data: [
          { name: 'Success', value: 7, color: "#4F63BE" },
          { name: 'Failure', value: 3, color: "#828282" },

        ]
      },
      {
        title: "Freemium Workflows", data: [
          { name: 'Success', value: 2, color: "#4F63BE" },
          { name: 'Failure', value: 2, color: "#828282" },

        ]
      }
    ],
    pieChart: {
      title: "Market Share",
      data: [40, 30, 30],
      labels: ['Product A', 'Product B', 'Product C'],
      colors: ['#0088FE', '#00C49F', '#FFBB28']
    }
  },
  {
    title: "Customer 3",
    stats: [
      { icon: profile, label: "No.of Users", count: 1200 },
      { icon: iuconsumed, label: "No.of IU Consumed", count: 345 },
      { icon: licenses, label: "No.of Licenses", count: 89 },
      { icon: pending, label: "No.of Pending Payments", count: 89 }
    ],
    barCharts: [
      {
        title: "Premium Workflows", data: [
          { name: 'Success', value: 7, color: "#4F63BE" },
          { name: 'Failure', value: 3, color: "#828282" },

        ]
      },
      {
        title: "Freemium Workflows", data: [
          { name: 'Success', value: 2, color: "#4F63BE" },
          { name: 'Failure', value: 2, color: "#828282" },

        ]
      }
    ]
  },
]

const CustomerDatacard = () => {
  return (

    <Grid container spacing={2}>
      {dataArray.map((data, idx) => (
        <Grid item xs={12} md={12} lg={6} key={idx} sx={{
          '@media (min-width: 2560px)': {
            maxWidth: '33.33%',  // 5 cards per row on 4K screens
          },
        }}>
          <Card sx={{ borderRadius: "16px" }}>
            <CardContent sx={{ backgroundColor: "#f5f9ff", p: 4, '&:last-child': { paddingBottom:"32px" }, }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "600", fontSize: "18px", lineHeight: "100%", letterSpacing: "1%", color: "#000000" }}>{data.title}</Typography>
              <Grid container direction="column" spacing={2} sx={{ p: 2, mt: 2, mb: 2, backgroundColor: "white", gap: 2, borderRadius: "16px", marginLeft: "0px !important", width: "100%" }}>
                {data.stats.map((stat, index) => (
                  <Grid container item key={index} justifyContent="space-between" alignItems="center" sx={{ paddingLeft: "0px !important", paddingTop: "0px !important" }}   >
                    <Grid item sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {stat.icon && <img src={stat.icon} alt='img' />}
                      <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "22px", letterSpacing: "1%", color: "#5e5e5e", pl: 1 }}>
                        {stat.label}
                      </Typography>
                    </Grid>
                    <Typography sx={{ fontWeight: "700", fontSize: "14px", lineHeight: "22px", color: "#E27DDD" }}>{stat.count}</Typography>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2} sx={{ pt: 2, flexWrap: "nowrap", gap: 2, marginLeft: "0px !important", width: "100%" }} className='customer-card-charts'>
                <Grid item xs={12} md={6} sx={{ display: "flex", backgroundColor: "white", borderRadius: "16px", justifyContent: "center", paddingLeft: "0px !important", alignItems: "center" }} className='customer-card-barchart'>
                  <BarChart />
                </Grid>
                <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: "white", borderRadius: "16px" }} className='customer-card-doughnutchart'>
                  <div>
                    <DonutChart activeCount={18} inactiveCount={5} />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export default CustomerDatacard


{/* {data.barCharts.map((chart, index) => (
                    <div key={index} style={{ height: "200px", width: "49%" }}>
                      <Bar
                        data={{
                          labels: chart.data.map(d => d.name),
                          datasets: [{
                            label: chart.title,
                            data: chart.data.map(d => d.value),
                            backgroundColor: chart.data.map(d => d.color),
                          }]
                        }}
                        options={{
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: 'bottom',
                              align: 'center',
                              labels: {
                                usePointStyle: true,
                                pointStyle: 'rect',
                                padding: 20,
                              },
                            },
                          },
                        }}
                        height={150}
                      />
                    </div>
                  ))} */}


{/* <Typography variant="subtitle1" align="center">{data.pieChart.title}</Typography> */ }
{/* <Pie
                      data={{
                        labels: data.pieChart.labels,
                        datasets: [{
                          data: data.pieChart.data,
                          backgroundColor: data.pieChart.colors,
                        }]
                      }}
                      options={{ maintainAspectRatio: false }}
                    /> */}