import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./DetailViewHeader.module.css";
import backArrowRounded from "../../../../assetts/images/leftRoundedArrow.svg";
import { generateBreadcrumbElements } from "../../../../utils/breadcrumbUtils";

const DetailViewHeader = ({title}) => {
  const navigate = useNavigate();
  const breadcrumbs = useSelector((state) => state.breadcrumbs.breadcrumbs);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.titleSection}>
        <h1 className={styles.title}>
          {title || 'No Workflow Data Available'}
        </h1>
        <div className={styles.breadcrumb}>
          {generateBreadcrumbElements(breadcrumbs, navigate)}{" "}
        </div>
      </div>
      <div className={styles.actionsSection}>
        <button className={styles.backButton} onClick={handleBackButtonClick}>
          <img src={backArrowRounded} alt="Back" className={styles.backIcon} />
        </button>
        <button className={styles.actionButton}>Audit Log</button>
      </div>
    </div>
  );
};

export default DetailViewHeader;
