import React from 'react';
import DatePicker from 'react-datepicker';
import { getValidationAttributes } from '../../../../../utils/validationUtils';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DateField.module.css';

const DateField = ({ field, value, onChange, error, style = {} }) => {
    const validationAttributes = getValidationAttributes(field);
    const selectedDate = value ? new Date(value) : null;

    return (
        <div className={styles.fieldContainer} style={style.container}>
            <label htmlFor={field.id} className={styles.label} style={style.label}>
                {field.label}
            </label>
            <DatePicker
                id={field.id}
                selected={selectedDate}
                onChange={(date) => onChange(date ? date.toISOString().split('T')[0] : '')}
                className={styles.dateInput}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select date"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                todayButton="Today"
                isClearable={!field.required}
                required={field.required}
                {...validationAttributes}
                customInput={
                    <input style={style.input} />
                }
            />
            {error && <p className={styles.error}>{error}</p>}
        </div>
    );
};

export default DateField;