import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ activeCount, inactiveCount }) => {
  const data = {
    labels: ['Active', 'Inactive'],
    datasets: [
      {
        data: [activeCount, inactiveCount],
        backgroundColor: ['#7398c7', '#AAE7F0'],
        hoverOffset: 4,
      },
    ],
  };

  const totalCount = activeCount + inactiveCount;

  const options = {
    plugins: {
        legend: {
          position: 'bottom',
          align: 'center',
          labels: {
            usePointStyle: true,
            pointStyle: 'rect',
            padding: 20,
          },
        },
        tooltip: { enabled: false },
        datalabels: {
            color: '#5e5e5e',
            backgroundColor: '#fff',
           borderRadius: 50,
            font: {
              weight: 'bold',
              size: 12,
            },
            padding: 9,
            boxShadow: '0px 0px 10px 0px #00000033',
            formatter: (value) => value < 10 ? `0${value}` : value,
            anchor: 'end',
            align: 'end',
            offset: -20,
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.1)',
          },
      },
      cutout: '70%',
      maintainAspectRatio: false
  };

  return (
   <div style={{ textAlign: 'center', position: 'relative', width: '100%',minHeight:"250px", margin: '0 auto' }}>
      <Doughnut data={data} options={options} plugins={[ChartDataLabels]} width={300} height={200} />
      <div style={{
        position: 'absolute',
        top: '42%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '35px',
        fontWeight: '700',
        color:"#464649"
      }}>
            <div style={{ color: '#5e5e5e', fontSize: '10px',fontWeight:"400" }}>Total Count</div>
           
        {totalCount}
      </div>
    </div>
  );
};

export default DonutChart;
