import { Box, Button, IconButton, Modal, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PartnerForm from '../../components/user-management/PartnerForm';
import PartnersList from '../../components/user-management/PartnersList';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPartnersList } from '../../api/usermanagmentApi';
import Preloader from '../../components/common/Preloader';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { getPartnerCustomerDetails, getPartnerDetail, getpartnerUsersDetails } from '../../components/user-management/services/partnerService';
import DynamicTable from '../../components/reusableComponents/DynamicTable/DynamicTable';
import CustomAlert from '../../components/reusableComponents/CustomAlert/CustomAlert';

const Partner = () => {
    const dispatch = useDispatch();
    const [usersData, setUsersData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: "", type: "info" });
    const [isModalLoading, setIsModalLoading] = useState(false)
    const [modalType, setModalType] = useState()
    const [partnerId, setPartnerId] = useState(null);
    const [selectedPartnerDetail, setSelectedPartnerDetail] = useState([]);
    let accessToken = useSelector((state) => state.auth.accessToken);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);

    const partnerConfig = useSelector((state) => state.auth.userDetails?.permissionConfig?.partner);

    // const userDetails = useSelector((state) => state.auth.userDetails);
    // const usertype = useSelector((state) => state.auth.userDetails?.user_type);

    const [partnerUsers, setPartnerUsers] = useState({
        data: [],
        totalCount: 0,
        nextPage: null,
        prevPage: null,
        currentPage: 1, // Start with page 1
        totalPages: 0, // Will be calculated dynamically
        currentPartnerId: null,
        loading: false

    });
    const [partnerCustomers, setPartnerCustomers] = useState({
        data: [],
        totalCount: 0,
        nextPage: null,
        prevPage: null,
        currentPage: 1, // Start with page 1
        totalPages: 0, // Will be calculated dynamically
        currentPartnerId: null,
        loading: false

    });
    const handleOpen = () => {
        setIsModalOpen(true);
        setPartnerId(null);
        setModalType("createPartner");
        setSelectedPartnerDetail({ ...blankPartnerDetail });
    }
    const handleClose = () => setIsModalOpen(false);

    const blankPartnerDetail = {
        name: '',
        business_name: '',
        url: '',
        email_id: '',
        number_of_employees: '',
        billing_address: {
            address_line1: '',
            address_line2: '',
            city: '',
            state: '',
            country: '',
            zip: '',
            phone_number: ''
        }
    };

    useEffect(() => {
        getPartnersData(); // Fetch templates when the component mounts
    }, [currentPage])

    const getPartnersData = async () => {
        setLoading(true)
        try {
     
            const data = await fetchPartnersList(accessToken, currentPage); // Call the API to fetch templates
            if (data.results != undefined) {
                setUsersData(data.results); // Set the 'results' array from the response
                setTotalPages(Math.ceil(data.count / 10))
            }
            setLoading(false); // Set loading to false when data is fetched
        } catch (error) {
            console.error('Failed to fetch templates:', error);
            setLoading(false); // Set loading to false even on error
        }
    };

    useEffect(() => {
        if (partnerCustomers.currentPartnerId) {
            getPartnerCustomerDetails({
                accessToken,
                id: partnerCustomers.currentPartnerId,
                page: partnerCustomers.currentPage,
                setPartnerCustomers,
                setIsModalLoading,
                setIsModalOpen,
                setModalType
            });
        }
    }, [partnerCustomers.currentPartnerId, partnerCustomers.currentPage]); // Dependency array

    useEffect(() => {
        if (partnerUsers.currentPartnerId) {
            getpartnerUsersDetails({
                accessToken,
                id: partnerUsers.currentPartnerId,
                page: partnerUsers.currentPage,
                setPartnerUsers,
                setIsModalLoading,
                setIsModalOpen,
                setModalType,
            });
        }
    }, [partnerUsers.currentPartnerId, partnerUsers.currentPage]); // Dependency array

    const handlePartnerActions = async (type, id) => {
        switch (type) {
            case 'editPartner':
                console.log("type", id)
                setPartnerId(id)
                setIsModalLoading(true)
                setModalType("editPartner")
                setIsModalOpen(true)
                getPartnerDetail({ accessToken, id, setSelectedPartnerDetail, setLoading, setIsModalLoading })
                break;
            case 'viewPartnerUsers':
                setPartnerUsers((prev) => ({
                    ...prev,
                    currentPartnerId: id,
                    currentPage: 1
                }))
                getpartnerUsersDetails({ accessToken, id, page: 1, setPartnerUsers, setIsModalLoading, setIsModalOpen, setModalType, });
                break;
            case 'viewPartnerCustomer':
                setIsModalLoading(true)
                setModalType("viewpartnercustomers")
                setIsModalOpen(true)
                setPartnerCustomers((prev) => ({
                    ...prev,
                    currentPartnerId: id,
                    currentPage: 1
                }))
                getPartnerCustomerDetails({
                    accessToken, id, page: 1, setPartnerCustomers, setIsModalLoading, setIsModalOpen, setModalType
                }
                );
                break;
            default:
                console.log("unknown button type", type)
                break;
        }
    }

    return (
        <Box sx={{ p: 5, mt: 8 }}>
            <CustomAlert
                open={alert.open}
                message={alert.message}
                type={alert.type}
                onClose={() => setAlert({ ...alert, open: false })}
            />
            <Grid2 item xs={12} sx={{ backgroundColor: 'transparent', borderBottom: 'none', display: "flex", justifyContent: "space-between" }}>
                <Paper elevation={1} sx={{
                    padding: 0,
                    backgroundColor: 'transparent',
                    border: 'none', // Ensures no border
                    boxShadow: 'none', // Removes shadow
                }}>
                    <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: 600, color: '#000000' }}>
                        Partners List
                    </Typography>

                </Paper>
                {partnerConfig?.create && (
                    <Button
                        variant="contained"
                        sx={{
                            alignItems: 'left', width: '114px', height: '32px', fontSize: "12px", padding: "0px", borderRadius: '24px', backgroundColor: '#7398c7', boxShadow: 'none', textTransform: 'none', '& .MuiButton-startIcon': { borderRadius: '50%', padding: '0px', }, '&:hover': { backgroundColor: '#7398C7', },
                        }}
                        startIcon={<AddCircleOutlinedIcon sx={{ borderRadius: '50%' }} />}
                        onClick={handleOpen} // Open drawer on click
                    >
                        Create New
                    </Button>
                )}
            </Grid2>

            <Box className="buttonGroup" display="flex" sx={{ pl: 2 }}>
                {
                    loading ? (
                        <Preloader />
                    ) :
                        (
                            <PartnersList
                                partnersData={usersData}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                loading={loading}
                                handlePartnerActions={handlePartnerActions}
                            />
                        )
                }
            </Box>

            <Modal open={isModalOpen} onClose={handleClose}>
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, height: '90vh', width: '60vw', borderRadius: 2, overflowY: "auto",
                }}
                >
                    <Typography variant="h6" component="h2" sx={{ fontFamily: "inter", fontWeight: "600", fontSize: "24px", lineHeight: "29.05px", mb: 4 }}>
                        {modalType === "viewpartnercustomers" && "Customers"}
                        {modalType === "editPartner" && " Partner Information"}
                        {modalType === "createPartner" && " Create Partner"}
                        {modalType === "viewpartnerusers" && "Partner Users"}
                    </Typography>
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    {isModalLoading ? (
                        <Preloader />
                    ) : (<>
                        {modalType === "viewpartnercustomers" ? (
                            partnerCustomers.totalCount > 0 ? (
                                <DynamicTable
                                    columns={[

                                        { field: "name", headerName: "Name" },
                                        { field: "business_name", headerName: "Business Name" },
                                        { field: "email_id", headerName: "Email" },
                                        { field: "created_at", headerName: "Created At" },
                                    ]}
                                    data={partnerCustomers.data}
                                    totalItems={partnerCustomers.totalCount}
                                    totalPages={partnerCustomers.totalPages}
                                    currentPage={partnerCustomers.currentPage}
                                    handlePageChange={(selectedPage) => { setPartnerCustomers((prev) => ({ ...prev, currentPage: selectedPage })) }}
                                />
                            ) : (
                                <div>No data available.</div>
                            )
                        ) : modalType === "viewpartnerusers" ? (

                            partnerUsers.totalCount > 0 ? (
                                <DynamicTable
                                    columns={[

                                        { field: "first_name", headerName: "First Name" },
                                        { field: "last_name", headerName: "Last Name" },
                                        { field: "email", headerName: "Email" },

                                    ]}
                                    data={partnerUsers.data}
                                    totalItems={partnerUsers.totalCount}
                                    totalPages={partnerUsers.totalPages}
                                    currentPage={partnerUsers.currentPage}
                                    handlePageChange={(selectedPage) => { setPartnerUsers((prev) => ({ ...prev, currentPage: selectedPage })) }}
                                />
                            ) : (
                                <div>No data available.</div>
                            )

                        ) : (
                            <PartnerForm
                                partner={selectedPartnerDetail}
                                partnerId={partnerId}
                                setIsModalOpen={setIsModalOpen}
                                getPartnersData={getPartnersData}
                                setAlert={setAlert}
                                onSave={() => {
                                    setSelectedPartnerDetail(null); // Reset to list mode after save
                                    // fetchUsersData(); // Refresh data
                                }}
                            />
                        )}
                    </>
                    )}
                </Box>


            </Modal>
        </Box>
    );
};

export default Partner;
