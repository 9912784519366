import React, { useState, useCallback, useEffect } from "react";
import { AlertCircle, ArrowLeftRight, Info } from "lucide-react";
import MappingForm from './MappingForm/MappingForm'
import styles from "./MappingAndTransformationSection.module.css";

const MappingAndTransformationSection = ({ node, nodes, edges, onSave }) => {
  const [currentNode, setCurrentNode] = useState({
    ...node,
    mappings: node?.data?.mappings || []
  });

  useEffect(() => {
    if (node) {
      setCurrentNode({
        ...node,
        mappings: node?.data?.mappings || []
      });
    }
  }, [node]);

  const getConnectedNodes = useCallback(() => {
    if (!edges?.length || !nodes?.length) {
      console.error("Edges or nodes are not defined or empty.");
      return [];
    }

    return edges
      .filter(edge => edge.target === node.id)
      .map(edge => {
        const sourceNode = nodes.find(n => n.id === edge.source);
        return sourceNode ? {
          ...sourceNode,
          mappings: sourceNode?.data?.mappings || []
        } : null;
      })
      .filter(Boolean);
  }, [edges, nodes, node?.id]);

  const handleMappingUpdate = useCallback((updatedMappings) => {
    setCurrentNode(prev => ({
      ...prev,
      mappings: updatedMappings
    }));
    onSave(updatedMappings);
  }, [onSave]);

  const connectedSources = getConnectedNodes();

  if (!node) return null;

  const mappingConfig = {
    sourceNodes: connectedSources,
    currentNode: currentNode,
    relevantMappings: currentNode.mappings.filter(mapping =>
      mapping.destination_task === currentNode?.data?.function_identifier
    )
  };

  return (
    <div className={styles.container}>
      {(!connectedSources.length) ? (
        <div className={styles.warning}>
          <AlertCircle className={styles.warningIcon} />
          <span>Configure node connections to begin mapping</span>
        </div>
      ) : (
        <>
          <div className={styles.headerSection}>
            <div className={styles.headerContent}>
              <div className={styles.headerLeft}>
                <div
                  className={styles.headerIcon}
                >
                  <ArrowLeftRight size={16} />
                </div>

                <h2 className={styles.headerTitle}>Input Mapping</h2>
              </div>
            </div>
          </div>

          <div className={styles.mainContent}>
            <div className={styles.infoBox}>
              <Info className={styles.infoIcon} />
              <p className={styles.infoText}>
                Configure field mappings between connected nodes
              </p>
            </div>
            <div className={styles.formContainer}>
              <MappingForm
                mappingConfig={mappingConfig}
                onMappingChange={handleMappingUpdate}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MappingAndTransformationSection;