import { createStore, applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers'; // Assuming you have combined reducers here
import rootSaga from '../sagas/rootSaga'; // Import your root saga
import logger from 'redux-logger'; // Import redux-logger
import authSaga from '../sagas/authSaga'
// import { watchTokenExpiration } from '../sagas/watchTokenExpiration';
import authReducer from '../reducers/authReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Uses localStorage by default
import { REAUTHENTICATE_REQUEST } from '../actions/authActions' // Action type

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// const store = configureStore({
//   reducer: {
//     auth: authReducer, // Your auth reducer here
//     // other reducers
//   },
//   middleware: [sagaMiddleware],
// });
// 2. Configure Persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Only persist auth-related state
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
//Create the Redux store with Saga middleware
// const store = createStore(
//   rootReducer, // Your combined reducers
//   applyMiddleware(sagaMiddleware)
// );
// Custom middleware to track reAuthenticate actions
const trackReAuthenticateMiddleware = store => next => action => {
  if (action.type === REAUTHENTICATE_REQUEST) {
    console.log('reAuthenticate action dispatched:', action.payload);
  }
  return next(action);
};
const store = configureStore({
  reducer: persistedReducer, // Use persistedReducer here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false, // Disabling thunk as we are using saga
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    })
      .concat(sagaMiddleware) // Adding saga middleware
      .concat(trackReAuthenticateMiddleware) // Adding custom middleware
      .concat(logger),
});

// Run the root saga
sagaMiddleware.run(rootSaga);
// const persistor = persistStore(store);

export const persistor = persistStore(store);
export default store;
