import React from 'react'
import { useState, forwardRef } from 'react';
import { TextField, Grid, Autocomplete, MenuItem } from '@mui/material';
import { CalendarRange } from 'lucide-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './partnerfilter.module.css';

const options = ['Customer 1'];
const CustomInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div className={styles.dateInputWrapper}>
    <CalendarRange
      className={styles.calendarIcon}
      onClick={onClick} />
    <input
      className={styles.dateRangePickerInput}
      value={value}
      onClick={onClick}
      placeholder={placeholder}
      readOnly
      ref={ref}
    />

  </div>
));
const PartnerFilter = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 2, mb: 2 }}>
      {/* Autocomplete Dropdown */}
      <Grid item xs={12} sm={6}>
        <Autocomplete
          value={selectedOption}
          onChange={(event, newValue) => setSelectedOption(newValue)}
          options={options}
          renderInput={(params) => <TextField {...params} placeholder='Select Customers' variant="outlined" />}
          sx={{
            height: '32px', borderRadius: '8px',
            '& .MuiOutlinedInput-root': {
              height: '32px',
              borderRadius: '8px',
              padding: '0',
              '& .MuiAutocomplete-input': {
                padding: '4px 8px',
              },
            },
          }}
          renderOption={(props, option) => (
            <MenuItem
              {...props}
              sx={{
                fontSize: '16px',
                fontFamily: 'Inter, sans-serif',
                color: '#6B6B6B',
                '&.Mui-selected': {
                  color: '#4F63BE',
                  backgroundColor: '#ECEEF6',
                },
                '&.Mui-selected:hover': {
                  backgroundColor: '#E0E4F2',
                },
                '&:hover': {
                  backgroundColor: '#F5F5F5',
                },
              }}
            >
              {options}
            </MenuItem>
          )}
        />
      </Grid>
      {/* Date Range Picker */}
      <Grid item xs={12} sm={6}>
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          // className={styles.dateRangePickerInput}
          onChange={(update) => {
            setDateRange(update);
          }}
          isClearable={true}
          placeholderText="Select date range"
          customInput={<CustomInput />}
        />
      </Grid>
    </Grid>
  )
}

export default PartnerFilter