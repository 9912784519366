import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { Search, AlertTriangle, ChevronLeft, ChevronRight, Loader } from 'lucide-react';
import { ReactComponent as SystemSettingsIcon } from "../../../../assetts/images/systemSettings.svg";
import CustomDropdown from "../../../../components/reusableComponents/CustomDropdown/CustomDropdown";
import { fetchProcessFlowListApi, fetchStatusMasterApi } from "../../../../api/processFlowApi";
import { filterLogs, getStatusIcon, getStatusStyles } from "./logUtils";
import EmptyState from "../../../../components/reusableComponents/EmptyState/EmptyState";
import styles from "./DetailViewSidebar.module.css";

const DateFilter = ({ fromDate, toDate, setFromDate, setToDate }) => (
  <div className={styles.filterItem}>
    <div className={styles.filterHeader}>
      <SystemSettingsIcon className={styles.filterIcon} />
      <span className={styles.filterTitle}>Date*</span>
    </div>
    <div className={styles.datePickerContainer}>
      <DatePicker
        selected={fromDate}
        onChange={setFromDate}
        className={styles.dateInput}
        dateFormat="yyyy-MM-dd"
        placeholderText="From date"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        todayButton="Today"
        isClearable
      />
      <span className={styles.dateSeparator}>to</span>
      <DatePicker
        selected={toDate}
        onChange={setToDate}
        className={styles.dateInput}
        dateFormat="yyyy-MM-dd"
        placeholderText="To date"
        minDate={fromDate}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        todayButton="Today"
        isClearable
      />
    </div>
  </div>
);

const LogItem = ({ log, isHighlighted, onClick }) => (
  <div
    className={`${styles.logItem} ${isHighlighted ? styles.highlighted : ""}`}
    onClick={onClick}
    style={getStatusStyles(log.status)}
  >
    <div className={styles.dateTime}>
      <span className={`${styles.logDate} ${isHighlighted ? styles.highlighted : ""}`}>{log.date}</span>
      <span className={`${styles.logTime} ${isHighlighted ? styles.highlighted : ""}`}>{log.time}</span>
    </div>
    {getStatusIcon(log.status, styles)}
  </div>
);

const DetailViewSidebar = ({ onLogSelect,setStatusOptions , statusOptions}) => {
  const { id } = useParams();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const [logs, setLogs] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(100);
  const [highlightedLog, setHighlightedLog] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(Date.now() - 86400000));
  const [toDate, setToDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  useEffect(() => {
    fetchStatusMasterApi()
      .then(response => response?.graph_status_choices && setStatusOptions(response.graph_status_choices))
      .catch(error => {
        const errorMessage = error?.errors?.length ? 
          error.errors.map(err => err.detail).join('. ') : 
          "Error loading status options. Please refresh the page.";
        setError(errorMessage);
      });
  }, [accessToken]);

  useEffect(() => {
    let isMounted = true;
    if (!isLoading) {
      setIsLoading(true);
      fetchProcessFlowListApi({
        from_date: fromDate.toISOString().split('T')[0],
        to_date: toDate.toISOString().split('T')[0],
        status: selectedStatus,
        workflow_id: id
      }, currentPage)
        .then(data => {
          if (isMounted) {
            setLogs(data.results || []);
            setHasNextPage(!!data.next);
            setHasPreviousPage(!!data.previous);
            onLogSelect?.(data.results?.[0]);
          }
        })
        .catch((error) => {
          if (isMounted) {
            let errorMessage = "Error fetching logs. Please try again later.";
            
            if (error?.errors?.length > 0) {
              errorMessage = error.errors
                .map(err => err.detail)
                .join('. ');
            }
            
            setError(errorMessage);
          }
        })
        .finally(() => isMounted && setIsLoading(false));
    }
    return () => { isMounted = false; };
  }, [accessToken, selectedStatus, fromDate, toDate, currentPage]);

  const filteredLogs = filterLogs(logs, selectedStatus);
  const currentStatusLabel = statusOptions.find(opt => opt.value === selectedStatus)?.label || 'All';

  const renderContent = () => {
    if (error) return <EmptyState icon={AlertTriangle} title="Error Loading Logs" message={error} iconSize={18} className={`${styles.sidebarEmptyState} ${styles.errorState}`} />;
    if (isLoading) return <div className={styles.loadingState}><Loader className={styles.loadingIcon} size={24} /><span>Loading logs...</span></div>;
    if (filteredLogs.length === 0) {
      return <EmptyState
        icon={Search}
        title="No Logs Found"
        message={`No logs found with status "${currentStatusLabel}" for the selected date range`}
        iconSize={18}
        className={styles.sidebarEmptyState}
      />;
    }
    return (
      <div className={styles.logList}>
        {filteredLogs.map(log => (
          <LogItem
            key={log.id}
            log={log}
            isHighlighted={highlightedLog === log.id}
            onClick={() => { setHighlightedLog(log.id); onLogSelect?.(log); }}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={styles.sidebar}>
      <div className={styles.filterSection}>
        <DateFilter fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
        <div className={styles.filterItem}>
          <div className={styles.filterHeader}>
            <SystemSettingsIcon className={styles.filterIcon} />
            <span className={styles.filterTitle}>Status*</span>
          </div>
          <CustomDropdown
            items={statusOptions}
            value={selectedStatus}
            onChange={(value) => { setSelectedStatus(value); setCurrentPage(1); }}
            style={{ width: "100%", maxWidth: "261px", height: "53px", borderRadius: "12px", fontSize: "18.97px", lineHeight: "22.96px", color: "#6B6B6B" }}
          />
        </div>
      </div>
      <div className={styles.listSection}>
        {renderContent()}
        {(hasPreviousPage || hasNextPage) && !isLoading && (
          <div className={styles.paginationContainer}>
            <div className={styles.paginationButtons}>
              <button className={styles.paginationButton} onClick={() => setCurrentPage(p => p - 1)} disabled={!hasPreviousPage}><ChevronLeft size={20} /></button>
              <span className={styles.paginationInfo}>{currentPage}</span>
              <button className={styles.paginationButton} onClick={() => setCurrentPage(p => p + 1)} disabled={!hasNextPage}><ChevronRight size={20} /></button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailViewSidebar;