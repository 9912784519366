import React, { useState, useEffect } from 'react';
import { IconButton, Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchWorkflowTemplates } from '../api/workflowApi'; // Import the API function
import { useSelector } from 'react-redux';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchComponent from '../components/common/SearchBox';
import CustomTooltip from '../components/reusableComponents/CustomTooltip/CustomTooltip';
import theme from '../theme';
import CustomPagination from '../components/common/CustomPagination';

const MyTemplates = () => {
  const navigate = useNavigate();

  // const breadcrumbs = useSelector(state => state.breadcrumbs.breadcrumbs)
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getTemplates(currentPage); // Fetch templates when the component mounts
  }, [currentPage]); // Access token as dependency to ensure the correct token is used
  useEffect(() => {

    getTemplates(1);
    setCurrentPage(1)
  }, [searchTerm]);

  const handleTimer = (id) => {
    console.log(`Start timer for template ${id}`);
  };

  const handleShutdown = (id) => {
    navigate(`/dashboard`)
  };

  const handleDelete = (id) => {
    console.log(`Delete template ${id}`);
  };
  const handleClickIntegration = () => {
    console.log("handleClickIntegration")
    navigate('/integrations'); // Navigate to integrations page
  }
  const handleClickWorkflowLibrary = () => {
    navigate('/workflow-library');
  }


  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  const getTemplates = async (page) => {
    setLoading(true)
    try {
      const data = await fetchWorkflowTemplates(page, searchTerm); // Call the API to fetch templates

      if (data.results != undefined) {
        setTemplates(data.results); // Set the 'results' array from the response
        setTotalPages(Math.ceil(data.count / 10))
      }
      setLoading(false); // Set loading to false when data is fetched
    } catch (error) {
      console.error('Failed to fetch templates:', error);
      setError(true); // Set error state if the API call fails
      setLoading(false); // Set loading to false even on error
    }
  };
  if (error) {
    return <Typography>Error loading templates.</Typography>;
  }

  return (
    <Box sx={{ p: 5, mt: 8 }}>
      {/* Buttons for Integrations and Workflow Library */}
      <Box className="buttonGroup" display="flex" marginTop={5}>
        <Button
          variant="contained"
          sx={{
            width: '130px',
            height: '32px',
            borderRadius: '24px',
            backgroundColor: '#4F63BE',
            boxShadow: 'none',
            textTransform: 'none',
            '& .MuiButton-startIcon': {
              borderRadius: '50%',
              padding: '0px',
            },
          }}
          startIcon={<AddCircleOutlinedIcon sx={{ borderRadius: '50%' }} />}
          onClick={handleClickIntegration} // Open drawer on click
        >
          Integrations
        </Button>
        <Button
          variant="contained"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Align content to the left
            width: '135px',
            height: '32px',
            borderRadius: '24px',
            backgroundColor: '#7398C7',
            boxShadow: 'none',
            textTransform: 'none',
            marginLeft: '8px', // Space between buttons
            padding: '0 12px', // Remove extra padding for tighter alignment
            '&:hover': {
              backgroundColor: '#7398C7',
            },
            '& .MuiButton-startIcon': {
              marginRight: '8px', // Space between icon and text
              marginLeft: '-4px', // Shift icon closer to the left edge if needed
            },
          }}
          startIcon={<AddCircleOutlinedIcon sx={{ fontSize: 20 }} />}
          onClick={handleClickWorkflowLibrary}
        >
          Workflows
        </Button>
      </Box>
      <Box
        sx={{
          borderRadius: '25px', // Outer border radius set to 25px
          p: 2, // Padding inside the box
          border: '1.81px solid #ECEEF6', // Applying the specific border style
          mt: 3, // Optional margin-top adjustment
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 3, // 72px top margin
            mb: 3,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              ml: 2,
              fontFamily: 'Inter',           // Set font-family to 'Inter'
              fontSize: '20px',              // Set font-size to 20px
              fontWeight: 600,               // Set font-weight to 600
              lineHeight: '24.2px',          // Set line-height to 24.2px
              letterSpacing: '0.01em',       // Set letter-spacing to 0.01em
              textAlign: 'left',             // Align text to the left
            }}
          >
            My Templates
          </Typography>

          <SearchComponent searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

        </Box>
        {/* Table Header */}
        <TableContainer sx={{ mb: 2 }}>
          <Table sx={{ borderCollapse: 'collapse' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...theme.typography.tableCellHeader }}>Name</TableCell>
                <TableCell sx={{ ...theme.typography.tableCellHeader }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                loading ?

                  (
                    <TableCell colSpan={2}>
                      <Box sx={{ display: "flex", justifyContent: "center", width: "100%", p: 2 }}>
                        <CircularProgress />
                      </Box>
                    </TableCell>) :
                  (
                    templates.length > 0 ? (

                      templates.map((template) => (
                        <TableRow key={template.id} sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          height: '25px', // Adjust the row height to reduce spacing
                        }}>
                          {/* Template Name */}
                          <TableCell
                            onClick={() => navigate(`/workflow-builder/template/${template.id}`)}
                            sx={{
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              color: '#000000',
                              fontWeight: 600,
                              fontSize: '14px',
                              ml: 2,
                              lineHeight: '16.94px',
                              borderBottom: 'none', // No bottom border
                              padding: '8px 14px', // Reduced padding
                              userSelect: 'none',
                              '&:hover': {
                                backgroundColor: '#f0f0f0', // Optional: Change background color on hover
                              },
                            }}
                          >
                            {template.name}
                          </TableCell>

                          {/* Action Icons */}
                          <TableCell align="left" sx={{ borderBottom: 'none', padding: '4px 8px', ml: 5, display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 0 }}>
                            <CustomTooltip title="Set Timer">
                              <IconButton
                                onClick={() => handleTimer(template.id)}
                                sx={{
                                  backgroundColor: '#F2F2F2',
                                  color: '#4F63BE',
                                  mt: 1,
                                  mr: 2,
                                  p: 1,
                                  '&:hover': {
                                    backgroundColor: '#D9D9D9',
                                  },
                                }}
                                aria-label="timer"
                              >
                                <TimerOutlinedIcon sx={{ fontSize: 14 }} />
                              </IconButton>
                            </CustomTooltip>

                            <CustomTooltip title="Launch">
                              <IconButton
                                onClick={() => handleShutdown(template.id)}
                                sx={{
                                  backgroundColor: '#F2F2F2',
                                  color: '#4F63BE',
                                  mt: 1,
                                  mr: 2,
                                  p: 1,
                                  '&:hover': {
                                    backgroundColor: '#D9D9D9',
                                  },
                                }}
                                aria-label="shutdown"
                              >
                                {/* <PowerSettingsNewIcon sx={{ fontSize: 14 }} /> */}
                                <LaunchOutlinedIcon sx={{ fontSize: 14 }} />
                              </IconButton>
                            </CustomTooltip>

                            <CustomTooltip title="Delete">
                              <IconButton
                                onClick={() => handleDelete(template.id)}
                                sx={{
                                  backgroundColor: '#F2F2F2',
                                  color: '#4F63BE',
                                  mt: 1,
                                  mr: 1,
                                  p: 1,
                                  '&:hover': {
                                    backgroundColor: '#D9D9D9',
                                  },
                                }}
                                aria-label="delete"
                              >
                                <DeleteOutlineOutlinedIcon sx={{ fontSize: 14 }} />
                              </IconButton>
                            </CustomTooltip>
                          </TableCell>
                        </TableRow>
                      ))

                    ) : (
                      <TableCell colSpan={2}>
                        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", p: 2 }}>
                          No Data Available.
                        </Box>
                      </TableCell>
                    )

                  )
              }

            </TableBody>
          </Table>
        </TableContainer>

      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignContent='center'
        sx={{
          width: '100%',
          position: 'relative',

          bottom: -30,
          alignItems: "center",
          border: '0px solid #ECEEF6',
        }}>

        <CustomPagination
          totalPages={totalPages}
          curPage={currentPage}
          onPageChange={handlePageChange}
        ></CustomPagination>

      </Box>






      {/* <Box
        sx={{
          borderRadius: '25px', // Outer border radius set to 25px
          p: 2, // Padding inside the box
          border: '1.81px solid #ECEEF6', // Applying the specific border style
          mt: 3, // Optional margin-top adjustment
        }}
      >
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3, // 72px top margin
          mb: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            ml:2,
            fontFamily: 'Inter',           // Set font-family to 'Inter'
            fontSize: '20px',              // Set font-size to 20px
            fontWeight: 600,               // Set font-weight to 600
            lineHeight: '24.2px',          // Set line-height to 24.2px
            letterSpacing: '0.01em',       // Set letter-spacing to 0.01em
            textAlign: 'left',             // Align text to the left
          }}
        >
          My Templates
        </Typography>


        
      </Box>
      <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> 
        <Box sx={{ flex: 1 }}>
          <Typography
            variant="h6"
            sx={{
              ml:2,
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '16.94px',
              letterSpacing: '0.01em',
              textAlign: 'left',
              color: '#949494',
              mb: 0, // Ensure there's no margin below
            }}
          >
            Name
          </Typography>
          <Box sx={{ height: '0.82px', backgroundColor: '#EFEFEF', width: '100%', mt: 1 }} /> 
        </Box>

        
        <Box sx={{ flex: 1, textAlign: 'right' }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '16.94px',
              letterSpacing: '0.01em',
              textAlign: 'right',
              color: '#949494',
              mb: 0, // Ensure there's no margin below
              mr:35,
            }}
          >
            Action
          </Typography>
          <Box sx={{ height: '0.82px', backgroundColor: '#EFEFEF', width: '100%', mt: 1 }} /> 
        </Box>
      </Box>
        
        <List>
          {templates.map((template) => (
            <ListItem key={template.id} sx={{ mb: 2, borderRadius: 1, backgroundColor: 'transparent', padding: '1px 0'}}>
              <ListItem>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center', // Center items vertically
                        width: 'fit-content', // Adjust width based on content
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 600,
                          lineHeight: '16.94px',
                          textAlign: 'left',
                          cursor: 'pointer',
                          whiteSpace: 'nowrap', // Prevent text from wrapping
                          overflow: 'hidden', // Hide overflow
                          textOverflow: 'ellipsis', // Show ellipsis for overflowing text
                        }}
                        onClick={() => navigate(`/workflow-builder/${template.id}`,{ state: { type: 'template' } })}
                      >
                        {template.name}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              
              <ListItemSecondaryAction sx={{
                right: '205px' // Move the entire action block 16px to the left (you can adjust this value)
              }}>
                <IconButton
                  edge="end"
                  onClick={() => handleTimer(template.id)}
                  sx={{ 
                    backgroundColor: '#F2F2F2', // Set the background color
                    color: '#4F63BE',           // Customize icon color
                    mr: 2,                      // Right margin
                    '&:hover': {                // Change background color on hover
                      backgroundColor: '#D9D9D9' // Optional: a darker shade on hover
                    }
                  }}// Customize icon color
                  aria-label="timer"
                >
                  <TimerOutlinedIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={() => handleShutdown(template.id)}
                  sx={{ 
                    backgroundColor: '#F2F2F2', // Set the background color
                    color: '#4F63BE',           // Customize icon color
                    mr: 2,                      // Right margin
                    '&:hover': {                // Change background color on hover
                      backgroundColor: '#D9D9D9' // Optional: a darker shade on hover
                    }
                  }}
                  aria-label="shutdown"
                >
                  <PowerSettingsNewIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  onClick={() => handleDelete(template.id)}
                  sx={{ 
                    backgroundColor: '#F2F2F2', // Set the background color
                    color: '#4F63BE',           // Customize icon color
                    mr: 2,                      // Right margin
                    '&:hover': {                // Change background color on hover
                      backgroundColor: '#D9D9D9' // Optional: a darker shade on hover
                    }
                  }}
                  aria-label="delete"
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Box> */}
    </Box>
  );
};

export default MyTemplates;
