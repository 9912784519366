import React, { useState } from "react";
import Box from '@mui/material/Box'
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import TextField from "@mui/material/TextField";
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import dayjs from 'dayjs';
import CustomTooltip from "../../../reusableComponents/CustomTooltip/CustomTooltip";
import { Radio } from "@mui/material";


const DayFrequency = ({ occurence }) => {
    const [freqData, setFreqData] = useState([])
    const [selectedRadio, setSelectedRadio] = useState("freqData");
    const [freqData2, setFreqData2] = useState([{
        on: "0",
        day: "0"
    }])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const addRow = (e, type) => {

        switch (type) {
            case "day":
                setFreqData((prev) => [...prev, "1"]);
                break;
            case "specific":
                setFreqData2((prev) => [...prev, { on: "0", day: "0" }]);
                break;
            default:
                break;
        }
    };

    const removeRow = (index, type) => {
        console.log("type", type)
        switch (type) {
            case "day":
                if (freqData.length === 1 && index === 0) {
                    const list = [...freqData];
                    list[index] = "1"
                    setFreqData(list);
                } else {
                    const list = [...freqData];
                    list.splice(index, 1);
                    setFreqData(list);
                }
                break;
            case "specific":
                if (freqData2.length === 1 && index === 0) {
                    const list = [...freqData2];
                    list[index].on = "0"
                    list[index].day = "0"
                    setFreqData2(list);
                } else {
                    const list = [...freqData2];
                    list.splice(index, 1);
                    setFreqData2(list);
                }
                break;
            default:
                break;
        }
    };
    // const handleFrequency = (e, index) => {
    //     const list = [...freqData];
    //     list[index] = e.target.value;
    //     setFreqData(list);
    // }
    const handleFrequency = (event) => {
        const {
            target: { value },
        } = event;
        setFreqData(
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const deleteFrequency = (event, valueToRemove) => {
        setFreqData((prevSelected) =>
            prevSelected.filter((value) => value !== valueToRemove)
        );
    };
    const occurenceChange = (e, index) => {
        setFreqData2((prev) => {
            prev[index].on = e.target.value;
            return [...prev];
        });
    };

    const specificDayChange = (e, index) => {
        setFreqData2((prev) => {
            prev[index].day = e.target.value;
            return [...prev];
        });
    };
    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "18px", marginBottom: "18px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ width: "12%" }}>Type:</Typography>
                <RadioGroup
                    row
                    value={selectedRadio}
                    onChange={(e) => setSelectedRadio(e.target.value)}
                >
                    <FormControlLabel value="freqData" control={<Radio />} label="Day" />
                    <FormControlLabel value="freqData2" control={<Radio />} label="The" />
                    <FormControlLabel value="other" control={<Radio />} label="From" />

                </RadioGroup>
            </div>
            <div
                style={{
                    display: selectedRadio !== "freqData" ? "none" : "flex",
                    flexWrap: "nowrap",
                    rowGap: "32px",

                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", width: "12%" }}>
                </div>

                <div
                    style={{
                        marginRight: "16px",
                        display: "flex",
                        width: "38%"
                    }}
                >
                    <Select
                        multiple
                        value={freqData}
                        onChange={handleFrequency}
                        renderValue={(selected) => (
                            <Box
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "4px",
                                    maxHeight: "40px",
                                    overflow: "auto",
                                }}
                            >
                                {selected.map((value) => (
                                    <Box
                                        key={value}
                                        style={{
                                            position: "relative",
                                            display: "inline-flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Chip
                                            key={value}
                                            label={`Day ${value}`}
                                            style={{
                                                backgroundColor: "#F2F2F2",
                                                color: "#4F63BE",
                                                borderRadius: "6px",
                                                padding: "4px",
                                                fontSize: "12px",
                                            }}
                                        />
                                        <Box
                                            component="span"
                                            onMouseDown={(event) => event.stopPropagation()}
                                            onClick={(event) => deleteFrequency(event, value)}
                                            style={{
                                                position: "absolute",
                                                top: "1px",
                                                right: "4px",
                                                color: "#4F63BE",
                                                fontSize: "12px",
                                                cursor: "pointer",
                                                userSelect: "none",
                                            }}
                                        >
                                            x
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                        sx={{
                            height: "48px",
                            "& .MuiInputBase-root": {
                                height: "100%",
                                minHeight: "48px",
                                display: "flex",
                                alignItems: "center", padding: "16px",
                            },
                            "& .MuiInputBase-input": {
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "400",
                            }
                        }}
                    >
                        {Array.from({ length: 31 }, (_, index) => index + 1).map((day) => (
                            <MenuItem key={day} value={day}>
                                Day {day}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>

            {freqData2.map((frequency, index) => (
                <div
                    key={index}
                    style={{
                        display: selectedRadio !== "freqData2" ? "none" : "flex",
                        flexWrap: "nowrap",
                        rowGap: "16px",
                        alignItems: "center",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center", width: "12%" }}>
                    </div>

                    <div
                        style={{
                            marginRight: "16px",
                            width: "38%"
                        }}
                    >
                        <Select
                            onChange={(e) => occurenceChange(e, index)}
                            value={freqData2[index].on}
                            sx={{
                                width: "100%",
                                height: "48px"
                            }}
                        >
                            {['All', 'First', 'Second', 'Third', 'Fourth', 'Last'].map((label, index) => (
                                <MenuItem key={index} value={index.toString()}>{label}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div
                        style={{
                            width: "38%"
                        }}
                    >
                        <Select
                            onChange={(e) => specificDayChange(e, index)}
                            value={freqData2[index].day}
                            sx={{
                                width: "80%",
                                height: "48px",
                            }}
                        >
                            {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                                <MenuItem key={index} value={index}>{day}</MenuItem>
                            ))}
                            {freqData2[index].on === "5" && <MenuItem value="7">Day</MenuItem>}
                        </Select>
                    </div>
                </div>
            ))
            }

            <div
                style={{
                    display: selectedRadio !== "other" ? "none" : "flex",
                    flexWrap: "nowrap",
                    rowGap: "16px",
                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", width: "12%" }}>
                </div>


                <TextField
                    type="number"
                    placeholder="From Day"
                    inputProps={{
                        min: 1,
                        max: 31,
                    }}
                    sx={{
                        width: "30%",
                        height: "48px",
                        marginRight: "16px",
                        "& .MuiInputBase-root": {
                            height: "48px",
                        }
                    }}
                />


                <TextField
                    type="number"
                    placeholder="End Day"
                    inputProps={{
                        min: 1,
                        max: 31,
                    }} sx={{
                        width: "30%",
                        height: "48px",
                        "& .MuiInputBase-root": {
                            height: "48px",
                        }
                    }}
                />

            </div>
        </div >


    )
}

export default DayFrequency;