// sagas/authSaga.js


import { call, put, select, takeLatest, debounce} from 'redux-saga/effects';
import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    loginSuccess,
    loginFailure,
    logoutSuccess,
    logoutFailure,
    REAUTHENTICATE_REQUEST,
    REAUTHENTICATE_FAILED,
    REFRESH_TOKEN_REQUEST,
    refreshTokenSuccess,
    refreshTokenFailure,
    REGISTER_REQUEST,
    registerSuccess,
    registerFailure,
    storeTokens,
    clearTokens,
    storeUserDetails,
    RESET_PASSWORD_REQUEST, 
    resetPasswordSuccess, 
    resetPasswordFailure,
    SETNEW_PASSWORD_REQUEST,
    setNewPasswordSuccess,
    setNewPasswordFailure,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_SUCCESS,
    GOOGLE_LOGIN_FAILURE,
    GOOGLE_LOGIN_CALLBACK,
    googleLoginSuccess, googleLoginFailure, googleLoginCallback,
    LINKEDIN_LOGIN_REQUEST,
    LINKEDIN_LOGIN_CALLBACK,
    linkedInLoginSuccess, linkedInLoginFailure, linkedInLoginCallback,
    MICROSOFT_LOGIN_REQUEST,
    MICROSOFT_LOGIN_CALLBACK,
    MicrosoftLoginFailure, MicrosoftLoginCallback,
    MicrosoftLoginSuccess,
    SOCIAL_MEDIA_LOGIN_REQUEST,
    SOCIAL_MEDIA_LOGIN_CALLBACK,
    socialMediaLoginSuccess, socialMediaLoginFailure, socialMediaLoginCallback,
    REFRESH_TOKEN_SUCCESS,
    setPartners, 
    
} from '../actions/authActions';
import { 
  FETCH_PARTNER_REQUEST,
  fetchPartnerSuccess,
  fetchPartnerFailure,
  setPaginationMeta,
} from '../actions/actions'

import { loginApi,logoutRequestAPI, refreshTokenApi, sendPasswordLinkRequest, setNewPasswordRequest, 
  socialMediaLoginRequestAPI,socialMediaCallbackAPI,
  fetchUserTypeListApi} from '../../api/authApi'
import { isTokenValid, refreshAccessToken } from '../../utils/tokenUtils';
import { fetchApi } from '../../utils/apiUtils';
import { fetchPartnersApi } from '../../api/usermanagmentApi';
import { decodePermission } from '../../utils/generalUtils';
const selectAccessToken = (state) => state.auth.accessToken;
const selectRefreshToken = (state) => state.auth.refreshToken;


// Worker Saga: Handling login API call
function* handleLogin(action) {
    try {
      console.log(' HANDLE LOGIN.........', action)
        const response = yield call(loginApi, action.payload);
        const { access, refresh, user_id, first_name, last_name, email, profile_image_url, permissions, user_type } = response;
       console.log(' $$ response .. ',response )
        // const { accessToken, refreshToken } = response;
        if (response.access && response.refresh) {
            // Store access token and refresh token in localStorage
            // yield put({ type: LOGIN_SUCCESS, payload: { access, refresh } });
            yield put({ type: 'STORE_TOKENS', payload: { accessToken: access, refreshToken: refresh } });
            // yield put(storeTokens(access, refresh));
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
     
            // Optionally store user information
            localStorage.setItem('userId', user_id);
            localStorage.setItem('firstName', first_name);
            localStorage.setItem('lastName', last_name);
            localStorage.setItem('email', email);
            localStorage.setItem('profile_image_url', profile_image_url);
            localStorage.setItem('permissions', permissions);
            localStorage.setItem('user_type', user_type);
            const userTypeList = yield call(fetchUserTypeListApi);
            console.log('userTypeList  == >', userTypeList)
            const item = userTypeList.find(type => type.value === user_type);
            console.log('##########  item.name ..... ',item?.name)
            const usertypename = item?.name
            
            const permissionConfig = yield call (decodePermission, permissions)
            console.log('##########  permissionConfig ..... ',permissionConfig)
            yield put(loginSuccess('Login successful!'));
            // Dispatch user details to the Redux store
            yield put(storeUserDetails({ user_id, first_name, last_name, email, profile_image_url, permissionConfig,user_type, usertypename }));
            // Optionally navigate to the dashboard after login
            yield put({ type: LOGIN_SUCCESS, payload: {
              userDetails: {
                
                first_name: response.first_name,
                last_name: response.last_name,
                email: response.email,
                profile_image_url:response.profile_image_url,
                permissions:permissionConfig,
                user_type: user_type,
                usertypename:usertypename
              },
              access, refresh} });
            // You may need to use history.push('/dashboard') or a similar method
        } else {
          // Dispatch login failure if no tokens are found in the response
          //  yield put(loginFailure('Login failed. Please try again.'));
          console.log('response.errors ... ',response.errors[0].detail)
          yield put(loginFailure(response.errors[0].detail));
        }
        // Dispatch success action
        // yield put({ type: LOGIN_SUCCESS, payload: {
        //   userDetails: {
        //     first_name: response.first_name,
        //     last_name: response.last_name,
        //     email: response.email,
        //   },
        //   access, refresh} });
        // yield put(loginSuccess({
        //     accessToken: response.access,
        //     refreshToken: response.refresh,


        // }));
    } catch (error) {
        // yield put(loginFailure(error.message));
        console.log('response.errors ... ',error.errors[0].detail)
          yield put(loginFailure(error.errors[0].detail));
    }
}


export function* handleRefreshToken(action) {
   try {
      const { refreshToken } = action.payload;
      const newAccessToken = yield call(refreshAccessToken, refreshToken);
      if (newAccessToken) {
          // Dispatch success action and store the new tokens
          yield put({ type: REFRESH_TOKEN_SUCCESS, payload: { accessToken: newAccessToken } });
      } else {
          throw new Error('Refresh token invalid');
      }
  } catch (error) {
      console.error("Failed to refresh token:", error.message);

      // Clear tokens and redirect to login
      yield put(clearTokens());
      // if (navigate) {
      //   navigate('/login'); // Redirect to login
      // } // Navigate to login page
      yield put({ type: LOGOUT_REQUEST }); // Optional: Dispatch logout action if required
  }
}




// API call for registration
function registerApi(userData) {
    console.log("Calling API with:", userData);
    return fetch('https://staging.runmybot.com/api/register/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    }).then(response => response.json());
  }
 
  // Worker saga
  function* handleRegister(action) {
    try {
      const response = yield call(registerApi, action.payload);
      console.log('API Response:', response);
      if (response.errors && response.errors[0] && response.errors[0].detail) {
       
        const errorMessage = response.errors[0].detail;
        yield put(registerFailure(errorMessage));  // Dispatch failure with the specific error message
      } else if (response.message) {
        yield put(registerSuccess(response.message));
      } else {
        yield put(registerFailure('Registration failed. Please try again.'));
      }
    } catch (error) {
      yield put(registerFailure('An error occurred during registration.'));
    }
  }
 
  // Watcher saga
  export function* watchRegister() {
    yield takeLatest(REGISTER_REQUEST, handleRegister);
  }
// Watcher saga for refresh token
  export function* watchTokenExpiration() {
    yield takeLatest(REFRESH_TOKEN_REQUEST, handleRefreshToken);
}


// Watcher Saga: Watches for login and refresh token actions
export default function* authSaga() {
    yield takeLatest(LOGIN_REQUEST, handleLogin);
    yield takeLatest(REFRESH_TOKEN_REQUEST, handleRefreshToken);
    // yield takeLatest(REAUTHENTICATE_REQUEST, handleReAuthenticate);
}


// Worker saga: handles the logout process
function* handleLogout(action) {
  try {
    // You can also clear tokens from localStorage or cookies if you're storing them there
    const { access, refresh } = action.payload; 
    if(refresh!=null){
      yield call(logoutRequestAPI, refresh, access); // Call API if needed
    }
    yield put(clearTokens()); // Dispatch action to clear tokens from the store
    localStorage.clear();
    yield put(logoutSuccess());
    

  } catch (error) {
    console.error('Logout failed:', error);
  }
}
// Watcher saga: listens for LOGOUT action
export function* watchLogoutSaga() {
  yield takeLatest(LOGOUT_REQUEST, handleLogout);
}

export function* watchLogin() {
  console.log("watchLogin..... ")
  yield takeLatest(LOGIN_REQUEST, handleLogin);
}

// Saga to check if the token is valid on app load
export function* handleReAuthenticate(action) {
  // try {
    const token = localStorage.getItem('accessToken');
 
    if (token) {
      const refresh = localStorage.getItem('refreshToken');
      const userid = localStorage.getItem('userId');
      const first_name = localStorage.getItem('firstName');
      const last_name  = localStorage.getItem('lastName');
      const email  = localStorage.getItem('email');
      const profile_image_url = localStorage.getItem('email');
      const permissions = localStorage.getItem('permissions');
      const user_type = localStorage.getItem('user_type');
      const userTypeList = yield call(fetchUserTypeListApi);
      console.log("### userTypeList ",userTypeList)
      const permissionConfig = yield call (decodePermission, permissions)
      console.log('##########  permissionConfig ..... ',permissionConfig)
      const item = userTypeList.find(type => type.value == user_type);
      console.log('##########  item.name ..... ',item)
      const usertypename = item?.name
      yield put(storeUserDetails({ userid, first_name, last_name, email, profile_image_url, permissionConfig, usertypename}));
      yield put({ type: 'STORE_TOKENS', payload: { accessToken: token, refreshToken: refresh } });
      yield put({ type: LOGIN_SUCCESS, payload: {
        userDetails: {
          
          first_name: first_name,
          last_name: last_name,
          email: email,
          profile_image_url:profile_image_url,
          permissions:permissionConfig,
          user_type: usertypename
        },
        token, refresh} });
      // Validate the token (you can also refresh it if needed)
      const isValid = yield call(isTokenValid, token);
      if (isValid) {
        // Token is valid, you can update the state
        yield put({ type: 'REAUTHENTICATE_SUCCESS', payload: token });
      } else {
        
        // Try refreshing the token
        const newToken = yield call(refreshAccessToken, token);
        if (newToken) {
          // Save the new token
          localStorage.setItem('authToken', newToken);
          yield put({ type: 'REAUTHENTICATE_SUCCESS', payload: newToken });
        } else {
          // No valid token, log out the user
          yield put({ type: 'REAUTHENTICATE_FAILED' });
        }
      }
    }
  // }  catch (error) {
  //   console.error('Error during re-authentication:', error);
  //   yield put({ type: LOGIN_FAILURE });
  // }
}

// Watcher saga for re-authentication on app initialization
export function* watchReAuthenticate() {
  console.log('watchReAuthenticate ***** REAUTHENTICATE_REQUEST ')
  yield takeLatest(REAUTHENTICATE_REQUEST, handleReAuthenticate);
}
// Worker saga
function* resetPasswordSaga(action) {
  try {
    const response = yield call(sendPasswordLinkRequest, action.payload.email);
    yield put(resetPasswordSuccess(response.message)); // on success
  } catch (error) {
    yield put(resetPasswordFailure(error.message)); // handle error
  }
}

// Watcher saga
export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga);
}


// Saga worker function
function* handleSetNewPassword(action) {
   try {
    const { userId, token, Password } = action.payload;
    const response = yield call(setNewPasswordRequest, action.payload);
    
    yield put(setNewPasswordSuccess(response.message || 'Password reset successful!'));
      
    }
     catch (error) {
       yield put(setNewPasswordFailure(error.message || 'Password reset failed.'))
    }
}

// Watcher saga
export function* watchSetNewPassword() {
  yield takeLatest(SETNEW_PASSWORD_REQUEST, handleSetNewPassword);
}





// Worker Saga for Google Login
function* handleSocialMediaLogin({ payload }) {
  try {
   
    const userData = yield call(socialMediaLoginRequestAPI, payload);

    const updatedUserData = {
      ...userData,
      ...payload
    };
    // Check if `login_url` matches known provider login URLs
    if (
      typeof updatedUserData.login_url === 'string' && 
      /(https:\/\/accounts\.google\.com|https:\/\/www\.linkedin\.com|https:\/\/login\.microsoftonline\.com)/.test(updatedUserData.login_url)
    ) {
      // Redirect to the provider's authorization URL
      window.location.href = updatedUserData.login_url;
      return; // Stop execution as we're redirecting
    }

    // Handle success in case no redirect is needed
    yield put(socialMediaLoginSuccess(updatedUserData));
    
  } catch (error) {
     yield put(socialMediaLoginFailure(error.message)); // Dispatch failure action with error
  }
}


function* handleSocialMediaLoginCallback({ payload }) {
  try {
    // Call API to exchange code for user data
    const { provider, currentUrl , code } = payload
    const userData = yield call(socialMediaCallbackAPI, payload);
    const { access, refresh, user_id, first_name, last_name, email } = userData;
    // Store tokens in localStorage (optional)
    if (userData.access && userData.refresh) {
      yield put({ type: 'STORE_TOKENS', payload: { accessToken: userData.access, refreshToken:userData.refresh } });
      localStorage.setItem('accessToken', userData.access);
      localStorage.setItem('refreshToken', userData.refresh);
      localStorage.setItem('userId', user_id);
      localStorage.setItem('firstName', first_name);
      localStorage.setItem('lastName', last_name);
      localStorage.setItem('email', email);
      
      yield put(storeUserDetails({ user_id, first_name, last_name, email }));
    }
    yield put(socialMediaLoginSuccess(userData));
  } catch (error) {
     yield put(socialMediaLoginFailure(error.message));
  }
}

// Watcher Saga
export function* watchSocialMediaLogin() {
  yield takeLatest(SOCIAL_MEDIA_LOGIN_REQUEST, handleSocialMediaLogin);
  yield debounce(500, SOCIAL_MEDIA_LOGIN_CALLBACK, handleSocialMediaLoginCallback);
}
// Worker Saga: Handle the action
function* handleChildAction(action) {
  // try {
      console.log('Data from child via Saga:', action.payload);

      // Perform side effects (e.g., API call)
      // const response = yield call(apiFunction, action.payload);

      // Dispatch a success action with the response
      yield put({ type: 'CHILD_ACTION_SUCCESS', payload: action.payload });
  // } catch (error) {
      // Dispatch a failure action with the error
  //     yield put({ type: 'CHILD_ACTION_FAILURE', payload: error.message });
  // }
}
// Watcher Saga: Watch for the action
export function* watchChildAction() {
  yield takeLatest('CHILD_ACTION', handleChildAction);
}

function* fetchPartnersSaga(action) {
  const { accessToken, page, pageSize } = action.payload;
  
   try {
      const response = yield call(fetchPartnersApi, accessToken, page, pageSize);
      // console.log("$$$$$$$$$$$ response.data ",response.results)
      // Assuming the response structure includes `data` and `meta`
      yield put(fetchPartnerSuccess(response.results));
      yield put(setPaginationMeta(response.meta)); // Update pagination metadata
   } catch (error) {
      yield put(fetchPartnerFailure(error.message)); // Dispatch failure action
       console.error('Error fetching users:', error.message);
   }
}

export function* watchPartners() {
  yield takeLatest(FETCH_PARTNER_REQUEST, fetchPartnersSaga);
}