import React, { useState } from "react";
import Box from '@mui/material/Box'
import TextField from "@mui/material/TextField";
import DayFrequency from "./reusableModule/DayFrequency";
import Grid from '@mui/material/Grid'
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from "@mui/material/ListItemText";
import TimeFrequency from "./reusableModule/TimeFrequency";
import TimeModule from "./reusableModule/TimeModule";

const YearlyScheduler = () => {
    const [frequency, setFrequency] = useState('1');
    const [selectedMonths, setSelectedMonths] = useState([])
    const months = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
    ];

    const changeMonth = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedMonths(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }} >
            <div style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>

                <div style={{ display: "flex", alignItems: "center", width: "100%", marginBottom: "16px" }}>
                    <Typography style={{ width: "12%" }}>Reccurs</Typography>
                    <Select
                        sx={{
                            width: "38%",
                            marginRight: "16px",
                            height: "48px"
                        }}
                        multiple
                        value={selectedMonths}
                        onChange={changeMonth}
                        renderValue={(selected) =>
                            selected
                                .map((value) => months.find((month) => month.value === value)?.label)
                                .join(', ')
                        }
                    >
                        {months.map((month) => (
                            <MenuItem key={month.value} value={month.value}>
                                <Checkbox checked={selectedMonths.includes(month.value)} />
                                <ListItemText primary={month.label} />
                            </MenuItem>
                        ))}
                    </Select>

                    <RadioGroup
                        row
                        value={frequency}
                        onChange={(e) => setFrequency(e.target.value)}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="At" />
                        <FormControlLabel value="2" control={<Radio />} label="Every" />
                    </RadioGroup>
                </div>
            </div>

            {/* Day Frequency Component */}
            <DayFrequency occurence={"Yearly"} />

            {/* Time Module or Frequency Component */}
            <div>
                {frequency === "1" ? <TimeModule /> : <TimeFrequency />}
            </div>
        </div>
    )
}

export default YearlyScheduler