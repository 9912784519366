// src/components/users/UserList.js
import React from 'react';
import { useSelector } from 'react-redux';
import CustomTooltip from '../reusableComponents/CustomTooltip/CustomTooltip';
import { Box, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import theme from '../../theme';

const CustomersList = ({ pageName, customersData, handleCustomerActions }) => {
  const customerConfig = useSelector((state) => state.auth.userDetails?.permissionConfig?.customer);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };


  return (
    <Grid container sx={{ borderBottom: 'none' }}>
      {/* Tile */}
      <Box
        sx={{
          width: '100% !important', borderRadius: '25px', // Outer border radius set to 25px          mt: 3, // Optional margin-top adjustment
        }}
      >
        {/* Table */}
        <Grid item xs={12} sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: "1.81px solid #ECEEF6", borderRadius: "25px" }}>
          <TableContainer  >
            <Table sx={{ p: 2, borderCollapse: "unset" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }} >Name</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Business name</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Email</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Created date</TableCell>
                  {pageName === undefined ? (<>
                    <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Actions</TableCell>
                  </>) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  customersData.length > 0 ? (
                    <>
                      {customersData?.map((row, index) => (
                        <TableRow key={index}>
                          {/* <TableCell  sx={{ borderBottom: 'none' }}>{index+1}</TableCell> */}
                          <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1 }}>{row.name}</TableCell>
                          <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1 }}>{row.business_name} </TableCell>
                          <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1 }}>{row.email_id} </TableCell>
                          <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1 }}> {formatDate(row.created_at)}</TableCell>
                          <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1, ml: 5, display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 0 }}>

                            {pageName === undefined ? (<>
                            {
                              customerConfig.update && (
                                <CustomTooltip title="Edit/View">
                                <IconButton
                                  onClick={() => handleCustomerActions('edit', row.id)}
                                  sx={{
                                    backgroundColor: '#F2F2F2', color: '#4F63BE', mt: 1, mr: 2, p: 1, '&:hover': { backgroundColor: '#D9D9D9', },
                                  }}
                                >
                                  <BorderColorOutlinedIcon sx={{ fontSize: 14 }} />
                                </IconButton>
                              </CustomTooltip>
                              )
                            }
                  
                              <CustomTooltip title="View Users">
                                <IconButton
                                  onClick={() => handleCustomerActions('viewUsers', row.id)}
                                  sx={{
                                    backgroundColor: '#F2F2F2', color: '#4F63BE', mt: 1, p: 1,mr: 2, '&:hover': { backgroundColor: '#D9D9D9', },
                                  }}

                                >
                                  <PeopleOutlinedIcon sx={{ fontSize: 14 }} />
                                </IconButton>
                              </CustomTooltip>
                              <CustomTooltip title="Deactivate">
                                <IconButton
                                  onClick={() => handleCustomerActions("deactivate", row.id)}
                                  sx={{ backgroundColor: '#F2F2F2', color: '#4F63BE', mt: 1, mr: 1, p: 1, '&:hover': { backgroundColor: '#D9D9D9', }, }}

                                >
                                  <PersonOffOutlinedIcon sx={{ fontSize: 14 }} />
                                </IconButton>
                              </CustomTooltip>
                            </>) : (<></>)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) :
                    (
                      <TableCell colSpan={4} sx={{ display: "flex", justifyContent: "center" }}>No Customers Available.</TableCell>
                    )
                }

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </Grid>
  );
};

export default CustomersList;
