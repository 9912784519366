import React, { useEffect, useRef, useCallback } from "react";
import { Snackbar, Alert } from "@mui/material";
import IntegrationTile from "../IntegrationTile/IntegrationTile";
import styles from "./IntegrationsGrid.module.css";

const IntegrationsGrid = ({ tiles, hasMore, onLoadMore, loading, error }) => {
  const loaderRef = useRef(null);
  const loadingRef = useRef(false);
  const wrapperRef = useRef(null);
  const [showError, setShowError] = React.useState(false);

  useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return;
    setShowError(false);
  };

  const handleLoadMore = useCallback(() => {
    if (!loadingRef.current && hasMore && !error) {
      loadingRef.current = true;
      onLoadMore().finally(() => {
        loadingRef.current = false;
      });
    }
  }, [hasMore, onLoadMore, error]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        handleLoadMore();
      }
    },
    [handleLoadMore]
  );

  // Intersection Observer for the loader element
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "100px",
      threshold: 0.1,
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [handleObserver]);

  // Window scroll handler as a backup
  useEffect(() => {
    const handleScroll = () => {
      if (!wrapperRef.current || !loaderRef.current || loadingRef.current || !hasMore || error) return;

      const wrapperRect = wrapperRef.current.getBoundingClientRect();
      const loaderRect = loaderRef.current.getBoundingClientRect();
      
      // Check if wrapper is in viewport
      const isWrapperVisible = wrapperRect.top < window.innerHeight && wrapperRect.bottom > 0;
      
      if (isWrapperVisible) {
        // If loader is close to viewport, trigger load more
        const buffer = 150; // pixels before reaching loader
        const shouldLoadMore = loaderRect.top - window.innerHeight < buffer;
        
        if (shouldLoadMore) {
          handleLoadMore();
        }
      }
    };

    // Throttle scroll event
    let ticking = false;
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", scrollListener, { passive: true });
    return () => window.removeEventListener("scroll", scrollListener);
  }, [hasMore, handleLoadMore, error]);

  // Initial check on mount and after content updates
  useEffect(() => {
    if (hasMore && !loading && !error) {
      // Use RAF to ensure DOM is ready
      requestAnimationFrame(() => {
        const loaderRect = loaderRef.current?.getBoundingClientRect();
        if (loaderRect && loaderRect.top < window.innerHeight) {
          handleLoadMore();
        }
      });
    }
  }, [hasMore, loading, handleLoadMore, error]);

  if (loading && tiles.length === 0) {
    return <div>Loading integrations...</div>;
  }

  return (
    <>
      <div className={styles.wrapper} ref={wrapperRef}>
        <div className={styles.gridContainer}>
          {tiles.map((tile) => (
            <IntegrationTile
              key={tile.id}
              id={tile.id}
              icon={tile?.icon || null}
              title={tile.name}
              category={tile.functions.join("/")}
              info={tile.info}
            />
          ))}
        </div>

        {hasMore && !error && (
          <div ref={loaderRef} className={styles.loaderContainer}>
            <div className={`${styles.arrow} ${loadingRef.current ? styles.loading : ''}`} />
            <span className={styles.loaderText}>
              {loadingRef.current ? 'Loading...' : 'Scroll for more'}
            </span>
          </div>
        )}
      </div>

      <Snackbar
        open={showError}
        autoHideDuration={5000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          elevation={6}
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default IntegrationsGrid;