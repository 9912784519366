import React from 'react';
import Box from '@mui/material/Box';
import EventPro from '../components/eventpro/eventpro';
import { useSelector } from 'react-redux';
const EventProPage = () => {
  // const accessToken = useSelector((state) => state.auth.accessToken);
  return (
    <div>
      <EventPro />
    </div>
  );
};

export default EventProPage;
