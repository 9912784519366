// src/components/users/UserList.js
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomTooltip from '../reusableComponents/CustomTooltip/CustomTooltip';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import theme from '../../theme';
import CustomPagination from '../common/CustomPagination';

const PartnersList = ({ partnersData, setCurrentPage, totalPages, currentPage, loading, handlePartnerActions }) => {

  const [usersData, setUsersData] = useState([]);
  const partnerConfig = useSelector((state) => state.auth.userDetails?.permissionConfig?.partner);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    setUsersData(partnersData)
  }, [partnersData])

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  return (
    <Grid container spacing={2} sx={{ pt: 2, borderBottom: 'none' }}>
      <Box
        sx={{
          width: '100%',
          borderRadius: '25px', // Outer border radius set to 25px
          p: 2, // Padding inside the box
          border: '1.81px solid #ECEEF6', // Applying the specific border style
          mt: 3, // Optional margin-top adjustment
        }}
      >
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Table>
              <TableHead>
                <TableRow>

                  <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Name</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Business name</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Email</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Created date</TableCell>
                  <TableCell sx={{ ...theme.typography.tableCellHeader, pt: 2, pb: 2, borderBottom: "0.82px solid #EFEFEF", fontWeight: "400", fontSize: "14px", lineHeight: "16.94px" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading ? (
                  usersData.length > 0 && usersData.map((row, index) => (
                    <TableRow key={index} sx={{
                      '&:hover': {
                        backgroundColor: '#F5F5F5', // Set the hover background color here
                      },
                      cursor: 'pointer', // Optional: Add a pointer cursor for better UX
                    }}>

                      <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1 }}>{row.name}</TableCell>
                      <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1 }}>{row.business_name} </TableCell>
                      <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1 }}>{row.email_id} </TableCell>
                      <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1 }}> {formatDate(row.created_at)}</TableCell>
                      <TableCell sx={{ borderBottom: 'none', pt: 1, pb: 1, ml: 5, display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: 0 }}>
                        {partnerConfig.update && (
                          <CustomTooltip title="Edit/View">
                            <IconButton
                              onClick={() => handlePartnerActions("editPartner", row.id)}
                              sx={{
                                backgroundColor: '#F2F2F2', color: '#4F63BE', mt: 1, mr: 2, p: 1, '&:hover': { backgroundColor: '#D9D9D9', },
                              }}
                            >
                              <BorderColorOutlinedIcon sx={{ fontSize: 14 }} />
                            </IconButton>
                          </CustomTooltip>
                        )}
                        <CustomTooltip title="View Customers">
                          <IconButton
                            onClick={() => handlePartnerActions("viewPartnerCustomer", row.id)}
                            sx={{
                              backgroundColor: '#F2F2F2', color: '#4F63BE', mt: 1, mr: 2, p: 1, '&:hover': { backgroundColor: '#D9D9D9', },
                            }}
                          >
                            <PersonOutlineOutlinedIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title="View Users">
                          <IconButton
                            onClick={() => handlePartnerActions("viewPartnerUsers", row.id)}
                            sx={{
                              backgroundColor: '#F2F2F2', color: '#4F63BE', mt: 1, mr: 2, p: 1, '&:hover': { backgroundColor: '#D9D9D9', },
                            }}
                          >
                            <PeopleOutlinedIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title="Deactivate">
                          <IconButton
                            //   onClick={() => handleDelete(template.id)}
                            sx={{
                              backgroundColor: '#F2F2F2', color: '#4F63BE', mt: 1, mr: 2, p: 1, '&:hover': { backgroundColor: '#D9D9D9', },
                            }}
                          >
                            <PersonOffOutlinedIcon sx={{ fontSize: 14 }} />
                          </IconButton>
                        </CustomTooltip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableCell colSpan={5}>No data available.</TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignContent='center'
        sx={{
          width: '100%',
          position: 'relative',

          bottom: -30,
          alignItems: "center",
          border: '0px solid #ECEEF6',
        }}>

        <CustomPagination
          totalPages={totalPages}
          curPage={currentPage}
          onPageChange={handlePageChange}
        ></CustomPagination>

      </Box>
    </Grid>
  );
};

export default PartnersList;
