import { 
  createWorkflowMapping, 
  updateWorkflowMapping, 
  listWorkflowMappings 
} from '../../../../api/workFlowMappingApi';

// Constants and Initial States
const emptyField = {
  sourceField: '',
  targetField: '',
  sourceType: 'string',
  targetType: 'string',
  mappingType: 'standard',
  id: Date.now(),
  position: 0
};

export const defaultFieldState = {
  sourceField: '',
  targetField: '',
  sourceType: 'string',
  targetType: 'string',
  mappingType: 'standard'
};

export const initialState = { 
  selectedMapping: 'new', 
  fields: [emptyField]
};

// Field Operations
const updatePositions = fields => fields.map((field, index) => ({ ...field, position: index }));

const createNewField = position => ({
  ...defaultFieldState,
  id: Date.now(),
  position
});

// Field List Operations
export const fieldOperations = {
  add: fields => updatePositions([...fields, createNewField(fields.length)]),
  delete: (id, fields) => updatePositions(fields.filter(field => field.id !== id)),
  update: (id, field, value, fields) => {
    const updatedFields = fields.map(item =>
        item.id === id
            ? { ...item, [field]: value }
            : item
    );

    return updatedFields;
},
  reorder: newOrder => updatePositions(newOrder)
};

// State Updates
export const stateUpdates = {
  handleSnackbar: (type, setState, message = '') => setState(prev => ({ 
    ...prev, 
    successPopup: type === 'success',
    errorPopup: type === 'error',
    errorMessage: message 
  })),

  handleMappingOperations: setState => ({
    fieldMappingChange: (id, field, value) => setState(prev => ({
      ...prev,
      mappingFields: fieldOperations.update(id, field, value, prev.mappingFields),
      hasChanges: true
    })),
    addField: () => setState(prev => ({
      ...prev,
      mappingFields: fieldOperations.add(prev.mappingFields),
      hasChanges: true
    })),
    deleteField: id => setState(prev => ({
      ...prev,
      mappingFields: fieldOperations.delete(id, prev.mappingFields),
      hasChanges: true
    })),
    reorderFields: newOrder => setState(prev => ({
      ...prev,
      mappingFields: fieldOperations.reorder(newOrder),
      hasChanges: true
    }))
  })
};

// Mapping Operations
export const mappingOperations = {
  getSelectedData: (value, existingMappings) => {
    if (value === 'new' || !existingMappings?.length) {
      return { fields: [emptyField], name: 'New Mapping' };
    }

    const mapping = existingMappings.find(m => m.id === value);
    if (!mapping) return { fields: [emptyField], name: 'New Mapping' };

    return {
      fields: mapping.mapping_schema 
        ? mapping.mapping_schema
            .map((field, index) => ({
              ...field,
              id: field.id || Date.now(),
              position: field.position || index
            }))
            .sort((a, b) => a.position - b.position)
        : [emptyField],
      name: mapping.name || `Mapping ${mapping.id}`
    };
  },

  getName: (selected, mappings) => {
    if (selected === 'new') return 'New Mapping';
    const mapping = mappings?.find(m => m.id === selected);
    return mapping?.name || `Mapping ${selected}`;
  },

  updateList: (newMapping, currentMappings = []) => {
    const existingIndex = currentMappings.findIndex(m => m.id === newMapping.id);
    return existingIndex === -1 
      ? [...currentMappings, newMapping]
      : currentMappings.map(mapping => 
          mapping.id === newMapping.id ? newMapping : mapping
        );
  },

  getInitialState: (prevState, value) => {
    const { fields, name } = value 
      ? mappingOperations.getSelectedData(value, prevState.existingMappings)
      : { 
          fields: prevState.initialState.fields, 
          name: mappingOperations.getName(prevState.initialState.selectedMapping, prevState.existingMappings) 
        };
    
    return {
      ...prevState,
      selectedMapping: value || prevState.initialState.selectedMapping,
      mappingFields: fields,
      mappingName: name,
      hasChanges: false
    };
  }
};

// API Operations
export const apiOperations = {
  fetch: async ({ sourceNode, currentNode, accessToken, initialMappingId, setState }) => {
    if (!sourceNode?.data?.function_identifier || 
        !currentNode?.data?.function_identifier || 
        !accessToken) return;

    try {
      const response = await listWorkflowMappings({
        source_task: sourceNode.data.function_identifier,
        destination_task: currentNode.data.function_identifier,
      });
      
      const mappings = response?.data || [];
      
      if (initialMappingId && initialMappingId !== 'new') {
        const selectedMappingData = mappingOperations.getSelectedData(initialMappingId, mappings);
        setState(prev => ({ 
          ...prev, 
          existingMappings: mappings,
          selectedMapping: initialMappingId,
          mappingFields: selectedMappingData.fields || [emptyField],
          mappingName: selectedMappingData.name || 'New Mapping',
          initialState: {
            selectedMapping: initialMappingId,
            fields: selectedMappingData.fields || [emptyField]
          }
        }));
      } else {
        setState(prev => ({ 
          ...prev, 
          existingMappings: mappings,
          mappingFields: [emptyField]
        }));
      }
    } catch (error) {
      stateUpdates.handleSnackbar('error', setState, 'Failed to fetch existing mappings');
    }
  },

  save: async (params) => {
    try {
      const { state, sourceNode, currentNode, accessToken, onMappingChange, setState } = params;
      const apiParams = {
        name: state.mappingName,
        mapping_schema: state.mappingFields,
        source_task: sourceNode?.data?.function_identifier,
        destination_task: currentNode?.data?.function_identifier,
      };

      const response = await (state.selectedMapping === 'new' 
        ? createWorkflowMapping(apiParams)
        : updateWorkflowMapping(state.selectedMapping, apiParams));

      const newMapping = {
        id: response?.data?.id,
        name: state.mappingName,
        source_task: apiParams.source_task,
        destination_task: apiParams.destination_task,
      };

      if (onMappingChange) {
        onMappingChange(mappingOperations.updateList(newMapping, currentNode?.mappings));
      }

      setState(prev => ({
        ...prev,
        selectedMapping: response?.data?.id,
        initialState: { selectedMapping: response?.data?.id, fields: state.mappingFields },
        hasChanges: false,
        successPopup: true
      }));

      apiOperations.fetch({ 
        sourceNode, 
        currentNode, 
        accessToken, 
        initialMappingId: response?.data?.id, 
        setState 
      });
    } catch (error) {
      const errorMessage = error?.errors?.length ? 
        error.errors.map(err => err.detail).join('. ') : 
        'Failed to save mapping';
      params.onError(errorMessage);
      console.error('Error saving mapping:', error);
    }
  }
};