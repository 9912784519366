// api/workflowApi.js
import { fetchApi } from "../utils/apiUtils";

export const fetchWorkflowNodesApi = async (params) => {
  return fetchApi('task-list/', {
    method: 'POST',
    requiresAuth: true,
    body: params
  });
};

export const fetchWorkflowTilesApi = async (params) => {
  return fetchApi('workflow-library/', {
    method: 'POST',
    requiresAuth: true,
    body: params
  });
};

export const fetchTagsApi = async () => {
  return fetchApi('tag-list/', {
    method: 'GET',
    requiresAuth: true
  });
};

export const saveOrUpdateWorkflowApi = async (workflowData, workflowId) => {
  const endpoint = workflowId ? `edit-customer-workflow/${workflowId}/` : 'create-customer-workflow/';
  return fetchApi(endpoint, {
    method: 'POST',
    requiresAuth: true,
    body: workflowData
  });
};

export const fetchWorkflowTemplates = async (page,searchTerm) => {
  const query = searchTerm || "";
  return fetchApi(`customer-workflow-list/?page=${page}`, {
    method: 'POST',
    requiresAuth: true,
    body: { name: query }
  });
};

export const fetchWorkflowDataApi = async (id, type) => {
  const endpoint = (type === 'template' || type === 'workflow') 
    ? `customer-workflow/${id}/` 
    : `integration-or-workflow/${id}/`;
    
  return fetchApi(endpoint, {
    method: 'GET',
    requiresAuth: true
  });
};