// ValidationConfig.jsx
import React, { useEffect, useState } from 'react';
import { Info } from 'lucide-react';
import { useSelector } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import { useConfigurationList } from '../sharedHelpers/useConfigurationList';
import ConfigurationLayout from '../sharedHelpers/ConfigurationLayout';
import ValidationRow from './ValidationRow';
import Preloader from '../../../../../../../components/common/Preloader';
import { fetchValidationTypes } from '../../../../../../../api/mappingTypeApi';

// Function to remove 'c_' prefix from object keys
function removePrefix(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const newKey = key.startsWith('c_') ? key.slice(2) : key;
    acc[newKey] = value;
    return acc;
  }, {});
}

const ValidationConfig = ({ onClose, onSave, initialConfig = [] }) => {
  const [validationTypes, setValidationTypes] = useState({});
  const [validationTypeOptions, setValidationTypeOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const accessToken = useSelector(state => state.auth.accessToken);

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') return;
    setShowError(false);
  };
 
  useEffect(() => {
    const loadValidationTypes = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const response = await fetchValidationTypes();
        const cleanedTypes = removePrefix(response);
        setValidationTypes(cleanedTypes);
        setValidationTypeOptions(
          Object.entries(cleanedTypes).map(([value, config]) => ({
            value,
            label: config.label
          }))
        );
      } catch (error) {
        const errorMessage = error?.errors?.length ? 
          error.errors.map(err => err.detail).join('. ') : 
          'Failed to load validation types';
        setError(errorMessage);
        setShowError(true);
        console.error('Error loading validation types:', error);
      } finally {
        setIsLoading(false);
      }
    };
 
    if (accessToken) {
      loadValidationTypes();
    }
  }, [accessToken]);

  const {
    items: validations,
    errors,
    canUndo,
    canRedo,
    handleAdd,
    handleChange,
    handleDelete,
    handleSave,
    undo,
    redo,
    getDisabledItems
  } = useConfigurationList({
    initialItems: initialConfig,
    getTypeParams: (type) => validationTypes[type]?.params || [],
    requiredField: 'type',
    onSave,
    typeConfigMap: validationTypes
  });

  const renderValidationRow = (validation, index) => (
    <ValidationRow
      key={validation.id}
      validation={validation}
      validationTypeOptions={validationTypeOptions}
      validationTypes={validationTypes}
      onChange={handleChange}
      onDelete={handleDelete}
      errors={errors[validation.id] || {}}
      index={index}
      disabledItems={getDisabledItems(validation.id)}
    />
  );

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Preloader />
      </div>
    );
  }

  return (
    <>
      <ConfigurationLayout
        items={validations}
        onAdd={handleAdd}
        onSave={handleSave}
        onClose={onClose}
        onUndo={undo}
        onRedo={redo}
        canUndo={canUndo}
        canRedo={canRedo}
        emptyStateIcon={Info}
        emptyStateTitle="No validations configured"
        emptyStateMessage="Click the Add button to create a validation rule"
        headerTitle="Validation Rules"
        headerSubtitle="Configure validation rules for this field"
        renderItem={renderValidationRow}
      />

      <Snackbar 
        open={showError} 
        autoHideDuration={5000} 
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert 
          onClose={handleCloseError} 
          severity="error" 
          elevation={6} 
          variant="filled"
        >
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ValidationConfig;
