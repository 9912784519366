import React, { useState, useEffect } from "react";
import { CssBaseline, Box } from "@mui/material";
import IntegrationsHeader from "../../components/Header/Header"
import IntegrationsSidebar from "../../components/Sidebar/Sidebar";
import IntegrationsGrid from "../../components/Grid/Grid";
import styles from "./ExpRunmybotPage.module.css";
import { useDispatch, useSelector } from "react-redux";
import { hideSidebar, showSidebar } from "../../../../redux/actions/actions";
import {
 fetchIntegrationTilesApi,
 fetchTagsApi,
} from "../../../../api/integrationApi";
import Preloader from "../../../../components/common/Preloader";

const ExpRunmybotPage = () => {
 const [filters, setFilters] = useState([]);
 const [tiles, setTiles] = useState([]);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);

 const dispatch = useDispatch();
 const accessToken = useSelector((state) => state.auth.accessToken);
 const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

 useEffect(() => {
   const fetchInitialData = async () => {
     try {
       if (isAuthenticated && accessToken) {
         const tags = await fetchTagsApi();
         setFilters(tags);

         const tilesData = await fetchIntegrationTilesApi({
           name: "",
           tag: "",
         });
         setTiles(tilesData.results);
       } else {
         throw new Error("User is not authenticated");
       }
     } catch (error) {
       const errorMessage = error?.errors?.length ? 
         error.errors.map(err => err.detail).join('. ') : 
         'Failed to load integration tiles or filters';
       setError(errorMessage);
       console.error('Error fetching initial data:', error);
     } finally {
       setLoading(false);
     }
   };

   fetchInitialData();

   // return () => {
   //   dispatch(showSidebar());
   // };
 }, [accessToken, isAuthenticated, dispatch]);

 const handleTagSelection = async (selectedTagIds) => {
   setLoading(true);
   setError(null);

   try {
     const tilesData = await fetchIntegrationTilesApi({
       name: "",
       tag: selectedTagIds,
     });
     setTiles(tilesData.results);
   } catch (error) {
     const errorMessage = error?.errors?.length ? 
       error.errors.map(err => err.detail).join('. ') : 
       'Failed to fetch integrations with selected tags';
     setError(errorMessage);
     console.error('Error fetching filtered integrations:', error);
   } finally {
     setLoading(false);
   }
 };

 return (
   <Box
     sx={{
       width: "100%",
       margin: "0 auto",
       overflowX: "hidden",
     }}
   >
     <CssBaseline />
     <div className={styles.IntegrationsLibraryPage}>
       <div className={styles.headerAndContent}>
         <IntegrationsHeader />
         <div className={styles.mainContent}>
           <IntegrationsSidebar
             filters={filters}
             onTagSelectionChange={handleTagSelection}
           />
           <div className={styles.contentContainer}>
             <IntegrationsGrid tiles={tiles} loading={loading} error={error} />
           </div>
         </div>
       </div>
     </div>
   </Box>
 );
};

export default ExpRunmybotPage;