import React from 'react';
import styles from './AutomationFlowComponent.module.css';
import WorkflowCard from '../../../workflows/Workflows';
import cancelIcon from '../../../../assetts/images/cancel-s.svg';

const AutomationFlowComponent = ({ log, workflowData, onNodeSelected, onPerformActionClick, canPerformAction }) => {
  return (
    <div className={styles.automationFlowContainer}>
      <div className={styles.header}>
        <h3 className={styles.dateTime}>
          <span>{log.date}</span>
          <span>{log.time}</span>
        </h3>
        <div className={styles.buttonContainer}>
          {log?.status === "success" && (
            <button className={styles.cancelButton}>
              <img src={cancelIcon} alt="Cancel Icon" />
              <span className={styles.cancelButtonText}>Cancel Run</span>
            </button>
          )}
          {canPerformAction && (
            <button className={styles.actionButton} onClick={onPerformActionClick}>
              <span>Perform Action</span>
            </button>
          )}
        </div>
      </div>
      <div className={styles.flowDiagram}>
        <WorkflowCard
          workflowData={workflowData}
          onNodeClick={onNodeSelected}
        />
      </div>
    </div>
  );
};

export default AutomationFlowComponent;