import React, { useState, useCallback, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import NotificationsSidebarHeader from "./NotificationsSidebarHeader";
import NotificationsSidebarList from "./NotificationsSidebarList";
import styles from "./NotificationsSidebar.module.css";

const ITEMS_PER_PAGE = 7;

const listVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      staggerChildren: 0.1,
      when: "beforeChildren"
    }
  },
  exit: { opacity: 0, x: -20 }
};

const NotificationsSidebar = ({
  notifications = [],
  selectedNotification,
  onNotificationClick,
  onDelete,
  onMarkAsRead,
  onMarkAsUnread,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const handleSelectAll = useCallback(() => {
    setSelectedIds(prev =>
      prev.length === notifications.length ? [] : notifications.map(n => n.id)
    );
  }, [notifications]);

  const handleBulkAction = useCallback((action, condition) => {
    const filteredIds = selectedIds.filter(id =>
      condition(notifications.find(n => n.id === id))
    );

    if (filteredIds.length) {
      action(filteredIds);
      action === onDelete && setSelectedIds([]);
    }
  }, [notifications, selectedIds, onDelete]);

  const handleCheckboxChange = useCallback((id) => {
    setSelectedIds(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
    );
  }, []);

  const {
    paginatedNotifications,
    hasMorePages,
    totalResults
  } = useMemo(() => {
    const filtered = notifications.filter(notification =>
      notification?.title?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const start = currentPage * ITEMS_PER_PAGE;
    const paginated = filtered.slice(start, start + ITEMS_PER_PAGE);
    const hasMore = (currentPage + 1) * ITEMS_PER_PAGE < filtered.length;

    return {
      paginatedNotifications: paginated,
      hasMorePages: hasMore,
      totalResults: filtered.length
    };
  }, [notifications, searchTerm, currentPage]);

  const headerProps = {
    notifications,
    selectedNotifications: selectedIds,
    onSelectAll: handleSelectAll,
    onBulkDelete: () => handleBulkAction(onDelete, () => true),
    onBulkMarkAsRead: () => handleBulkAction(onMarkAsRead, n => !n?.isRead),
    onBulkMarkAsUnread: () => handleBulkAction(onMarkAsUnread, n => n?.isRead),
    searchTerm,
    setSearchTerm,
    totalResults
  };

  const listProps = {
    paginatedNotifications,
    selectedNotifications: selectedIds,
    selectedNotification,
    onNotificationClick,
    onCheckboxChange: handleCheckboxChange,
    onMarkAsRead,
    onMarkAsUnread,
    onDelete,
    currentPage,
    setCurrentPage,
    hasMoreNotifications: hasMorePages,
    listVariants
  };

  return (
    // <motion.div
    //   className={styles.NotificationsSidebar}
    //   initial={{ opacity: 0, x: -50 }}
    //   animate={{ opacity: 1, x: 0 }}
    //   exit={{ opacity: 0, x: -50 }}
    //   transition={{ duration: 0.3 }}
    // >
    //   <NotificationsSidebarHeader {...headerProps} />
    //   <AnimatePresence mode="wait">
    //     <NotificationsSidebarList {...listProps} />
    //   </AnimatePresence>
    // </motion.div>
    <div className={styles.NotificationsSidebar}>
      <NotificationsSidebarHeader {...headerProps} />
      <NotificationsSidebarList {...listProps} />
    </div>
  );
};

export default React.memo(NotificationsSidebar);